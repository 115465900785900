var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "preview relative" },
    [
      _vm._l(_vm.data, function (item) {
        return _c("div", { staticClass: "preview-item" }, [
          _c("div", { staticClass: "preview-key" }, [_vm._v(_vm._s(item.key))]),
          _c("div", { staticClass: "preview-value" }, [
            _vm._v(_vm._s(item.value || "-")),
          ]),
        ])
      }),
      _c(
        "div",
        {
          staticClass: "preview-actions",
          on: { click: _vm.onClickPreviewEdit },
        },
        [
          _c("Icon", {
            staticClass: "preview-edit",
            attrs: { name: "icon-global-edit" },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }