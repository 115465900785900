export default function($t) {
  return [
    {
      name: 'symbol',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Allergen.symbol')
      },
      get title() {
        return $t('Allergen.symbol')
      }
    },
    {
      name: 'name',
      is_visible: true,
      get short_title() {
        return $t('Recipe.allergenName')
      },
      get title() {
        return $t('Recipe.allergenName')
      }
    }
  ]
}
