var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("blured-background", [
    _c("div", { staticClass: "Alert" }, [
      _c(
        "div",
        { staticClass: "Alert-body" },
        [
          _c("div", { staticClass: "Alert-header" }, [
            _c(
              "figure",
              { staticClass: "Alert-icon" },
              [_c("Icon", { attrs: { name: "icon-global-bell" } })],
              1
            ),
            _c("h3", { staticClass: "Alert-title" }, [
              _vm._v(
                _vm._s(_vm.$t("Recipes.TrainingAlert_WillTraining_Title"))
              ),
            ]),
            _c(
              "p",
              { staticClass: "Alert-description" },
              [
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("Recipes.TrainingAlert_WillTraining_Description")
                    )
                  ),
                ],
              ],
              2
            ),
          ]),
          _vm.isLoadingRecipe
            ? _c("Loading")
            : _c("div", { attrs: { id: "form-area" } }, [
                _c(
                  "form",
                  {
                    attrs: { id: "form-training-recipe" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.submit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "Alert-input" },
                      [
                        _c("span", { staticClass: "Alert-input-span" }, [
                          _vm._v(
                            _vm._s(_vm.$t("Recipes.TrainingAlert_Input_Name"))
                          ),
                        ]),
                        _vm.isLoadingRecipe ? _c("Loading") : _vm._e(),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.name,
                              expression: "name",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value:
                                "required|max:128|very_singularity:Recipe/header/check/name,name",
                              expression:
                                "'required|max:128|very_singularity:Recipe/header/check/name,name'",
                            },
                          ],
                          ref: "firstInput",
                          staticClass: "txt",
                          class: { "is-danger": _vm.veeErrors.has("name") },
                          attrs: {
                            name: "name",
                            autocomplete: "off",
                            id: "input-recipes-training-name",
                            "data-vv-as": _vm.$t(
                              "Recipes.TrainingAlert_Input_Name"
                            ),
                          },
                          domProps: { value: _vm.name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.name = $event.target.value
                            },
                          },
                        }),
                        _c(
                          "p",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.veeErrors.has("name") &&
                                  !_vm.isLoadingRecipe,
                                expression:
                                  "veeErrors.has('name') && !isLoadingRecipe",
                              },
                            ],
                            staticClass: "Form-item-help is-danger",
                          },
                          [_vm._v(_vm._s(_vm.veeErrors.first("name")))]
                        ),
                      ],
                      1
                    ),
                    _vm.isProductionType
                      ? _c(
                          "div",
                          { staticClass: "Alert-input" },
                          [
                            _c(
                              "span",
                              { staticClass: "Alert-input-span search-span" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "Recipes.detail_Popup_FormField_StockItemName"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _vm.isLoadingRecipe ? _c("Loading") : _vm._e(),
                            _c("custom-search", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "Alert-input-search",
                              attrs: {
                                name: "productionStockItem",
                                "component-name":
                                  "search-recipes-production-stock-item",
                                "data-vv-as": _vm.$t(
                                  "Recipes.detail_Popup_FormField_StockItemName"
                                ),
                                inputClass: {
                                  "is-danger":
                                    _vm.veeErrors.has("productionStockItem") ||
                                    _vm.usedByProductionRecipeBefore,
                                },
                                "on-search": _vm.getOptions,
                                options: _vm.selectOptions,
                                label: "name",
                                placeholder: _vm.$t(
                                  "Recipes.detail_Popup_FormField_StockItemName_Placeholder"
                                ),
                              },
                              model: {
                                value: _vm.productionStockItem,
                                callback: function ($$v) {
                                  _vm.productionStockItem = $$v
                                },
                                expression: "productionStockItem",
                              },
                            }),
                            _c(
                              "p",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.veeErrors.has(
                                        "productionStockItem"
                                      ) && !_vm.isLoadingRecipe,
                                    expression:
                                      "veeErrors.has('productionStockItem') && !isLoadingRecipe",
                                  },
                                ],
                                staticClass: "Form-item-help is-danger",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.veeErrors.first("productionStockItem")
                                  )
                                ),
                              ]
                            ),
                            _vm.usedByProductionRecipeBefore
                              ? _c(
                                  "p",
                                  { staticClass: "Form-item-help is-danger" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "Recipes.popup_Form_Error_usedByProductionRecipeBefore",
                                          {
                                            name: _vm.productionStockItem
                                              .usedByProductionRecipeName,
                                          }
                                        )
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
          _c(
            "div",
            { staticClass: "Alert-actions" },
            [
              _c(
                "Button",
                {
                  attrs: {
                    secondary: "",
                    size: "medium",
                    id: "btn-training-alert-close",
                    type: "button",
                  },
                  on: { click: _vm.close },
                },
                [_vm._v(_vm._s(_vm.$t("Recipes.TrainingAlert_CancelButton")))]
              ),
              _c(
                "Button",
                {
                  attrs: {
                    primary: "",
                    size: "medium",
                    variant: "full",
                    id: "btn-training-alert-submit",
                    form: "form-training-recipe",
                    type: "submit",
                    disabled: _vm.isLoadingSubmit || _vm.isLoadingRecipe,
                  },
                },
                [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isLoadingSubmit,
                          expression: "!isLoadingSubmit",
                        },
                      ],
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("Recipes.TrainingAlert_TrainingButton"))
                      ),
                    ]
                  ),
                  _c("Loading", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isLoadingSubmit,
                        expression: "isLoadingSubmit",
                      },
                    ],
                    attrs: { theme: "disable" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }