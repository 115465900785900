import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import { RecipeGroups } from '../model'

export default {
  namespaced: true,

  state: {
    recipeGroups: new RecipeGroups(),
    permittedRecipeGroups: []
  },

  getters: {
    recipeGroup_List: state => state.recipeGroups.list,
    recipeGroup_IsEmpty: state => state.recipeGroups.length === 0,
    permittedRecipeGroups: state => state.permittedRecipeGroups
  },

  actions: {
    async get_RECIPEGROUP_LIST({ commit, dispatch }) {
      const results = await requestHandler({
        title: 'get_RECIPEGROUP_LIST',
        methodName: 'get_RECIPEGROUP_LIST',
        method: () => {
          return HTTP.get('RecipeGroup/all?PageSize=99999&PageNumber=1')
        },
        success: result => {
          commit('UPDATE_RECIPEGROUP_LIST', result.data.recipeGroupList)
          return result
        }
      })
      return results
    },
    async getPermittedRecipeGroups({ commit, dispatch }) {
      const results = await requestHandler({
        title: 'getPermittedRecipeGroups',
        methodName: 'getPermittedRecipeGroups',
        method: () => {
          return HTTP.get('RecipeGroup/all/permitted')
        },
        success: result => {
          commit('UPDATE_PERMITTED_RECIPEGROUP_LIST', result.data.recipeGroupList)
          return result
        }
      })
      return results
    },
    async get_RECIPEGROUP_ITEM({ state, commit, dispatch }, Id) {
      const results = await requestHandler({
        title: 'get_RECIPEGROUP_ITEM',
        methodName: 'get_RECIPEGROUP_ITEM',
        method: () => {
          return HTTP.get('RecipeGroup', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },

    async create_RECIPEGROUP_ITEM({ commit, dispatch }, item) {
      const results = await requestHandler({
        title: 'create_RECIPEGROUP_ITEM',
        methodName: 'create_RECIPEGROUP_ITEM',
        method: () => {
          return HTTP.post('RecipeGroup', item)
        },
        success: result => result
      })
      return results
    },

    async update_RECIPEGROUP_ITEM({ commit, dispatch }, item) {
      const results = await requestHandler({
        title: 'update_RECIPEGROUP_ITEM',
        methodName: 'update_RECIPEGROUP_ITEM',
        method: () => {
          return HTTP.put('RecipeGroup', item)
        },
        success: result => result
      })
      return results
    },

    async delete_RECIPEGROUP_ITEM({ commit, dispatch }, Id) {
      const results = await requestHandler({
        title: 'delete_RECIPEGROUP_ITEM',
        methodName: 'delete_RECIPEGROUP_ITEM',
        method: () => {
          return HTTP.delete('RecipeGroup', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },
    async CheckRecipeGroupName({ commit }, params) {
      const results = await requestHandler({
        title: 'RecipeGroup/CheckRecipeGroupName',
        methodName: 'RecipeGroup/CheckRecipeGroupName',
        method: () => {
          return HTTP.get('RecipeGroup/check/name', {
            params
          })
        },
        success: result => result
      })
      return results
    }
  },

  mutations: {
    RESET(state) {
      state.recipeGroups = new RecipeGroups()
    },

    UPDATE_RECIPEGROUP_LIST(state, list) {
      state.recipeGroups.addItems(list)
    },

    UPDATE_PERMITTED_RECIPEGROUP_LIST(state, list) {
      state.permittedRecipeGroups = list
    },

    ADD_RECIPEGROUP_ITEM(state, item) {
      state.recipeGroups.addItem(item)
    },

    UPDATE_RECIPEGROUP_ITEM(state, item) {
      state.recipeGroups.updateItem(item)
    }
  }
}
