export default function($t) {
  return [
    {
      name: 'toPos',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Recipe.toPos')
      },
      get title() {
        return $t('Recipe.toPos')
      }
    },
    {
      name: 'powerValue',
      is_visible: true,
      get short_title() {
        return $t('Recipe.powerValue')
      },
      get title() {
        return $t('Recipe.powerValue')
      }
    },

    {
      name: 'cookingTime',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Recipe.cookingTime_withMin')
      },
      get title() {
        return $t('Recipe.cookingTime_withMin')
      }
    }
  ]
}
