<template lang="pug">
blured-background
    .Alert
      .Alert-body

        figure.Alert-icon
          Icon(name="icon-global-copy")
          
        h3.Alert-title {{$t('Recipes.copyAlert_WillCopy_Title') }}
        p.Alert-description
          template {{$t('Recipes.copyAlert_WillCopy_Description') }}

        .Alert-item
          .Alert-item-name
            span(v-if="item") {{item.name}}
            Loading(v-else)
        #form-area
          form.Alert-input(
            id="form-copy-recipe"
            @submit.prevent="submit"
            v-if="!isProductionType"
          )
            span.Alert-input-span {{ $t('Recipes.copyAlery_Input_Span') }}
            Loading(v-if="isLoadingRecipe")
            input.Alert-input-entry.no-spinners(
              v-if="!isLoadingRecipe"
              name="copyCount"
              type="number"
              id="search-recipes-total-count"
              v-model.number="copyCount"
              v-validate="'required|numeric|min_value:1|max_value:9'"
              :data-vv-as="$t('Recipes.copy_FormField_Name_Input')"
              :class="{ 'is-not-valid': veeErrors.has('copyCount') }")
            p.Form-item-help.is-danger(
            v-show="veeErrors.has('copyCount') && !isLoadingRecipe"
            ) {{ veeErrors.first('copyCount') }}

          form.Alert-input(
            id="form-copy-recipe"
            @submit.prevent="submit"
            v-else
          )
            span.Alert-input-span.search-span {{ $t('Recipes.detail_Popup_FormField_StockItemName') }}
            Loading(v-if="isLoadingRecipe")
            custom-search.Alert-input-search(
              ref="firstInput"
              name="productionStockItem"
              component-name="search-recipes-production-stock-item"
              v-model="productionStockItem"
              :data-vv-as="$t('Recipes.detail_Popup_FormField_StockItemName')"
              v-validate="'required'"
              :inputClass="{ 'is-not-valid': veeErrors.has('productionStockItem') || usedByProductionRecipeBefore }"
              :on-search="getOptions"
              :options="selectOptions"
              label="name"
              v-if="!isLoadingRecipe"
              :placeholder="$t('Recipes.detail_Popup_FormField_StockItemName_Placeholder')"
            )

            p.Form-item-help.is-danger(
            v-show="veeErrors.has('productionStockItem') && !isLoadingRecipe"
            ) {{ veeErrors.first('productionStockItem') }}

            p.Form-item-help.is-danger(
            v-if="usedByProductionRecipeBefore"
            ) {{ $t('Recipes.popup_Form_Error_usedByProductionRecipeBefore', { name: productionStockItem.usedByProductionRecipeName }) }}

        .Alert-actions
          Button(
          secondary
          size="medium"
          id="btn-copy-alert-close"
          type="button",
          @click="close"
          ) {{ $t('Recipes.copyAlert_CancelButton') }}
          Button(
          primary
          size="medium"
          variant="full"
          id="btn-copy-alert-submit"
          form="form-copy-recipe",
          type="submit",
          :disabled="isLoadingSubmit")
            span(v-show="!isLoadingSubmit") {{ $t('Recipes.copyAlert_CopyButton') }}
            Loading(theme="disable", v-show="isLoadingSubmit")
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import bluredBackground from '@/view/global/full-blur-background'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'RecipeCopy',

  components: {
    bluredBackground
  },

  data () {
    return {
      item: {},
      productionStockItem: null,
      copyCount: 1,
      selectOptions: []
    }
  },

  computed: {
    ...mapGetters('Recipes'[
      'Page'
    ]),

    usedByProductionRecipeBefore () {
      return !!(this.productionStockItem && this.productionStockItem.usedByProductionRecipeName)
    },
    isLoadingSubmit () {
      return this.$wait.is(['copyRecipe', 'submitCopy'])
    },

    isLoadingRecipe () {
      return this.$wait.is(['get_RECIPE_HEADER'])
    },

    isProductionType () {
      return this.item.type === 2
    }
  },

  created () {
    this.item = this.$route.params.item

    if (!this.$route.params.item) {
      let id = this.$route.params.id
      this.get_RECIPE_HEADER(id).then(res => {
        this.item = res.data.recipeHeader
      })
    }
    this.focusStockItem()
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Stock', [
      'searchStockItem'
    ]),

    ...mapActions('Recipes', [
      'copyRecipe',
      'get_RECIPE_HEADER'
    ]),
    focusStockItem () {
      this.$nextTick(() => this.$refs.firstInput.$refs.search.focus())
    },
    close () {
      this.$router.push({
        path: this.$route.matched[0].path
      })
    },

    submit () {
      this.$validator.validateAll().then(result => {
        if (!result || this.usedByProductionRecipeBefore) return

        this.submitCopy()
      })
    },

    getOptions (search) {
      this.searchStockItem({
        Text: search,
        Types: [2],
        ForProductionRecipeHeaderId: this.$route.params.id
      }).then(res => {
        this.selectOptions = res.data.items
      })
    },

    submitCopy: vueWaitLoader(async function () {
      const params = {
        sourceRecipeId: this.item.id,
        cloneCount: this.isProductionType ? null : this.copyCount,
        productionStockItemId: this.isProductionType ? this.productionStockItem.id : null
      }

      await this.copyRecipe(params).then(res => {
        if (res) {
          const message = this.$t('Recipes.alert_Copy_Submitted', {
            name: this.item.name
          })
          this.close()
          this.$emit('getList', this.Page.number)
          this.notifyShow({ message })
        }
      })
    }, 'submitCopy')
  }
}
</script>

<style lang="scss" scoped>

  .Alert {

    &-body {
      text-align: center;
    }

    &-icon {
      margin-bottom: 30px;
    }

    &-title {
      font-size: 1.6em;
    }

    &-description {
      font-size: 1.2em;
      margin-top: 7px;
      color: $color-text-light;
    }

    &-input {
      display: flex;
      flex-direction: column;
      align-items: center;

    &-search {
      width: 100%;
    }

      &-span {
        color: $color-light;
        font-family: $font-family;
        opacity: 0.72;
      }

      &-span.search-span {
        align-self: flex-start;
        margin-bottom: 10px;
      }

      &-entry {
        width: 276px;
        height: 54px;
        opacity: 0.72;
        font-family: $font-family;
        font-size: $font-size-bigger;
        border-radius: $border-radius;
        border: 1px solid $color-gray;
        color: $color-dark;
        text-align: center;
        margin-top: 13px;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      margin-top: 50px;

      &-name {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        padding: 10px;
        min-width: 276px;
        height: 83px;
        border-radius: $border-high-radius;
        box-shadow: 0 2px 15px 0 rgba(38, 166, 253, 0.28);
        background-color: #ffffff;

        span {
          color: $color-dark;
          font-family: $font-family;
          font-size: $font-size-bigger;
          font-weight: $font-weight-bold;
        }
      }
    }

    &-actions {
      margin-left: auto;
      margin-right: auto;
      max-width: 500px;
      margin-top: 25px;
      display: flex;
      align-items: center;
      z-index: 20;

      .btn {
        padding-left: 32px;
        padding-right: 33px;
        margin-left: 10px;
        margin-right: 10px;
        opacity: 0.72;
        transition: opacity 0.2s ease-in-out;
        text-transform: uppercase;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

.icon-global-copy {
  color: $color-success;
  opacity: 1;
  width: 46px;
  height: 56px;
}
.is-not-valid {
  border: 1px solid $color-warning;
  border-radius: $border-radius;
  outline-color: $color-warning;
}
</style>
