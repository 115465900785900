import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import AllergenCodeList from '@/constant/allergen-list-codes'
import { isEmpty } from '@/utils/baseOperations'
import { Recipe, Recipes, RecipesByStockItem } from '../model'
import { getPaginationNumber } from '@/utils/pagination'

export default {
  namespaced: true,

  state: {
    recipes: new Recipes(),
    recipes_by_stock_item: new RecipesByStockItem(),
    recipesByRecipe: [],
    recipe: null,
    recipeAllergenList: [],
    calorieList: [],
    selectedRecipeGroups: [],
    Page: {
      number: 1,
      size: 20,
      total: 0
    },
    pageHeaderLinks: [
      'Recipes',
      'RecipeGroups'
    ]
  },

  getters: {
    pageHeaderLinks: state => state.pageHeaderLinks,
    recipes_List: state => state.recipes.list,
    recipes_IsEmpty: state => state.recipes.length === 0,
    recipes_by_stock_item_List: state => state.recipes_by_stock_item.list,
    recipes_by_stock_item_IsEmpty: state => state.recipes_by_stock_item.length === 0,
    recipe_Detail: state => state.recipe,
    recipeAllergenList: state => state.recipeAllergenList,
    Page: state => ({
      ...state.Page,
      last: Math.ceil(state.Page.total / state.Page.size)
    }),
    calorieList: state => state.calorieList,
    recipesByRecipe: state => state.recipesByRecipe,
    selectedRecipeGroups: state => state.selectedRecipeGroups
  },

  actions: {
    async get_RECIPES_LIST({ state, commit, dispatch }, { CostCalculationStoreId = null, CostCalculationType = 1, page = 1, pageSize, type = null, name = null, RecipeGroupIds = null, showUnGroups = true, sortExpressionList, showPassives }) {
      const PAGE_NUMBER = getPaginationNumber({ selectedPage: page, currentPage: state.Page.number })
      const payload = {
        pageSize,
        PageNumber: PAGE_NUMBER,
        Type: type,
        name: name,
        costCalculationStoreId: CostCalculationStoreId,
        costCalculationType: CostCalculationType,
        RecipeGroupIds,
        showUnGroups,
        sortExpressionList,
        showPassives
      }
      const results = await requestHandler({
        title: 'get_RECIPES_LIST',
        methodName: 'get_RECIPES_LIST',
        method: () => {
          return HTTP.post('Recipe/header/all', payload)
        },
        success: result => {
          commit('UPDATE_RECIPES_LIST', result.data)
          return result
        }
      })
      return results
    },

    async searchRecipeItemByName({ commit }, payload) {
      payload.PageSize = 999999
      payload.PageNumber = 1
      const loadingName = 'searchRecipeItemByName'
      if (!payload.Type && !payload.type) {
        payload.Type = null
      }
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('Recipe/header/all', payload)
        },
        success: result => {
          return result
        }
      })
      return results
    },

    async searchRecipeByName({ commit, dispatch }, params) {
      const results = await requestHandler({
        title: 'searchRecipeByName',
        methodName: 'searchRecipeByName',
        method: () => {
          return HTTP.get('Recipe/header/filterbyname', { params })
        },
        success: result => result
      })
      return results
    },

    async get_RECIPES_BY_STOCK_ITEM({ commit, dispatch }, params) {
      params.PageNumber = 1
      params.PageSize = 999

      const results = await requestHandler({
        title: 'get_RECIPES_BY_STOCK_ITEM',
        methodName: 'get_RECIPES_BY_STOCK_ITEM',
        method: () => {
          return HTTP.post('Recipe/header/searchbystockitemid', params)
        },
        success: result => {
          commit('UPDATE_RECIPES_BY_STOCK_ITEM', result.data.recipeShortHeaderList)
          return result
        }
      })
      return results
    },

    async checkBarcode({ commit }, params) {
      const loadingName = 'checkBarcode'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Recipe/header/check/barcode', { params })
        },
        success: result => result
      })
      return results
    },

    async getRecipesWithRecipe({ commit, dispatch }, params) {
      params.PageNumber = 1
      params.PageSize = 999

      const results = await requestHandler({
        title: 'getRecipesWithRecipe',
        methodName: 'getRecipesWithRecipe',
        method: () => {
          return HTTP.post('Recipe/header/searchbyrecipeheaderid', params)
        },
        success: result => {
          commit('RECIPE_LIST_BY_RECIPE', result.data.recipeShortHeaderList)
          return result
        }
      })
      return results
    },

    async get_RECIPE_HEADER({ commit, dispatch }, RecipeHeaderId) {
      const results = await requestHandler({
        title: 'get_RECIPE_HEADER',
        methodName: 'get_RECIPE_HEADER',
        method: () => {
          return HTTP.get('Recipe', {
            params: { RecipeHeaderId, withDetails: false }
          })
        },
        success: result => result
      })
      return results
    },

    async getTotalCalorie({ commit }, payload) {
      const loadingName = 'getTotalCalorie'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Recipe/nutrition/detail/total', {
            params: {
              RecipeHeaderId: payload.RecipeHeaderId,
              FetchCaloriesOnly: payload.FetchCaloriesOnly
            }
          })
        },
        success: result => {
          commit('SET_TOTAL_CALORIE', result.data)
          return result
        }
      })
      return results
    },

    async getAllNutritionValues({ commit }, payload) {
      const loadingName = 'getAllNutritionValues'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Recipe/nutrition/detail', {
            params: {
              RecipeHeaderId: payload.RecipeHeaderId,
              FetchCaloriesOnly: payload.FetchCaloriesOnly,
              StockItemId: payload.StockItemId
            }
          })
        },
        success: result => result
      })
      return results
    },

    async getDetail({ commit, dispatch }, id) {
      const results = await requestHandler({
        title: 'getDetail',
        methodName: 'getDetail',
        method: () => {
          return HTTP.get('Recipe/detail', {
            params: { id }
          })
        },
        success: result => result
      })
      return results
    },

    async create_RECIPE_HEADER({ commit, dispatch }, recipe) {
      const results = await requestHandler({
        title: 'create_RECIPE_HEADER',
        methodName: 'create_RECIPE_HEADER',
        method: () => {
          return HTTP.post('Recipe/header', recipe)
        },
        success: result => result
      })
      return results
    },

    async create_TRAINING_RECIPE({ commit, dispatch }, sourceRecipeId) {
      const results = await requestHandler({
        title: 'create_TRAINING_RECIPE',
        methodName: 'create_TRAINING_RECIPE',
        method: () => {
          return HTTP.post('Recipe/training', { sourceRecipeId })
        },
        success: result => result
      })
      return results
    },
    async finalize_TRAINING_RECIPE({ commit, dispatch }, payload) {
      // ProcessTypes :

      //   1 : NewRecipe

      //   2: OverwriteExisting

      //   3:DeleteTrainingRecipe

      const results = await requestHandler({
        title: 'finalize_TRAINING_RECIPE',
        methodName: 'finalize_TRAINING_RECIPE',
        method: () => {
          return HTTP.put('Recipe/training/finalize', payload)
        },
        success: result => result
      })
      return results
    },

    async update_RECIPE_HEADER({ commit, dispatch }, recipe) {
      const results = await requestHandler({
        title: 'update_RECIPE_HEADER',
        methodName: 'update_RECIPE_HEADER',
        method: () => {
          return HTTP.put('Recipe/header', recipe)
        },
        success: result => result
      })
      return results
    },

    async updateRecipeContent({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'updateRecipeContent',
        methodName: 'updateRecipeContent',
        method: () => {
          return HTTP.put('Recipe/bulkcontent', payload)
        },
        success: result => result
      })
      return results
    },

    async deleteRecipeContent({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'deleteRecipeContent',
        methodName: 'deleteRecipeContent',
        method: () => {
          return HTTP.post('Recipe/deletebulkcontent', payload)
        },
        success: result => result
      })
      return results
    },

    async changeActivationStatus({ commit, dispatch }, recipe) {
      const results = await requestHandler({
        title: 'changeActivationStatus',
        methodName: 'changeActivationStatus',
        method: () => {
          return HTTP.put('Recipe/header/activationstate', recipe)
        },
        success: result => result
      })
      return results
    },

    async updateOrderNumber({ commit, dispatch }, payload) {
      // payload ==> id and orderNumber
      const results = await requestHandler({
        title: 'updateOrderNumber',
        methodName: 'updateOrderNumber',
        method: () => {
          return HTTP.put('Recipe/detail/ordernumber', payload)
        },
        success: result => result
      })
      return results
    },

    async delete_RECIPE_HEADER({ commit, dispatch }, RecipeHeaderId) {
      const results = await requestHandler({
        title: 'delete_RECIPE_HEADER',
        methodName: 'delete_RECIPE_HEADER',
        method: () => {
          return HTTP.delete('Recipe', {
            params: { RecipeHeaderId }
          })
        },
        success: result => result
      })
      return results
    },

    async get_RECIPE_DETAIL({ commit, dispatch }, { recipeHeaderId, showAlternativeStocks = false , costCalculationType}) {
      const results = await requestHandler({
        title: 'get_RECIPE_DETAIL',
        methodName: 'get_RECIPE_DETAIL',
        method: () => {
          return HTTP.get('Recipe', {
            params: { recipeHeaderId, showAlternativeStocks, costCalculationType}
          })
        },
        success: result => {
          commit('UPDATE_RECIPE_DETAIL', result.data)
          return result
        }
      })
      return results
    },

    async get_RECIPE_DETAIL_TOTALS({ commit, dispatch }, {recipeHeaderId, showAlternativeStocks = false}) {
      const results = await requestHandler({
        title: 'get_RECIPE_DETAIL_TOTALS',
        methodName: 'get_RECIPE_DETAIL_TOTALS',
        method: () => {
          return HTTP.get('Recipe/detail/total', {
            params: { recipeHeaderId, showAlternativeStocks }
          })
        },
        success: result => {
          return result
        }
      })
      return results
    },

    async create_RECIPE_DETAIL_STOCK({ commit, dispatch }, detail) {
      const results = await requestHandler({
        title: 'create_RECIPE_DETAIL_STOCK',
        methodName: 'create_RECIPE_DETAIL_STOCK',
        method: () => {
          return HTTP.post('Recipe/detail/stockitem', detail)
        },
        success: result => result
      })
      return results
    },

    async create_RECIPE_DETAIL_RECIPE({ commit, dispatch }, detail) {
      const results = await requestHandler({
        title: 'create_RECIPE_DETAIL_RECIPE',
        methodName: 'create_RECIPE_DETAIL_RECIPE',
        method: () => {
          return HTTP.post('Recipe/detail/recipe', detail)
        },
        success: result => result
      })
      return results
    },

    async update_RECIPE_DETAIL({ commit, dispatch }, { detail, type }) {
      const endpoint = `Recipe/detail/${type === 1 ? 'stockitem' : 'recipe'}`
      const results = await requestHandler({
        title: 'update_RECIPE_DETAIL',
        methodName: 'update_RECIPE_DETAIL',
        method: () => {
          return HTTP.put(endpoint, detail)
        },
        success: result => result
      })
      return results
    },

    async delete_RECIPE_DETAIL({ commit, dispatch }, Id) {
      const results = await requestHandler({
        title: 'delete_RECIPE_DETAIL',
        methodName: 'delete_RECIPE_DETAIL',
        method: () => {
          return HTTP.delete('Recipe/detail', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },

    async remove_RECIPES_BY_STOCK_ITEM({ commit, dispatch }) {
      commit('CLEAR_RECIPES_BY_STOCK_ITEM')
    },

    async checkRecipeName({ commit, dispatch }, params) {
      const loadingName = 'checkRecipeName'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Recipe/header/check/name', { params })
        },
        success: result => result
      })
      return results
    },

    async checkRecipeCode({ commit, dispatch }, params) {
      const loadingName = 'checkRecipeCode'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Recipe/header/check/recipecode', { params })
        },
        success: result => result
      })
      return results
    },

    async copyRecipe({ commit, dispatch }, params) {
      const loadingName = 'copyRecipe'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('Recipe/clone', params)
        },
        success: result => result
      })
      return results
    },

    async findRecipeInInventory({ commit, dispatch }, payload) {
      const loadingName = 'findRecipeInInventory'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('Recipe/headerlist/getbyinventoryheaderid', payload)
        },
        success: result => result
      })
      return results
    },

    async getRecipeAllergens({ commit }, payload) {
      const loadingName = 'getRecipeAllergens'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Recipe/allergen/all', {
            params: {
              pageNumber: 1,
              pageSize: 999999999,
              RecipeId: payload.RecipeId
            }
          })
        },
        success: result => {
          commit('SET_RECIPE_ALLERGEN_LIST', result.data)
          return result
        }
      })
      return results
    }
  },

  mutations: {
    RESET(state) {
      state.recipes = new Recipes()
      state.recipe = null
      state.recipes_by_stock_item = new RecipesByStockItem()
    },

    RESET_RECIPES(state) {
      state.recipes = new Recipes()
      state.recipe = null
    },

    RESET_RECIPES_BY_STOCK_ITEM(state) {
      state.recipes_by_stock_item = new RecipesByStockItem()
    },

    RESET_RECIPES_BY_RECIPE(state) {
      state.recipesByRecipe = []
    },

    UPDATE_RECIPES_LIST(state, data) {
      state.recipes.addItems(data.recipeHeaderList)
      state.recipes.list.forEach(item => {
        item.rowTextClass = item.isActive ? '' : 'TableViewPassiveItemsNoBorder'
      })
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
      state.Page.total = data.totalCount
    },

    UPDATE_RECIPES_BY_STOCK_ITEM(state, list) {
      state.recipes_by_stock_item.addItems(list)
    },

    RECIPE_LIST_BY_RECIPE(state, data) {
      state.recipesByRecipe = data
    },

    UPDATE_RECIPE_DETAIL(state, recipe) {
      state.recipe = new Recipe(recipe)
      if (!isEmpty(recipe.recipeDetailList)) {
        state.recipe.addItems(recipe.recipeDetailList)
      }
    },

    CLEAR_RECIPES_BY_STOCK_ITEM(state) {
      state.recipes.clearList()
    },

    SET_RECIPE_ALLERGEN_LIST(state, data) {
      const allergenData = data.allergenList.map(el => {
        let iconMapping = AllergenCodeList.find(iconCode => iconCode.code === el.code)
        return {
          ...el,
          iconName: iconMapping ? iconMapping.icon : 'simpra-logo'
        }
      })
      state.recipeAllergenList = allergenData
    },
    SET_TOTAL_CALORIE(state, data) {
      state.calorieList = data.itemList[0]
    },
    SET_RECIPE_GROUP_FILTER(state, data) {
      state.selectedRecipeGroups = data
    },
    RESET_RECIPE_GROUP_FILTER(state, data) {
      state.selectedRecipeGroups = []
      state.Page = {number: 1, size: 20, total: 0}
    }
  }
}
