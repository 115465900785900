<template lang="pug">
DeleteAlert(
  :item="item",
  :fields="fields",
  :tableName="$t('Recipe.removeDetail_TableName')",
  loading="delete_RECIPE_DETAIL",
  @delegateOnAlertRemoveOk="itemRemove")
</template>

<script>
import { mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
import getApiErrorMessage from '@/utils/get-api-error-message'

export default {
  name: 'RecipeDetailRemove',

  data () {
    return {
      fields: [{
        name: 'name',
        is_visible: true,
        short_title: this.$t('Recipe.removeDetail_TableColumn_Name_Short'),
        title: this.$t('Recipe.removeDetail_TableColumn_Name')
      }, {
        name: 'quantity',
        is_visible: true,
        short_title: this.$t('Recipe.removeDetail_TableColumn_StockItemQuantity_Short'),
        title: this.$t('Recipe.removeDetail_TableColumn_StockItemQuantity')
      }],
      item: {}
    }
  },

  async mounted () {
    this.recipeId = this.$route.params.recipeHeaderId
    this.detailId = this.$route.params.detailId
    if (this.$route.params.item) {
      this.item = this.$route.params.item
    } else {
      this.getDetail(this.detailId)
        .then(res => {
          this.item.name = res.data.recipeDetail.stockItemName
          this.item.quantity = res.data.recipeDetail.netQuantity
        })
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Recipes', [
      'delete_RECIPE_DETAIL',
      'getDetail'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      await this.delete_RECIPE_DETAIL(this.detailId).then(res => {
        if (res.status === 200) {
          const message = this.$t('Global.notification_Deleted', { name: this.item.name })
          this.$emit('getList')
          this.notifyShow({ message })
          this.close()
        } else {
          const message = getApiErrorMessage(this, res.data.code)
          this.notifyShow({ message })
        }
      })
    }, 'recipeRemoveDetailForm')
  }
}
</script>

<style lang="scss">
</style>
