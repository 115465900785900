var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass: "alternative-show",
      attrs: { id: "alternative-stock-show" },
      on: { click: _vm.changeShowAlternative },
    },
    [
      _c("div", { staticClass: "alternative-show-icon" }, [
        _vm._v(_vm._s(_vm.icon)),
      ]),
      _c("div", { staticClass: "alternative-show-text" }, [
        _vm._v(_vm._s(_vm.text)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }