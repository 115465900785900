import BaseClass from '@/utils/baseclass'

// RECIPES

export class Recipes extends BaseClass {
  addItem(item) {
    let newItem = new RecipeHeader(item)
    this.list.push(newItem)
  }

  updateItem(item) {
    let findItem = this.findItem(item.id)
    if (findItem) {
      findItem.cost = item.cost
      findItem.estimatedCost = item.estimatedCost
      findItem.totalCost = item.totalCost
      findItem.costRatio = item.costRatio
      findItem.estimatedCostRatio = item.estimatedCostRatio
      findItem.grossSalesPrice = item.grossSalesPrice
      findItem.id = item.id
      findItem.information = item.information
      findItem.name = item.name
      findItem.netSalesPrice = item.netSalesPrice
      findItem.profit = item.profit
      findItem.estimatedProfit = item.estimatedProfit
      findItem.profitRatio = item.profitRatio
      findItem.estimatedProfitRatio = item.estimatedProfitRatio
      findItem.recipeCode = item.recipeCode
      findItem.recipeGroupId = item.recipeGroupId
      findItem.taxId = item.taxId
      findItem.barcodeList = item.barcodeList
      findItem.unitId = item.unitId
      findItem.hasNutritionValues = item.hasNutritionValues
      findItem.isActive = item.isActive
      findItem.preparationTime = item.preparationTime
    }
  }
}

class RecipeHeader {
  constructor(item) {
    this.cost = item.cost
    this.estimatedCost = item.estimatedCost
    this.totalCost = item.totalCost
    this.costRatio = item.costRatio
    this.estimatedCostRatio = item.estimatedCostRatio
    this.grossSalesPrice = item.grossSalesPrice
    this.id = item.id
    this.information = item.information
    this.name = item.name
    this.netSalesPrice = item.netSalesPrice
    this.profit = item.profit
    this.barcodeList = item.barcodeList
    this.estimatedProfit = item.estimatedProfit
    this.profitRatio = item.profitRatio
    this.estimatedProfitRatio = item.estimatedProfitRatio
    this.taxRatio = item.taxRatio
    this.recipeCode = item.recipeCode
    this.recipeGroupId = item.recipeGroupId
    this.taxId = item.taxId
    this.unitId = item.unitId
    this.type = item.type
    this.productionStockItemId = item.productionStockItemId
    this.productionStockItemName = item.productionStockItemName
    this.status = item.status
    this.sourceRecipeHeaderId = item.sourceRecipeHeaderId
    this.cookingTime600W = item.cookingTime600W
    this.cookingTime750W = item.cookingTime750W
    this.cookingTime900W = item.cookingTime900W
    this.hasNutritionValues = item.hasNutritionValues
    this.isActive = item.isActive
    this.preparationTime = item.preparationTime
  }
}

// RECIPE

export class Recipe extends BaseClass {
  constructor(item) {
    super()
    this.cost = item.recipeHeader.cost
    this.estimatedCost = item.recipeHeader.estimatedCost
    this.totalCost = item.recipeHeader.totalCost
    this.costRatio = item.recipeHeader.costRatio
    this.estimatedCostRatio = item.recipeHeader.estimatedCostRatio
    this.grossSalesPrice = item.recipeHeader.grossSalesPrice
    this.id = item.recipeHeader.id
    this.information = item.recipeHeader.information
    this.name = item.recipeHeader.name
    this.netSalesPrice = item.recipeHeader.netSalesPrice
    this.profit = item.recipeHeader.profit
    this.estimatedProfit = item.recipeHeader.estimatedProfit
    this.profitRatio = item.recipeHeader.profitRatio
    this.estimatedProfitRatio = item.recipeHeader.estimatedProfitRatio
    this.taxRatio = item.recipeHeader.taxRatio
    this.recipeCode = item.recipeHeader.recipeCode
    this.recipeGroupId = item.recipeHeader.recipeGroupId
    this.taxId = item.recipeHeader.taxId
    this.unitId = item.recipeHeader.unitId
    this.portionUnitName = item.recipeHeader.portionUnitName
    this.unitName = item.recipeHeader.unitName
    this.barcodeList = item.recipeHeader.barcodeList
    this.totalQuantity = item.recipeHeader.totalQuantity
    this.portionQuantity = item.recipeHeader.portionQuantity
    this.portionUnitId = item.recipeHeader.portionUnitId
    this.totalUnitName = item.recipeHeader.totalUnitName
    this.nutritionFieldName = item.nutritionFieldName
    this.nutritionFieldUnitName = item.nutritionFieldUnitName
    this.per100gAmount = item.per100gAmount
    this.perServingAmount = item.perServingAmount
    this.totalAmount = item.totalAmount
    this.values = item.values
    this.stockItemName = item.stockItemName
    this.recipeHeaderId = item.recipeHeaderId
    this.isActive = item.isActive
    this.preparationTime = item.preparationTime
  }

  addItem(item) {
    let newItem = new RecipeDetailList(item)
    this.list.push(newItem)
  }

  updateItem(item) {
    let findItem = this.findItem(item.id)
    if (findItem) {
      findItem.cost = item.cost
      findItem.estimatedCost = item.estimatedCost
      findItem.totalCost = item.totalCost
      findItem.costRatio = item.costRatio
      findItem.estimatedCostRatio = item.estimatedCostRatio
      findItem.grossSalesPrice = item.grossSalesPrice
      findItem.id = item.id
      findItem.information = item.information
      findItem.name = item.name
      findItem.netSalesPrice = item.netSalesPrice
      findItem.profit = item.profit
      findItem.estimatedProfit = item.estimatedProfit
      findItem.profitRatio = item.profitRatio
      findItem.estimatedProfitRatio = item.estimatedProfitRatio
      findItem.recipeCode = item.recipeCode
      findItem.barcodeList = item.barcodeList
      findItem.recipeGroupId = item.recipeGroupId
      findItem.taxId = item.taxId
      findItem.unitId = item.unitId
      findItem.recipePortionQuantity = item.recipePortionQuantity
      findItem.recipePortionUnitName = item.recipePortionUnitName
      findItem.stockItemName = item.stockItemName
      findItem.isActive = item.isActive
      findItem.preparationTime = item.preparationTime
    }
  }
}

class RecipeDetailList {
  constructor(item) {
    this.id = item.id
    this.recipeId = item.recipeId
    this.type = item.type
    this.unitName = item.type === 2 ? item.recipePortionQuantity + ' ' + item.recipePortionUnitName : item.unitName
    this.quantity = item.grossQuantity
    this.grossQuantity = item.grossQuantity
    this.netQuantity = item.netQuantity
    this.baseUnitName = item.baseUnitName
    this.stockItemAveragePrice = item.stockItemAveragePrice
    this.stockItemAveragePrice = item.stockItemAveragePrice
    this.cost = item.cost
    this.estimatedCost = item.estimatedCost
    this.totalCost = item.totalCost
    this.costRatio = item.costRatio
    this.estimatedCost = item.estimatedCost
    this.estimatedTotalCost = item.estimatedTotalCost
    this.estimatedCostRatio = item.estimatedCostRatio
    this.stockItemId = item.stockItemId
    this.name = item.name
    this.unitCode = item.unitCode
    this.unitId = item.unitId
    this.baseUnitId = item.baseUnitId
    this.baseQuantity = item.baseQuantity
    this.recipePortionUnitName = item.recipePortionUnitName
    this.recipePortionQuantity = item.recipePortionQuantity
    this.loss1 = item.loss1
    this.loss2 = item.loss2
    this.isAlternativeStock = item.isAlternativeStock
    this.hasAlternativeStock = item.hasAlternativeStock
    this.recipeNetSalesPrice = item.recipeNetSalesPrice
    this.orderNumber = item.orderNumber
    this.kgQuantity = item.kgQuantity
    this.values = item.values
  }
}

// RECIPE GROUPS

export class RecipeGroups extends BaseClass {
  addItem(item) {
    let newItem = new RecipeGroup(item)
    this.list.push(newItem)
  }

  updateItem(item) {
    let findItem = this.findItem(item.id)
    if (findItem) {
      findItem.id = item.id
      findItem.name = item.name
    }
  }
}

class RecipeGroup {
  constructor(item) {
    this.id = item.id
    this.name = item.name
  }
}

// RECIPES BY STOCK ITEM

export class RecipesByStockItem extends BaseClass {
  addItem(item) {
    let newItem = new RecipeHeaderByStockItem(item)
    this.list.push(newItem)
  }

  updateItem(item) {
    let findItem = this.findItem(item.id)
    if (findItem) {
      findItem.id = item.id
      findItem.name = item.name
      findItem.quantity = item.quantity
      findItem.unitName = item.unitName
      findItem.isAlternative = item.isAlternative
      findItem.recipeDetailId = item.recipeDetailId
    }
  }
}

class RecipeHeaderByStockItem {
  constructor(item) {
    this.id = item.id
    this.name = item.name
    this.quantity = item.quantity
    this.unitName = item.unitName
    this.isAlternative = item.isAlternative
    this.recipeDetailId = item.recipeDetailId
  }
}
