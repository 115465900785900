<template lang="pug">
.statistics
    .statistics-item(
      v-for="(field, index) in fields"
      :key="field.key"
    )
      label.statistics-item-label {{ field.translation }}
      input.txt.statistics-item-input.m-top-5(
        v-if="field.type === 'percent'"
        v-model="statisticData[field.key]"
        @change="calculate(field.key)"
        :disabled="!field.isEditable"
        :id="`input-statistics-item-${index}`"
        :class="field.isEditable ? 'is-editable' : ''"
      )
      currency-input.txt.statistics-item-input.m-top-5(
        v-else-if="field.type === 'currency'"
        v-model="statisticData[field.key]"
        @change.native="calculate(field.key)"
        :disabled="!field.isEditable"
        :id="`input-statistics-item-${index}`"
        :class="field.isEditable ? 'is-editable' : ''"
      )
</template>
<script>
import getIstatisticFields from './getIstatisticFields'

export default {
  name: 'RecipeTotalInfoBox',
  props: {
    recipe: {
      type: Object,
      required: true
    },
    showEstimatedCost: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      fields: getIstatisticFields(this.$t),
      statisticData: null
    }
  },
  mounted () {
    this.statisticData = { ...this.recipe }
    this.calculate()
  },
  watch: {
    showEstimatedCost () {
      this.statisticData = { ...this.recipe }
      this.calculate()
    }
  },
  methods: {
    calculate (key) {
      const data = this.statisticData
      data.cost = this.showEstimatedCost ? data.estimatedCost : data.cost

      this.fields.forEach(item => {
        // Turn all calculatble properties to number
        data[item.key] = Number(data[item.key])
      })
      switch (key) {
        case 'grossSalesPrice':
          data.netSalesPrice = data.grossSalesPrice / (1 + (data.taxRatio / 100))
          break
        case 'costRatio':
          data.netSalesPrice = (data.cost * 100) / data.costRatio
          break
        case 'profit':
          data.netSalesPrice = data.profit + data.cost
          break
        case 'profitRatio':
          data.costRatio = 100 - data.profitRatio
          this.calculate('costRatio')
          break
      }

      data.grossSalesPrice = data.netSalesPrice * (1 + (data.taxRatio / 100))
      data.costRatio = (data.cost * 100) / data.netSalesPrice
      data.profit = data.netSalesPrice - data.cost
      data.profitRatio = (data.profit * 100) / data.netSalesPrice

      this.fields.forEach(item => {
        // Format
        if (!Number(data[item.key]) || data[item.key] === Infinity || data[item.key] === -Infinity) data[item.key] = 0
        if (item.type === 'percent') data[item.key] = Number(data[item.key]).toFixed(2)
      })
    }
  }
}
</script>
<style lang="scss" scoped>

.statistics {
  display: flex;
  align-items: center;
  &-item {
    flex-grow: 1;
    label {
      color: $color-light;
      font-size: $font-size-micro;
    }
    input {
      border: 1px dashed $color-light;
      border-radius: $border-radius;
      min-height: 42px;
    }

    &:not(:first-child) {
      margin-left: 25px;
    }

    &.is-editable {
      cursor: pointer;
    }
  }
}
</style>
