var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "statistics" },
    _vm._l(_vm.fields, function (field, index) {
      return _c(
        "div",
        { key: field.key, staticClass: "statistics-item" },
        [
          _c("label", { staticClass: "statistics-item-label" }, [
            _vm._v(_vm._s(field.translation)),
          ]),
          field.type === "percent"
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.statisticData[field.key],
                    expression: "statisticData[field.key]",
                  },
                ],
                staticClass: "txt statistics-item-input m-top-5",
                class: field.isEditable ? "is-editable" : "",
                attrs: {
                  disabled: !field.isEditable,
                  id: `input-statistics-item-${index}`,
                },
                domProps: { value: _vm.statisticData[field.key] },
                on: {
                  change: function ($event) {
                    return _vm.calculate(field.key)
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.statisticData, field.key, $event.target.value)
                  },
                },
              })
            : field.type === "currency"
            ? _c("currency-input", {
                staticClass: "txt statistics-item-input m-top-5",
                class: field.isEditable ? "is-editable" : "",
                attrs: {
                  disabled: !field.isEditable,
                  id: `input-statistics-item-${index}`,
                },
                nativeOn: {
                  change: function ($event) {
                    return _vm.calculate(field.key)
                  },
                },
                model: {
                  value: _vm.statisticData[field.key],
                  callback: function ($$v) {
                    _vm.$set(_vm.statisticData, field.key, $$v)
                  },
                  expression: "statisticData[field.key]",
                },
              })
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }