<template lang="pug">
Popup

    template(slot="title")
      template(v-if="isEdit") {{ $t('Recipes.form_EditTitle') }}
      template(v-else) {{ $t('Recipes.form_CreateTitle') }}

    template(slot="content")
      .selectType(v-if="!isEdit")
        SelectedButtons(v-model="formType", :data="typeData")

      form.Form(
      id="form-recipe"
      @submit.prevent="onSubmitRecipeForm"
      )

        .Form-item.required
          label.Form-item-label {{ $t('Recipes.form_Field_Name') }}

          .control
            input.txt(
            ref="firstField"
            autocomplete="off"
            name="name"
            :data-vv-as="$t('Recipes.form_Field_Name')"
            v-model="form.name"
            v-validate="'required|max:128|verify_name'"
            :class="{ 'is-danger': veeErrors.has('name') }"
            type="text"
            :data-vv-delay="$getConst('INPUT_DELAY_TIME')"
            id="input-recipes-form-field-name")
            //
            Button(
            variant="icon loading"
            id="btn-recipes-popup-loading-name"
            type="button",
            tabindex="-1",
            v-show="isPendingName")
              Loading(theme="disable")
            Button(
            variant="icon check"
            id="btn-popup-recipes-ok-name"
            type="button",
            tabindex="-1",
            iconName="icon-check"
            iconClass="icon-check"
            v-show="isValidateName")
            //
          p.Form-item-help.is-danger(
          v-show="veeErrors.has('name')"
          ) {{ veeErrors.first('name') }}

        .Form-item.required(v-if="isProductionType")
          label.Form-item-label {{ $t('Recipes.detail_Popup_FormField_StockItemName') }}
          .control
            custom-search(
              name="stockItem"
              component-name="form-recipe-stock-item"
              v-model="form.stockItem"
              :data-vv-as="$t('Recipes.detail_Popup_FormField_StockItemName')"
              v-validate="'required'"
              :inputClass="{ 'is-danger': veeErrors.has('stockItem') || usedByProductionRecipeBefore }"
              ref="firstField"
              :on-search="getOptions"
              :options="selectOptions"
              label="name"
              :placeholder="$t('Recipes.detail_Popup_FormField_StockItemName_Placeholder')"
            )

          p.Form-item-help.is-danger(
          v-show="veeErrors.has('stockItem')"
          ) {{ veeErrors.first('stockItem') }}

          p.Form-item-help.is-danger(
          v-if="usedByProductionRecipeBefore"
          ) {{ $t('Recipes.popup_Form_Error_usedByProductionRecipeBefore', { name: form.stockItem.usedByProductionRecipeName }) }}

        div.Form-item
          label.Form-item-label {{ $t('Recipes.form_Field_RecipeCode') }}
          .control
            input.txt(
            name="recipeCode"
            autocomplete="off"
            :data-vv-as="$t('Recipes.form_Field_RecipeCode')"
            v-model="form.recipeCode"
            v-validate="'alpha_dash|max:128|verify_code'"
            :class="{ 'is-danger': veeErrors.has('recipeCode') }"
            type="text"
            :data-vv-delay="$getConst('INPUT_DELAY_TIME')"
            id="input-recipes-form-recipe-code")
            //
            Button(
            variant="icon loading"
            id="btn-recipes-popup-loading-code"
            type="button",
            tabindex="-1",
            v-show="isPendingCode")
              Loading(theme="disable")
            Button(
            variant="icon check"
            id="btn-recipes-popup-ok-code"
            type="button",
            tabindex="-1",
            iconName="icon-check"
            iconClass="icon-check"
            v-show="isValidateCode")
            //
          p.Form-item-help.is-danger(
          v-show="veeErrors.has('recipeCode')"
          ) {{ veeErrors.first('recipeCode') }}

        .Form-item.m-top-20
          label.Form-item-label {{ $t('PriceQuota.popupForm_Field_barcode') }}
          .control.barcode-input
            customTextInput(
              id="input-price-quote-popup-field-barcode-recipe-code"
              name="barcode"
              :data-vv-as="$t('PriceQuota.popupForm_Field_barcode')"
              :placeholder="$t('PriceQuota.popupForm_Field_barcode_Placeholder')"
              :error="veeErrors.has('barcode')"
              :disabled="disableBarcodeInput"
              autocomplete="off"
              v-validate="`numeric|verify_barcode|verify_weighted_barcode:${form.totalUnitId}`"
              :class="{ 'is-danger': veeErrors.has(`barcode`) }"
              v-model="barcode.barcode"
              @keydown.prevent.enter="addBarcode"
            )
            .barcode-actions
              Loading(
                v-if="isLoadingCheckBarcode"
                theme="disable"
              )
              Button(
                v-else
                :justIcon="true"
                size="small"
                :clearVariant="true"
                @click="addBarcode"
              )
                svg.icon(:class="[barcode && !disableBarcodeInput && !veeErrors.has('barcode') ? 'c-success' : 'c-gray']")
                  use(xlink:href="#icon-global-add")


          showValidateError(
            v-if="veeErrors.has('barcode')"
            :errorName="veeErrors.first('barcode')"
          )
          .barcodes
            span.barcodes-text(v-for="(barcode, index) in form.barcodeList") {{ barcode.barcode }}
              Button.barcodes-text-remove(
                type="button"
                variant="icon"
                iconName="icon-barcode-close"
                iconClass="icon-barcode-close"
                @click="removeBarcode(index)"
              )
        div.Form-item
          label.Form-item-label {{ $t('Recipes.form_Field_RecipeGroup') }}
          .control.form-item-select
            Icon(name="icon-down-arrow")
            select.select(
            id="select-recipes-recipe-group"
            name="recipeGroupId"
            :data-vv-as="$t('Recipes.form_Field_RecipeGroup')"
            v-model="form.recipeGroupId"
            :class="{ 'is-danger': veeErrors.has('recipeGroupId') }")
              option(value="-1" id="option-recipes-recipe-unselected") {{ $t('Recipes.form_Unselected') }}
              option(
              v-for="group in recipeGroup_List"
              :key="group.id"
              :value="group.id"
              :id="`option-recipes-recipe-group-${group.name}`"
              ) {{ group.name }}
          p.Form-item-help.is-danger(
          v-show="veeErrors.has('recipeGroupId')"
          ) {{ veeErrors.first('recipeGroupId') }}

        .Form-item.required.twice-line#prod-quantity-unit(v-if="isProductionType && form.stockItem")
          .twice-item
            label.Form-item-label {{ $t('Recipes.form_label_Field_prodUnitId') }}
            .control
              input.txt.no-spinners(
                name="prodQuantity"
                id="input-recipes-form-field-prodQuantity"
                autocomplete="off"
                :data-vv-as="$t('Recipes.form_label_Field_prodUnitId')"
                v-model.number="form.prodQuantity"
                v-validate="{required: true, greater_than:-1, decimal: $getConst('DECIMAL_FOR_QUANTITY'), max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                :class="{ 'is-danger': veeErrors.has('prodQuantity') }"
                type="number"
                step="any")

          .twice-item
            label.Form-item-label {{ $t('Recipes.popup_FormField_ProdUnit') }}

            .control
              input.txt(
              disabled
              :value="form.stockItem.unitName"
              id="input-recipes-form-field-prodUnit")

        div.Form-item(v-if="!isProductionType")
          label.Form-item-label {{ $t('Recipes.form_Field_TaxRatio') }}
          .control.form-item-select
            Icon(name="icon-down-arrow")
            select.select(
            id="select-recipes-tax-ratio"
            name="taxId"
            :data-vv-as="$t('Recipes.form_Field_TaxRatio')"
            v-model="form.taxId"
            :class="{ 'is-danger': veeErrors.has('taxId') }")
              option(value="-1" id="option-recipes-tax-ratio-unselected") {{ $t('Recipes.form_Unselected') }}
              option(
              v-for="tax in Taxes"
              :key="tax.id"
              :value="tax.id"
              :id="`option-recipes-${tax.id}`"
              ) {{ tax.ratio }}
          p.Form-item-help.is-danger(
          v-show="veeErrors.has('taxId')"
          ) {{ veeErrors.first('taxId') }}

        div.Form-item(v-if="!isProductionType")
          label.Form-item-label {{ $t('Recipes.form_Field_GrossSalesPrice') }}
          .control
            currency-input.txt(
            name="grossSalesPrice",
            :data-vv-as="$t('Recipes.form_Field_GrossSalesPrice')",
            :disabled="!isPriceAvailable"
            v-model="form.grossSalesPrice",
            :class="{ 'is-danger': veeErrors.has('amount') }",
            v-validate="{required: true, min_value: 0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}")

        div.Form-item(v-if="!isProductionType")
          label.Form-item-label {{ $t('Recipes.form_Field_NetSalesPrice') }}
          .control
            currency-input.txt.is-disabled(
            name="netSalesPrice",
            disabled,
            v-model="form.netSalesPrice",
            type="text"
            id="input-recipes-form-net-sales-price")

        .Form-item.twice-line.required(v-if="!isProductionType")

          .twice-item
            label.Form-item-label {{ $t('Recipes.form_Field_BaseuUnitQuantity') }}
            .control
              input.txt(
              autocomplete="off"
              name="totalQuantity"
              @change="calculatePortions('totalQuantity')"
              v-model.number="form.totalQuantity"
              v-validate="{required: true, min_value: 0.001, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}"
              :data-vv-as="$t('Recipes.form_Field_BaseuUnitQuantity')"
              :class="{ 'is-danger': veeErrors.has('totalQuantity') }"
              type="number"
              step="any"
              id="input-recipes-form-field-totalQuantity")

            p.Form-item-help.is-danger(
            v-show="veeErrors.has('totalQuantity')"
            ) {{ veeErrors.first('totalQuantity') }}

          .twice-item
            label.Form-item-label {{ $t('Recipes.popup_FormField_BaseUnit') }}
            .control.form-item-select
              Icon(name="icon-down-arrow")
              select.select(
              id="select-recipes-popup-baseunit"
              name="baseUnitId"
              :data-vv-as="$t('Recipes.popup_FormField_BaseUnit')"
              v-validate="'required|is_not:-1'"
              v-model="form.totalUnitId"
              :class="{ 'is-danger': veeErrors.has('baseUnitId') }")
                option(value="-1" id="option-recipes-popup-unselected") {{ $t('Recipes.popup_FormField_BaseUnit_Unselected') }}
                option(
                v-for="BaseUnit in BaseUnitList"
                :key="BaseUnit.id"
                :value="BaseUnit.baseUnitId"
                :id="`option-recipes-popup-${BaseUnit.name}`"
                ) {{ BaseUnit.name }}
            p.Form-item-help.is-danger(
            v-show="veeErrors.has('baseUnitId')"
            ) {{ veeErrors.first('baseUnitId') }}

        .Form-item.twice-line.required(v-if="!isProductionType")
          .twice-item
            label.Form-item-label {{ $t('Recipes.form_label_Field_portionUnitId') }}
            .control
              input.txt(
              autocomplete="off"
              name="portionQuantity"
              @change="calculatePortions('portionQuantity')"
              :disabled="isEmptyBaseUnit"
              v-model.number="form.portionQuantity"
              v-validate="{required: true, min_value: 0.001, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}"
              :data-vv-as="$t('Recipes.form_label_Field_portionUnitId')"
              :class="{ 'is-danger': veeErrors.has('portionQuantity') }"
              type="number"
              step="any"
              id="input-recipes-form-field-portionQuantity")

            p.Form-item-help.is-danger(
            v-show="veeErrors.has('portionQuantity')"
            ) {{ veeErrors.first('portionQuantity') }}

          .twice-item
            label.Form-item-label {{ $t('Recipes.popup_FormField_BaseUnit') }}
            .control.control.form-item-select
              Icon(name="icon-down-arrow")
              select.select(
              id="select-recipes-popup-group-unit"
              name="unitId"
              :disabled="isEmptyBaseUnit"
              :data-vv-as="$t('Recipes.popup_FormField_BaseUnit')"
              v-validate="'required|is_not:-1'"
              v-model="form.portionUnitId"
              :class="{ 'is-danger': veeErrors.has('unitId') }")
                option(value="-1" id="option-recipes-portionid-unselected") {{ $t('Recipes.form_Unselected') }}
                option(
                v-for="Unit in filteredUnitList"
                :key="Unit.id"
                :value="Unit.id"
                :id="`option-recipes-unit-${Unit.name}`"
                ) {{ Unit.name }}
            p.Form-item-help.is-danger(
            v-show="veeErrors.has('unitId')"
            ) {{ veeErrors.first('unitId') }}

        div.Form-item.required(v-if="!isProductionType")
          label.Form-item-label {{ $t('Recipes.form_Field_portionQuantity') }}
          .control
            customNumberInput(
              name="calculatedPortion"
              :data-vv-as="$t('Despatches.shelfLife')"
              id="input-batch-shelf-life"
              :disabled="isEmptyPortion || isEmptyBaseUnit"
              v-model="form.calculatedPortion"
              v-validate="'required|min_value:0.001'"
              @change="calculatePortions('calculatedPortion')"
            )

    template(slot="footer")

      Button(
      primary
      size="large"
      variant="full"
      id="btn-recipes-popup-submit"
      type="submit",
      form="form-recipe"
      :disabled="isLoading")
        span(v-show="!isLoading")
          template(v-if="isEdit") {{ $t('Recipes.form_FinishEditButton') }}
          template(v-else) {{ $t('Recipes.form_FinishCreateButton') }}
        Loading(theme="disable", v-show="isLoading")

</template>

<script>
import { mapFields, Validator } from 'vee-validate'
import { mapGetters, mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
import getQuantityFromBarcode from '@/mixins/getQuantityFromBarcode'
/**
   *
   *  formType = 1 => normal recipe form
   *  formType = 2 => production recipe form
   *
   */

export default {
  name: 'RecipeForm',

  mixins: [getQuantityFromBarcode],
  props: {
    status: {
      type: String,
      default: 'new'
    }
  },

  data () {
    return {
      form: {
        id: null,
        recipeGroupId: '-1',
        taxId: '-1',
        stockItem: null,
        barcodeList: [],
        name: null,
        recipeCode: null,
        netSalesPrice: 0,
        grossSalesPrice: 0,
        totalUnitId: -1,
        portionUnitId: -1,
        portionQuantity: 1,
        totalQuantity: 1,
        calculatedPortion: 1,
        prodQuantity: 1,
        unitId: null,
        portionUnitName: null
      },
      selectOptions: [],
      formType: 1,
      typeData: [
        {
          text: this.$t('Recipes.form_type_normal_recipe'),
          value: 1
        },
        {
          text: this.$t('Recipes.form_type_production_recipe'),
          value: 2
        }
      ],
      defaultUnitId: 'd2a0e74d-648a-4461-a006-03a58bc19f2f',
      selectedPortionUnit: {},
      selectedTotalUnit: {},
      dontChangePortionUnit: true,
      isPriceAvailable: false,
      barcode: {
        barcode: null
      },
      validBarcode: {
        recipeHeaderName: '',
        unitName: ''
      },
    }
  },

  watch: {
    'form.grossSalesPrice' () {
      this.calNetPrice()
    },

    'form.taxId' () {
      this.calNetPrice()
    },

    'form.totalUnitId' () {
      this.onChangeTotalUnitId()
    },

    'form.portionUnitId' () {
      this.onChangePortionId()
    },

    formType () {
      this.resetState()
      this.$validator.reset()
    }
  },

  async created () {
    await this.get_RECIPEGROUP_LIST()
    this.getTaxes()
    let self = this
    Validator.extend('verify_barcode', {
      // Custom validation message
      
      getMessage: () => self.$t('Recipes.checkBarcode', {recipeHeaderName: self.validBarcode.recipeHeaderName}),
      // Custom validation rule
      validate: (value) => new Promise(resolve => {
        let params = {
          Barcode: value,
          recipeHeaderId: self.form.id
        }
        self.checkBarcode(params)
          .then(res => {
            if (res) {
              self.validBarcode.recipeHeaderName = res.data.recipeHeaderName
              resolve({
                valid: res.data.isAvailable
              })
            } else {
              resolve({
                valid: false
              })
            }
          })
      })
    })

    const verifyName = {
      getMessage (field, params, data) {
        return (data && data.message) || 'Something wrong'
      },
      validate (value) {
        return new Promise(resolve => {
          let params = {
            Name: value
          }
          if (self.isEdit) {
            params.Id = self.$route.params.id
          }
          self.checkRecipeName(params)
            .then(res => {
              if (res) {
                resolve({
                  valid: res.data.isAvailable
                })
              } else {
                resolve({
                  valid: false
                })
              }
            })
        })
      }
    }

    const verifyNameMessages = {
      tr: {
        messages: {
          verify_name: field => 'Girdiğiniz reçete adı daha önce kullanılmış.'
        }
      }
    }

    Validator.extend('verify_name', verifyName)
    Validator.localize(verifyNameMessages)

    const verifyCode = {
      getMessage (field, params, data) {
        return (data && data.message) || 'Something wrong'
      },
      validate (value) {
        return new Promise(resolve => {
          let params = {
            RecipeCode: value
          }
          if (self.isEdit) {
            params.Id = self.$route.params.id
          }
          self.checkRecipeCode(params)
            .then(res => {
              if (res) {
                resolve({
                  valid: res.data.isAvailable
                })
              } else {
                resolve({
                  valid: false
                })
              }
            })
        })
      }
    }

    const verifyCodeMessages = {
      tr: {
        messages: {
          verify_code: field => "Girdiğiniz reçete numarası daha önce kullanılmış."
        }
      }
    }

    Validator.extend('verify_code', verifyCode)
    Validator.localize(verifyCodeMessages)
  },

  async mounted () {
    await this.calculatePortions()
    this.pricePermission.find(i => {
      if (i.permission === 90) this.isPriceAvailable = true
    })
    if (this.isEdit) await this.init()
    if (!this.isEdit) {
      this.dontChangePortionUnit = false
      this.form.totalUnitId = this.defaultUnitId
    }
    await this.formFocus()
  },

  computed: {
    ...mapGetters('RecipeGroups', [
      'recipeGroup_List'
    ]),

    ...mapGetters('Tax', [
      'Taxes'
    ]),

    ...mapGetters('Settings', [
      'pricePermission'
    ]),

    ...mapGetters('Recipes'[
      'Page'
    ]),

    ...mapFields({
      flagsName: 'name',
      flagsCode: 'recipeCode'
    }),

    ...mapGetters('Units', [
      'BaseUnitList',
      'getRelatedUnitList'
    ]),

    usedByProductionRecipeBefore () {
      return !!(this.form.stockItem &&
        this.form.stockItem.usedByProductionRecipeName &&
        this.form.stockItem.usedByProductionRecipeHeaderId !== this.recipeHeader.id)
    },

    isLoadingCheckBarcode () {
      return this.$wait.is(['checkBarcode'])
    },

    disableBarcodeInput () {
      return this.form.name === null
    },

    recipeHeader () {
      return {
        id: this.$route.params.id || null,
        type: this.formType,
        recipeGroupId: +this.form.recipeGroupId === -1 ? null : this.form.recipeGroupId,
        taxId: this.form.taxId === '-1' || this.isProductionType ? null : this.form.taxId,
        name: this.form.name,
        recipeCode: this.form.recipeCode,
        information: this.form.information,
        netSalesPrice: this.form.netSalesPrice,
        barcodeList: this.form.barcodeList,
        grossSalesPrice: this.form.grossSalesPrice,
        unitId: this.isProductionType ? null : this.form.unitId,
        totalUnitId: this.isProductionType ? null : this.form.totalUnitId,
        totalQuantity: this.isProductionType ? this.form.prodQuantity : this.form.totalQuantity,
        portionUnitId: this.isProductionType ? null : this.form.portionUnitId,
        portionQuantity: this.isProductionType ? 1 : this.form.portionQuantity,
        productionStockItemId: this.isProductionType ? this.form.stockItem.id : null,
        cookingTime600W: this.form.cookingTime600W,
        cookingTime750W: this.form.cookingTime750W,
        cookingTime900W: this.form.cookingTime900W,
        preferedCookingTime: this.form.preferedCookingTime
      }
    },

    isProductionType () {
      return this.formType === 2
    },

    filteredUnitList () {
      return this.getRelatedUnitList(this.form.totalUnitId, true)
    },

    isEmptyBaseUnit () {
      return +this.form.totalUnitId === -1 || this.form.totalQuantity.length === 0
    },

    isEmptyPortion () {
      return +this.form.portionUnitId === -1 || this.form.portionQuantity.length === 0
    },

    isEmptyCalculatedPortion () {
      return this.form.calculatedPortion.length === 0
    },

    isLoading () {
      return this.$wait.is(['create_RECIPE_HEADER', 'update_RECIPE_HEADER', 'onSubmitRecipeForm'])
    },

    isEdit () {
      return this.status === 'edit'
    },

    isPendingName () {
      return this.flagsName.pending
    },

    isValidateName () {
      if (this.flagsName.required) {
        return this.flagsName.valid &&
                 !this.isPendingName
      } else {
        return this.flagsName.valid &&
                 this.form.name !== '' &&
                 this.form.name !== null &&
                 !this.isPendingName
      }
    },

    isPendingCode () {
      return this.flagsCode.pending
    },

    isValidateCode () {
      if (this.flagsCode.required) {
        return this.flagsCode.valid &&
                 !this.isPendingCode
      } else {
        return this.flagsCode.valid &&
                 this.form.recipeCode !== '' &&
                 this.form.recipeCode !== null &&
                 !this.isPendingCode
      }
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('RecipeGroups', [
      'get_RECIPEGROUP_LIST'
    ]),

    ...mapActions('Tax', [
      'getTaxes'
    ]),

    ...mapActions('Recipes', [
      'get_RECIPE_HEADER',
      'create_RECIPE_HEADER',
      'update_RECIPE_HEADER',
      'checkRecipeName',
      'checkRecipeCode',
      'checkBarcode'
    ]),

    ...mapActions('Stock', [
      'searchStockItem'
    ]),

    addBarcode () {
      if (!this.isLoadingCheckBarcode && !!this.barcode.barcode) {
        if (!this.veeErrors.has('barcode')) {
          this.form.barcodeList.push(this.barcode)
          this.barcode = {
            barcode: null
          }
        } else {
          this.barcode = {
            barcode: null
          }
        }
      }
    },

    removeBarcode (index) {
      this.form.barcodeList.splice(index, 1)
    },

    onChangeTotalUnitId () {
      this.BaseUnitList.map(unit => {
        if (this.form.totalUnitId === unit.id) {
          this.selectedTotalUnit = unit
        }
      })
      if (!this.dontChangePortionUnit) this.form.portionUnitId = this.form.totalUnitId
      this.calculatePortions()
    },

    onChangePortionId () {
      this.filteredUnitList.map(unit => {
        if (this.form.portionUnitId === unit.id) {
          this.selectedPortionUnit = unit
        }
      })
      this.calculatePortions()
    },

    roundPortionNumbers (value) {
      if (value.toString().indexOf('.') > -1) {
        return Number(Number(value).toFixed(3))
      } else {
        return Number(value)
      }
    },

    calculatePortions (changedValue = 'changedDropdown') {
      const dividedMultiplier = this.form.totalQuantity * this.selectedTotalUnit.baseQuantity
      const denominatorMultiplier = this.form.portionQuantity * this.selectedPortionUnit.baseQuantity
      const quotient = this.form.calculatedPortion * this.selectedPortionUnit.baseQuantity

      if (
        changedValue === 'totalQuantity' ||
        changedValue === 'portionQuantity' ||
        changedValue === 'changedDropdown'
      ) {
        if (!this.isEmptyBaseUnit && !this.isEmptyPortion) {
          this.form.calculatedPortion = this.roundPortionNumbers(dividedMultiplier / denominatorMultiplier)
        }
      } else if (changedValue === 'calculatedPortion' || changedValue === 'changedDropdown') {
        if (!this.isEmptyBaseUnit && !this.isEmptyCalculatedPortion) {
          this.form.portionQuantity = this.roundPortionNumbers(dividedMultiplier / quotient)
        }
      }
    },

    calNetPrice () {
      let ratio = 0
      const tax = this.Taxes.find(item => item.id === this.form.taxId)
      if (tax) ratio = tax.ratio
      // (brüt*100)/(100+tax)
      this.form.netSalesPrice = (this.form.grossSalesPrice * 100) / (100 + ratio)
    },

    async init () {
      let itemId = this.$route.params.id
      await this.get_RECIPE_HEADER(itemId).then(res => {
        if (res.status === 200) {
          this.formType = res.data.recipeHeader.type
          this.form.stockItem = {
            name: res.data.recipeHeader.productionStockItemName,
            id: res.data.recipeHeader.productionStockItemId,
            unitName: res.data.recipeHeader.totalUnitName
          }
          this.form.recipeGroupId = res.data.recipeHeader.recipeGroupId || -1
          this.form.taxId = res.data.recipeHeader.taxId
          this.form.name = res.data.recipeHeader.name
          this.form.recipeCode = res.data.recipeHeader.recipeCode
          this.form.barcodeList = res.data.recipeHeader.barcodeList
          this.form.netSalesPrice = +res.data.recipeHeader.netSalesPrice
          this.form.grossSalesPrice = res.data.recipeHeader.grossSalesPrice
          this.form.information = res.data.recipeHeader.information
          this.form.totalQuantity = res.data.recipeHeader.totalQuantity
          this.form.totalUnitId = res.data.recipeHeader.totalUnitId
          this.form.portionQuantity = res.data.recipeHeader.portionQuantity
          this.form.portionUnitId = res.data.recipeHeader.portionUnitId
          this.form.cookingTime600W = res.data.recipeHeader.cookingTime600W
          this.form.cookingTime750W = res.data.recipeHeader.cookingTime750W
          this.form.cookingTime900W = res.data.recipeHeader.cookingTime900W
          this.form.preferedCookingTime = res.data.recipeHeader.preferedCookingTime

          if (this.isProductionType) this.form.prodQuantity = this.form.totalQuantity
        }
      })
        .then(() => {
          this.dontChangePortionUnit = false
        })
    },

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    formFocus () {
      this.$refs.firstField.focus()
    },

    resetState () {
      if (this.isEdit) return
      this.form.name = null
      this.form.recipeCode = null
      this.form.taxId = -1
      this.form.recipeGroupId = -1
      this.selectOptions = []
      this.selectedPortionUnit = {}
    },

    onSubmitRecipeForm: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async result => {
        if (!result || this.usedByProductionRecipeBefore) return

        if (this.isEdit) {
          await this.update_RECIPE_HEADER(this.recipeHeader).then(res => {
            if (res.status === 200) {
              const message = this.$t('Global.notification_Updated', { name: this.recipeHeader.name })
              this.notifyShow({ message })
              this.$emit('getList')
              this.close()
            }
          })
        } else {
          await this.create_RECIPE_HEADER(this.recipeHeader).then(res => {
            if (res) {
              const message = this.$t('Global.notification_Created', { name: this.recipeHeader.name })
              this.notifyShow({ message })
              this.$router.push({
                name: 'RecipeDetailNew',
                params: {
                  recipeHeaderId: res.data.recipeHeader.id
                }
              })
            }
          })
        }
      })
    }, 'onSubmitRecipeForm'),

    getOptions (search) {
      this.searchStockItem(
        {
          Text: search,
          Types: [this.formType],
          ForProductionRecipeHeaderId: this.isEdit ? this.$route.params.id : null
        }
      )
        .then(res => {
          this.selectOptions = res.data.items
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  
  .twice-item {
    width: 50%;
    &:first-child {
      margin-right: 10px;
    }
  }

  .twice-line {
    display: flex;
  }

  .cooking-time-container{
    margin-top: 20px;
    align-items: baseline;
    display: flex;
    .label-radio{
      display: flex;
      justify-content: start;
      align-items: start;
    }
  }
</style>
