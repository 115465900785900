<template lang="pug">
DeleteAlert(
  :item="item",
  :fields="fields",
  :tableName="$t('Recipes.remove_TableName')",
  loading="delete_RECIPE_HEADER",
  @delegateOnAlertRemoveOk="itemRemove")
</template>

<script>
import { mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'RecipeRemove',

  data () {
    return {
      fields: [{
        name: 'name',
        is_visible: true,
        short_title: this.$t('Recipes.remove_TableColumn_Name_Short'),
        title: this.$t('Recipes.remove_TableColumn_Name')
      }, {
        name: 'netSalesPrice',
        is_visible: true,
        short_title: this.$t('Recipes.remove_TableColumn_NetSalesPrice_Short'),
        title: this.$t('Recipes.remove_TableColumn_NetSalesPrice')
      }, {
        name: 'cost',
        is_visible: true,
        short_title: this.$t('Recipes.remove_TableColumn_Cost_Short'),
        title: this.$t('Recipes.remove_TableColumn_Cost')
      }, {
        name: 'costRatio',
        is_visible: true,
        short_title: this.$t('Recipes.remove_TableColumn_CostRatio_Short'),
        title: this.$t('Recipes.remove_TableColumn_CostRatio')
      }],
      item: {}
    }
  },

  async mounted () {
    if (this.$route.params.item) {
      this.item = this.$route.params.item
    } else {
      this.get_RECIPE_DETAIL({ recipeHeaderId: this.$route.params.id })
        .then(res => {
          this.item = res.data.recipeHeader
          if (this.item.costRatio === null) this.item.costRatio = 0
        })
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Recipes', [
      'delete_RECIPE_HEADER',
      'get_RECIPE_HEADER',
      'get_RECIPE_DETAIL'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      await this.delete_RECIPE_HEADER(this.item.id).then(res => {
        if (res) {
          const message = this.$t('Global.notification_Deleted', { name: this.item.name })
          this.notifyShow({ message })
          this.$emit('getList')
          this.close()
        }
      })
    }, 'removeRecipeHeaderForm')
  }
}
</script>

<style lang="scss">
</style>
