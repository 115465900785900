<template lang="pug">
span.alternative-show#alternative-stock-show(@click="changeShowAlternative")
    .alternative-show-icon {{ icon }}
    .alternative-show-text {{ text }}
</template>

<script>
export default {
  props: {
    showAlternative: {
      type: Boolean,
      required: true
    },
    changeShowAlternative: {
      type: Function,
      required: true
    }
  },
  computed: {
    text () {
      return this.showAlternative
        ? this.$t('Recipe.Show_Alternative_Stock_Button_Text_2')
        : this.$t('Recipe.Show_Alternative_Stock_Button_Text_1')
    },

    icon () {
      return this.showAlternative
        ? '-'
        : '+'
    }
  }
}
</script>

<style lang="scss" scoped>

  .alternative-show {
    color: $color-primary;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: $font-size-big;
    font-weight: $font-weight-bold;
    border-top: 1px solid $color-gray;
    margin-top: 20px;
    padding: 20px 0;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;

    &-icon {
      display: flex;
      width: 24px;
      height: 24px;
      font-family: $font-family-mono;
      justify-content: center;
      align-items: center;
      border: 2px solid $color-primary;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
</style>
