var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("blured-background", [
    _c("div", { staticClass: "Alert" }, [
      _c("div", { staticClass: "Alert-body" }, [
        _c(
          "figure",
          { staticClass: "Alert-icon" },
          [_c("Icon", { attrs: { name: "icon-global-copy" } })],
          1
        ),
        _c("h3", { staticClass: "Alert-title" }, [
          _vm._v(_vm._s(_vm.$t("Recipes.copyAlert_WillCopy_Title"))),
        ]),
        _c(
          "p",
          { staticClass: "Alert-description" },
          [[_vm._v(_vm._s(_vm.$t("Recipes.copyAlert_WillCopy_Description")))]],
          2
        ),
        _c("div", { staticClass: "Alert-item" }, [
          _c(
            "div",
            { staticClass: "Alert-item-name" },
            [
              _vm.item
                ? _c("span", [_vm._v(_vm._s(_vm.item.name))])
                : _c("Loading"),
            ],
            1
          ),
        ]),
        _c("div", { attrs: { id: "form-area" } }, [
          !_vm.isProductionType
            ? _c(
                "form",
                {
                  staticClass: "Alert-input",
                  attrs: { id: "form-copy-recipe" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "Alert-input-span" }, [
                    _vm._v(_vm._s(_vm.$t("Recipes.copyAlery_Input_Span"))),
                  ]),
                  _vm.isLoadingRecipe ? _c("Loading") : _vm._e(),
                  !_vm.isLoadingRecipe
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.copyCount,
                            expression: "copyCount",
                            modifiers: { number: true },
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|numeric|min_value:1|max_value:9",
                            expression:
                              "'required|numeric|min_value:1|max_value:9'",
                          },
                        ],
                        staticClass: "Alert-input-entry no-spinners",
                        class: {
                          "is-not-valid": _vm.veeErrors.has("copyCount"),
                        },
                        attrs: {
                          name: "copyCount",
                          type: "number",
                          id: "search-recipes-total-count",
                          "data-vv-as": _vm.$t(
                            "Recipes.copy_FormField_Name_Input"
                          ),
                        },
                        domProps: { value: _vm.copyCount },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.copyCount = _vm._n($event.target.value)
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      })
                    : _vm._e(),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.veeErrors.has("copyCount") &&
                            !_vm.isLoadingRecipe,
                          expression:
                            "veeErrors.has('copyCount') && !isLoadingRecipe",
                        },
                      ],
                      staticClass: "Form-item-help is-danger",
                    },
                    [_vm._v(_vm._s(_vm.veeErrors.first("copyCount")))]
                  ),
                ],
                1
              )
            : _c(
                "form",
                {
                  staticClass: "Alert-input",
                  attrs: { id: "form-copy-recipe" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "Alert-input-span search-span" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("Recipes.detail_Popup_FormField_StockItemName")
                      )
                    ),
                  ]),
                  _vm.isLoadingRecipe ? _c("Loading") : _vm._e(),
                  !_vm.isLoadingRecipe
                    ? _c("custom-search", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        ref: "firstInput",
                        staticClass: "Alert-input-search",
                        attrs: {
                          name: "productionStockItem",
                          "component-name":
                            "search-recipes-production-stock-item",
                          "data-vv-as": _vm.$t(
                            "Recipes.detail_Popup_FormField_StockItemName"
                          ),
                          inputClass: {
                            "is-not-valid":
                              _vm.veeErrors.has("productionStockItem") ||
                              _vm.usedByProductionRecipeBefore,
                          },
                          "on-search": _vm.getOptions,
                          options: _vm.selectOptions,
                          label: "name",
                          placeholder: _vm.$t(
                            "Recipes.detail_Popup_FormField_StockItemName_Placeholder"
                          ),
                        },
                        model: {
                          value: _vm.productionStockItem,
                          callback: function ($$v) {
                            _vm.productionStockItem = $$v
                          },
                          expression: "productionStockItem",
                        },
                      })
                    : _vm._e(),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.veeErrors.has("productionStockItem") &&
                            !_vm.isLoadingRecipe,
                          expression:
                            "veeErrors.has('productionStockItem') && !isLoadingRecipe",
                        },
                      ],
                      staticClass: "Form-item-help is-danger",
                    },
                    [_vm._v(_vm._s(_vm.veeErrors.first("productionStockItem")))]
                  ),
                  _vm.usedByProductionRecipeBefore
                    ? _c("p", { staticClass: "Form-item-help is-danger" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Recipes.popup_Form_Error_usedByProductionRecipeBefore",
                              {
                                name: _vm.productionStockItem
                                  .usedByProductionRecipeName,
                              }
                            )
                          )
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
        ]),
        _c(
          "div",
          { staticClass: "Alert-actions" },
          [
            _c(
              "Button",
              {
                attrs: {
                  secondary: "",
                  size: "medium",
                  id: "btn-copy-alert-close",
                  type: "button",
                },
                on: { click: _vm.close },
              },
              [_vm._v(_vm._s(_vm.$t("Recipes.copyAlert_CancelButton")))]
            ),
            _c(
              "Button",
              {
                attrs: {
                  primary: "",
                  size: "medium",
                  variant: "full",
                  id: "btn-copy-alert-submit",
                  form: "form-copy-recipe",
                  type: "submit",
                  disabled: _vm.isLoadingSubmit,
                },
              },
              [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isLoadingSubmit,
                        expression: "!isLoadingSubmit",
                      },
                    ],
                  },
                  [_vm._v(_vm._s(_vm.$t("Recipes.copyAlert_CopyButton")))]
                ),
                _c("Loading", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isLoadingSubmit,
                      expression: "isLoadingSubmit",
                    },
                  ],
                  attrs: { theme: "disable" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }