import { render, staticRenderFns } from "./recipe-training-confirmation.vue?vue&type=template&id=34c6b756&scoped=true&lang=pug&"
import script from "./recipe-training-confirmation.vue?vue&type=script&lang=js&"
export * from "./recipe-training-confirmation.vue?vue&type=script&lang=js&"
import style0 from "./recipe-training-confirmation.vue?vue&type=style&index=0&id=34c6b756&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34c6b756",
  null
  
)

export default component.exports