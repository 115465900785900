<template lang="pug">
.training-mode
    .training-mode-left
      Icon(name="icon-global-bell")
      p.m-left-20#title-training-recipes-overwrite {{ $t('Recipes.TrainingModeTitle') }}
    .training-mode-right
      Button.m-left-20(
        secondary
        size="small"
        id="btn-training-recipes-overwrite"
        :disabled="isLoading"
        @click="overwrite"
        type="button")
        span {{ $t('Recipes.TrainingModeButtonOverwrite') }}
      Button.m-left-20(
        secondary
        size="small"
        id="btn-training-recipes-asnew"
        :disabled="isLoading"
        @click="asNew"
        type="button")
        span {{ $t('Recipes.TrainingModeButtonAsNew') }}
      Button.m-left-20(
        primary
        size="small"
        id="btn-training-recipes-cancel"
        :disabled="isLoading"
        @click="cancel"
        type="button")
        span {{ $t('Recipes.TrainingModeButtonCancel') }}
</template>

<script>
export default {
  name: 'recipe-training-confirmation',
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    overwrite () {
      this.$emit('clickOverwrite')
    },
    asNew () {
      this.$emit('clickAsNew')
    },
    cancel () {
      this.$emit('clickCancel')
    }
  }
}
</script>

<style scoped lang="scss">

  .training-mode {
    background: $color-primary;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding-left: 15px;
    padding-right: 15px;
    .icon-global-bell {
      color: $color-white;
      width: 33px;
      height: 32px;
    }
    p {
      font-size: $font-size-normal;
      color: $color-white;
    }
    &-left {
      display: inherit;
      align-items: center;
    }
    &-right {
      display: inherit;
    }
  }
</style>
