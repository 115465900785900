import Recipes from '../recipes'
import Recipe from '../recipe'
import RecipeGroups from '../recipe-groups'
import RecipeGroupRemove from '../popup/remove-group'
import RecipeDetailRemove from '../popup/remove-detail'
import RecipeDetailForm from '../popup/detail'
import FormRecipe from '../popup/recipe'
import RecipeRemove from '../popup/remove-recipe'
import RecipeCopy from '../popup/copy-recipe'
import FormGroup from '../popup/group'
import RecipeTraining from '../popup/training-recipe'

export default [
  {
    path: '/recipes',
    name: 'Recipes',
    component: Recipes,
    children: [
      {
        path: 'new',
        name: 'RecipeNew',
        component: FormRecipe
      },
      {
        path: 'item/:id/edit',
        name: 'RecipeEdit',
        component: FormRecipe,
        props: {
          status: 'edit'
        }
      },
      {
        path: 'item/:id/remove',
        name: 'RecipeRemove',
        component: RecipeRemove
      },
      {
        path: 'item/:id/copy',
        name: 'RecipeCopy',
        component: RecipeCopy
      }
    ],
    meta: {
      main_menu: true,
      slug: 'recipe',
      category: 'Cost',
      permissionKey: 'RecipesMenu',
      page: 'recipe'
    }
  },
  {
    path: '/recipes/item/:recipeHeaderId',
    name: 'Recipe',
    component: Recipe,
    children: [
      {
        path: 'remove-detail/:detailId',
        name: 'RecipeDetailRemove',
        component: RecipeDetailRemove
      },
      {
        path: 'detail/new',
        name: 'RecipeDetailNew',
        component: RecipeDetailForm
      },
      {
        path: 'detail/:detailId',
        name: 'RecipeDetailEdit',
        component: RecipeDetailForm,
        props: {
          status: 'edit'
        }
      },
      {
        path: 'training',
        name: 'RecipeTraining',
        component: RecipeTraining
      }
    ],
    meta: {
      permissionKey: 'RecipesMenu',
      page: 'recipe'
    }
  },
  {
    path: '/recipes/recipes-groups',
    name: 'RecipeGroups',
    component: RecipeGroups,
    children: [
      {
        path: 'new',
        name: 'RecipeGroupNew',
        component: FormGroup
      },
      {
        path: ':id/edit',
        name: 'RecipeGroupEdit',
        component: FormGroup,
        props: {
          status: 'edit'
        }
      },
      {
        path: ':id/remove',
        name: 'RecipeGroupRemove',
        component: RecipeGroupRemove
      }
    ],
    meta: {
      permissionKey: 'RecipesMenu',
      page: 'recipe'
    }
  }
]
