export default function($t, isPriceAvailable) {
  return [
    {
      name: 'action-buttons',
      type: 'portal',
      headerTitle: false,
      is_visible: true,
      get short_title() {
        return $t('Recipe.allergenName')
      },
      get title() {
        return $t('Recipe.allergenName')
      }
    },
    {
      name: 'action-calorie',
      type: 'portal',
      headerTitle: false,
      is_visible: true,
      get short_title() {
        return $t('Recipes.calorie')
      },
      get title() {
        return $t('Recipes.calorie')
      }
    },
    {
      name: 'id',
      is_visible: false,
      is_sortable: true,
      sortingObject: {
        columnName: 'id',
        sortType: null
      }
    },
    {
      name: 'name',
      is_visible: true,
      headerTitle: true,
      is_sortable: true,
      get short_title() {
        return $t('Recipe.summary_recipe_name')
      },
      get title() {
        return $t('Recipe.summary_recipe_name')
      },
      sortingObject: {
        columnName: 'name',
        sortType: null
      }
    },
    {
      name: 'grossSalesPrice',
      is_visible: true,
      default: '0',
      type: 'currency',
      is_sortable: isPriceAvailable,
      get short_title() {
        return $t('Recipes.extraInfo_GrossSalesPrice')
      },
      get title() {
        return $t('Recipes.extraInfo_GrossSalesPrice')
      },
      sortingObject: {
        columnName: 'grossSalesPrice',
        sortType: null
      }
    },
    {
      name: 'netSalesPrice',
      is_visible: true,
      type: 'currency',
      is_sortable: isPriceAvailable,
      get short_title() {
        return $t('Recipes.summary_NetSalesPrice')
      },
      get title() {
        return $t('Recipes.summary_NetSalesPrice')
      },
      sortingObject: {
        columnName: 'netSalesPrice',
        sortType: null
      }
    },
    {
      name: 'profit',
      is_visible: true,
      type: 'currency',
      is_sortable: isPriceAvailable,
      get short_title() {
        return $t('Recipes.extraInfo_Profit')
      },
      get title() {
        return $t('Recipes.extraInfo_Profit')
      },
      sortingObject: {
        columnName: 'profit',
        sortType: null
      }
    },
    {
      name: 'profitRatio',
      is_visible: true,
      is_sortable: isPriceAvailable,
      type: 'percent',
      get short_title() {
        return $t('Recipes.extraInfo_ProfitRatio')
      },
      get title() {
        return $t('Recipes.extraInfo_ProfitRatio')
      },
      sortingObject: {
        columnName: 'profitRatio',
        sortType: null
      }
    },
    {
      name: 'cost',
      is_visible: true,
      is_sortable: isPriceAvailable,
      type: 'currency',
      get short_title() {
        return $t('Recipes.summary_Cost')
      },
      get title() {
        return $t('Recipes.summary_Cost')
      },
      sortingObject: {
        columnName: 'cost',
        sortType: null
      }
    },
    {
      name: 'costRatio',
      is_visible: true,
      is_sortable: isPriceAvailable,
      type: 'percent',
      get short_title() {
        return $t('Recipes.summary_CostRatio')
      },
      get title() {
        return $t('Recipes.summary_CostRatio')
      },
      sortingObject: {
        columnName: 'costRatio',
        sortType: null
      }
    }
  ]
}
