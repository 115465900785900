<template lang="pug">
blured-background
    .Alert
      .Alert-body

        .Alert-header

          figure.Alert-icon
            Icon(name="icon-global-bell")

          h3.Alert-title {{$t('Recipes.TrainingAlert_WillTraining_Title') }}
          p.Alert-description
            template {{$t('Recipes.TrainingAlert_WillTraining_Description') }}
        Loading(v-if="isLoadingRecipe")
        #form-area(v-else)
          form(
            id="form-training-recipe"
            @submit.prevent="submit"
          )
            .Alert-input
              span.Alert-input-span {{ $t('Recipes.TrainingAlert_Input_Name') }}
              Loading(v-if="isLoadingRecipe")
              input.txt(
                name="name"
                ref="firstInput"
                autocomplete="off"
                id="input-recipes-training-name"
                v-model="name"
                v-validate="'required|max:128|very_singularity:Recipe/header/check/name,name'"
                :data-vv-as="$t('Recipes.TrainingAlert_Input_Name')"
                :class="{ 'is-danger': veeErrors.has('name') }")
              p.Form-item-help.is-danger(
              v-show="veeErrors.has('name') && !isLoadingRecipe"
              ) {{ veeErrors.first('name') }}

            .Alert-input(v-if="isProductionType")
              span.Alert-input-span.search-span {{ $t('Recipes.detail_Popup_FormField_StockItemName') }}
              Loading(v-if="isLoadingRecipe")
              custom-search.Alert-input-search(
                name="productionStockItem"
                component-name="search-recipes-production-stock-item"
                v-model="productionStockItem"
                :data-vv-as="$t('Recipes.detail_Popup_FormField_StockItemName')"
                v-validate="'required'"
                :inputClass="{ 'is-danger': veeErrors.has('productionStockItem') || usedByProductionRecipeBefore }"
                :on-search="getOptions"
                :options="selectOptions"
                label="name"
                :placeholder="$t('Recipes.detail_Popup_FormField_StockItemName_Placeholder')"
              )

              p.Form-item-help.is-danger(
              v-show="veeErrors.has('productionStockItem') && !isLoadingRecipe"
              ) {{ veeErrors.first('productionStockItem') }}

              p.Form-item-help.is-danger(
              v-if="usedByProductionRecipeBefore"
              ) {{ $t('Recipes.popup_Form_Error_usedByProductionRecipeBefore', { name: productionStockItem.usedByProductionRecipeName }) }}

        .Alert-actions
          Button(
          secondary
          size="medium"
          id="btn-training-alert-close"
          type="button",
          @click="close"
          ) {{ $t('Recipes.TrainingAlert_CancelButton') }}
          Button(
          primary
          size="medium"
          variant="full"
          id="btn-training-alert-submit"
          form="form-training-recipe",
          type="submit",
          :disabled="isLoadingSubmit || isLoadingRecipe")
            span(v-show="!isLoadingSubmit") {{ $t('Recipes.TrainingAlert_TrainingButton') }}
            Loading(theme="disable", v-show="isLoadingSubmit")
</template>

<script>
import { mapActions } from 'vuex'
import bluredBackground from '@/view/global/full-blur-background'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'RecipeTraining',

  components: {
    bluredBackground
  },

  data () {
    return {
      item: {},
      productionStockItem: null,
      name: null,
      selectOptions: []
    }
  },

  computed: {

    usedByProductionRecipeBefore () {
      return !!(this.productionStockItem && this.productionStockItem.usedByProductionRecipeName)
    },
    isLoadingSubmit () {
      return this.$wait.is(['finalize_TRAINING_RECIPE', 'submitTrainingRecipe'])
    },

    isLoadingRecipe () {
      return this.$wait.is(['get_RECIPE_HEADER'])
    },

    isProductionType () {
      return this.item.type === 2
    }
  },

  created () {
    this.item = this.$route.params.item

    if (!this.$route.params.item) {
      let id = this.$route.params.recipeHeaderId
      this.get_RECIPE_HEADER(id).then(res => {
        this.item = res.data.recipeHeader
      })
    }
  },

  mounted () {
    this.focusFirstInput()
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Stock', [
      'searchStockItem'
    ]),

    ...mapActions('Recipes', [
      'finalize_TRAINING_RECIPE',
      'get_RECIPE_HEADER'
    ]),
    focusFirstInput () {
      this.$nextTick(() => this.$refs.firstInput.focus())
    },
    close () {
      this.$router.go(-1)
    },

    submit: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async result => {
        if (!result || this.usedByProductionRecipeBefore) return

        const params = {
          name: this.name,
          trainingRecipeHeaderId: this.item.id,
          processType: 1,
          productionStockItemId: this.isProductionType ? this.productionStockItem.id : null
        }

        await this.finalize_TRAINING_RECIPE(params)
          .then(res => {
            if (res) {
              this.$emit('getList')
              this.close()
            }
          })
      })
    }, 'submitTrainingRecipe'),

    getOptions (search) {
      this.searchStockItem({
        Text: search,
        Types: [2],
        ForProductionRecipeHeaderId: this.$route.params.id
      }).then(res => {
        this.selectOptions = res.data.items
      })
    }
  }
}
</script>

<style lang="scss" scoped>

  .Alert {

    &-body {
      text-align: center;
    }

    &-icon {
      margin-bottom: 30px;
    }

    &-title {
      font-size: 1.6em;
    }

    &-description {
      font-size: 1.2em;
      margin-top: 7px;
      color: $color-text-light;
    }

    &-input {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;

    &-search {
      width: 100%;
    }

      &-span {
        color: $color-light;
        font-family: $font-family;
        opacity: 0.72;
        align-self: flex-start;
        margin-bottom: 10px;
      }
    }

    &-actions {
      margin-left: auto;
      margin-right: auto;
      max-width: 500px;
      margin-top: 25px;
      display: flex;
      align-items: center;
      z-index: 20;

      .btn {
        padding-left: 32px;
        padding-right: 33px;
        margin-left: 10px;
        margin-right: 10px;
        opacity: 0.72;
        transition: opacity 0.2s ease-in-out;
        text-transform: uppercase;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

.icon-global-bell {
  color: $color-success;
  opacity: 1;
  width: 46px;
  height: 56px;
}
</style>
