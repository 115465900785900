export default function($t) {
  return [
    {
      key: 'grossSalesPrice',
      type: 'currency',
      isEditable: true,
      translation: $t('Recipe.stats_GrossSalesPrice')
    },
    {
      key: 'netSalesPrice',
      type: 'currency',
      isEditable: false,
      translation: $t('Recipe.stats_NetSalesPrice')
    },
    {
      key: 'cost',
      type: 'currency',
      isEditable: false,
      translation: $t('Recipe.stats_Cost')
    },
    {
      key: 'costRatio',
      type: 'percent',
      isEditable: true,
      translation: $t('Recipe.stats_CostRatio')
    },
    {
      key: 'profit',
      type: 'currency',
      isEditable: true,
      translation: $t('Recipe.stats_Profit')
    },
    {
      key: 'profitRatio',
      type: 'percent',
      isEditable: true,
      translation: $t('Recipe.stats_ProfitRatio')
    }
  ]
}
