var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "training-mode" }, [
    _c(
      "div",
      { staticClass: "training-mode-left" },
      [
        _c("Icon", { attrs: { name: "icon-global-bell" } }),
        _c(
          "p",
          {
            staticClass: "m-left-20",
            attrs: { id: "title-training-recipes-overwrite" },
          },
          [_vm._v(_vm._s(_vm.$t("Recipes.TrainingModeTitle")))]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "training-mode-right" },
      [
        _c(
          "Button",
          {
            staticClass: "m-left-20",
            attrs: {
              secondary: "",
              size: "small",
              id: "btn-training-recipes-overwrite",
              disabled: _vm.isLoading,
              type: "button",
            },
            on: { click: _vm.overwrite },
          },
          [
            _c("span", [
              _vm._v(_vm._s(_vm.$t("Recipes.TrainingModeButtonOverwrite"))),
            ]),
          ]
        ),
        _c(
          "Button",
          {
            staticClass: "m-left-20",
            attrs: {
              secondary: "",
              size: "small",
              id: "btn-training-recipes-asnew",
              disabled: _vm.isLoading,
              type: "button",
            },
            on: { click: _vm.asNew },
          },
          [
            _c("span", [
              _vm._v(_vm._s(_vm.$t("Recipes.TrainingModeButtonAsNew"))),
            ]),
          ]
        ),
        _c(
          "Button",
          {
            staticClass: "m-left-20",
            attrs: {
              primary: "",
              size: "small",
              id: "btn-training-recipes-cancel",
              disabled: _vm.isLoading,
              type: "button",
            },
            on: { click: _vm.cancel },
          },
          [
            _c("span", [
              _vm._v(_vm._s(_vm.$t("Recipes.TrainingModeButtonCancel"))),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }