export default function($t) {
  return [
    {
      name: 'nutritionFieldName',
      is_visible: true,
      get short_title() {
        return $t('Recipe.popupAdd_UnitId')
      },
      get title() {
        return $t('Recipe.popupAdd_UnitId')
      }
    },
    {
      name: 'per100gAmount',
      is_visible: true,
      get short_title() {
        return $t('Recipe.per100gAmount')
      },
      get title() {
        return $t('Recipe.per100gAmount')
      }
    },
    {
      name: 'perServingAmount',
      is_visible: true,
      get short_title() {
        return $t('Recipe.perServingAmount')
      },
      get title() {
        return $t('Recipe.perServingAmount')
      }
    },

    {
      name: 'totalAmount',
      is_visible: true,
      get short_title() {
        return $t('Recipe.totalAmount')
      },
      get title() {
        return $t('Recipe.totalAmount')
      }
    }
  ]
}
