<template lang="pug">
Popup

    template(slot="title")
      div
        span(v-if="!isEdit") {{ $t('Recipe.popupAdd_Header') }}
        span(v-else) {{ $t('Recipe.popupAdd_Header_Edit') }}
      .PopupHeader(v-if="!isEdit")
        .tab
          Button(
          size="small"
          id="btn-recipes-popup-tab-header-1"
          :class="{ 'isSelected' : activeTabIndex === 0 }",
          @click="tabChange(0)",
          type="button"
          ) {{ $t('Recipe.popupAdd_Header_StockItem') }}
          Button(
          size="small"
          id="btn-recipes-popup-tab-header-2"
          :class="{ 'isSelected' : activeTabIndex === 1 }",
          @click="tabChange(1)",
          type="button"
          ) {{ $t('Recipe.popupAdd_Header_Recipe') }}
    template(slot="content" v-if="isLoading")
      Loading
    template(slot="content" v-if="!isLoading")
      Preview(v-if="showAlternative" :data="previewData" :onClickPreviewEdit="changeShowAlternative")

      form.Form(
      v-show="isStockTab"
      @submit.prevent="submitNewStockItem"
      id="form-new-stock-item")

        .Form-item.required
          label.Form-item-label {{ $t('Recipe.popupAdd_StockItemName') }}
          .control
            custom-search(
              component-name="recipe-detail-stock-item"
              name="stockItem",
              v-model="formNewStockItem.stockItem",
              :data-vv-as="$t('Recipe.popupAdd_StockItemName')",
              v-validate="isStockTab ? 'required' : ''",
              :inputClass="{ 'is-danger': veeErrors.has('stockItem') || !validStock }",
              ref="stockItem"
              id="search-stock-item"
              :on-search="getStockItemOptions",
              :options="formNewStockItemSelectOptions",
              :disabled="isEdit"
              label="name",
              :placeholder="$t('Recipe.popupAdd_StockItemName_Placeholder')"
              :directSelectOption="directSelectOptionStockItem"
            )

          p.Form-item-help.is-danger(
          v-show="veeErrors.has('stockItem')"
          ) {{ veeErrors.first('stockItem') }}

          p.Form-item-help.is-danger(
          v-show="!veeErrors.has('stockItem') && !validStock"
          ) {{ $t('Recipe.checkStock') }}

          p.row-info(v-if="shouldShowBaseUnit")
            span.row-info-text {{ $t('Recipe.popupAdd_StockItemName_BaseUnitText') }}
            span.row-info-value {{ findUnit(formNewStockItem.stockItem.baseUnitId).code }}

        .Form-item.required
          label.Form-item-label {{ $t('Recipe.popupAdd_StockItemQuantity') }}
          .control

          customNumberInput(
              name="stockItemQuantity"
              :error="veeErrors.has('stockItemQuantity')"
              :data-vv-as="$t('Recipe.popupAdd_StockItemQuantity')"
              v-validate="isStockTab ? {required: true, decimal: $getConst('DECIMAL_FOR_QUANTITY'), is_not: 0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}: ''"
              id="input-recipes-popup-new-stock-item-quanity"
              v-model="formNewStockItem.netQuantity"
            )

          showValidateError(
              v-show="veeErrors.has('stockItemQuantity')"
              :errorName="veeErrors.first('stockItemQuantity')"
          )

          p.row-info(v-if="shouldShowBaseUnitQuantity && !veeErrors.has('stockItemQuantity')")
            span.row-info-text {{ $t('Recipe.popupAdd_StockItemName_BaseUnitQuantityText') + ' '}}
            span.row-info-value {{ Number(baseUnitQuantity.toFixed(6)) + ' ' +  findUnit(this.formNewStockItem.stockItem.baseUnitId).code }}

        .Form-item.required
          label.Form-item-label {{ $t('Recipe.popupAdd_UnitId') }}
          .control.form-item-select
            Icon(name="icon-down-arrow")
            select.select(
            name="unitId"
            :data-vv-as="$t('Recipe.popupAdd_UnitId')"
            v-model="formNewStockItem.unitId"
            v-validate="isStockTab ? 'required' : ''"
            id="select-recipe-popup-add"
            :class="{ 'is-danger': veeErrors.has('unitId') }")
              option(
              v-for="unit in units",
              :key="unit.id",
              :value="unit.id"
              :id="`option-recipe-popup-add-${unit.name}`"
              ) {{ unit.name }}

            showValidateError(
              v-show="veeErrors.has('unitId')"
              :errorName="veeErrors.first('unitId')"
            )

        .m-top-20(id="recipe-losses-container" v-if="formNewStockItem.stockItem")
          .Form-item.double-input
            .Form-item.control
              label.Form-item-label {{ $t('Recipe.popup_item_Loss1') }}
              customNumberInput(
                name="loss1"
                :error="veeErrors.has('loss1')"
                :isTypePercentage="true"
                :data-vv-as="$t('Recipe.popup_item_Loss1')"
                v-validate="'greater_than:0|max_value:100'"
                v-model="formNewStockItem.loss1"
                @input="calculateGrossQuantity(formNewStockItem)"
                :class="{ 'is-danger': veeErrors.has('loss1') }"
                placeholder=",00"
                id="input-recipes-popup-loss1"
              )

            .Form-item.control
              label.Form-item-label {{ $t('Recipe.popup_item_Loss2') }}
              customNumberInput(
                name="loss2"
                :error="veeErrors.has('loss2')"
                :data-vv-as="$t('Recipe.popup_item_Loss2')"
                v-validate="'greater_than:0|max_value:100'"
                :isTypePercentage="true"
                id="input-recipes-popup-loss2"
                v-model="formNewStockItem.loss2"
                placeholder=",00"
                :disabled="!formNewStockItem.loss1"
              )

          showValidateError(
              v-show="veeErrors.has('loss1')"
              :errorName="veeErrors.first('loss1')"
          )

          showValidateError(
            v-show="veeErrors.has('loss2')"
            :errorName="veeErrors.first('loss2')"
          )

          .row-info
            span.row-info-text {{ $t('Recipe.form_label_Field_gross_Quantity') }}
            span.row-info-value {{ grossQuantity.toFixed(6) }} {{ findUnit(formNewStockItem.unitId).name }}

      AlternativeShowButton(v-if="activeTabIndex === 0" :showAlternative="showAlternative" :changeShowAlternative="changeShowAlternative")

      form.Form(
      v-show="isAlternativeStockTab"
      @submit.prevent="submitNewStockItem"
      id="form-new-stock-item")

        .Form-item.required
          label.Form-item-label {{ $t('Recipe.popupAdd_StockItemName') }}
          .control
            custom-search(
              component-name="recipe-detail-alternative-stock-item"
              name="alternativeStockItem",
              v-model="formNewAlternativeStockItem.stockItem",
              :data-vv-as="$t('Recipe.popupAdd_StockItemName')",
              v-validate="isAlternativeStockTab && !isEmptyAlternative ? 'required' : ''",
              :inputClass="{ 'is-danger': veeErrors.has('alternativeStockItem') || !validStock }",
              ref="alternativeStockItem"
              id="search-alternative-stock-item"
              :on-search="getAlternativeStockItemOptions",
              :options="formNewAlternativeStockItemSelectOptions",
              label="name",
              :placeholder="$t('Recipe.popupAdd_StockItemName_Placeholder')"
              :directSelectOption="directSelectOptionAlternativeStockItem"
            )

          p.Form-item-help.is-danger(
          v-show="veeErrors.has('alternativeStockItem')"
          ) {{ veeErrors.first('alternativeStockItem') }}

          p.Form-item-help.is-danger(
          v-show="!veeErrors.has('alternativeStockItem') && !validStock"
          ) {{ $t('Recipe.checkStock') }}

          p.row-info(v-if="shouldShowAlternativeBaseUnit")
            span.row-info-text {{ $t('Recipe.popupAdd_StockItemName_BaseUnitText') }}
            span.row-info-value {{ findUnit(formNewAlternativeStockItem.stockItem.baseUnitId).code }}

        .Form-item.required
          label.Form-item-label {{ $t('Recipe.popupAdd_StockItemQuantity') }}
          .control
            customNumberInput(
              name="alternativeStockItemQuantity"
              :error="veeErrors.has('alternativeStockItemQuantity')"
              :data-vv-as="$t('Recipe.popupAdd_StockItemQuantity')"
              v-validate="isAlternativeStockTab && !isEmptyAlternative ? 'required|decimal:6|is_not:0' : ''"
              id="input-alternative-recipes-popup-new-stock-item-quanity"
              v-model="formNewAlternativeStockItem.netQuantity"
            )
          showValidateError(
              v-show="veeErrors.has('alternativeStockItemQuantity')"
              :errorName="veeErrors.first('alternativeStockItemQuantity')"
          )

          p.row-info(v-if="shouldShowAlternativeBaseUnitQuantity && formNewAlternativeStockItem.stockItem && formNewAlternativeStockItem.stockItem.baseUnitId && !veeErrors.has('alternativeStockItemQuantity')")
            span.row-info-text {{ $t('Recipe.popupAdd_StockItemName_BaseUnitQuantityText') + ' '}}
            span.row-info-value {{ Number(alternativeBaseUnitQuantity.toFixed(6)) + ' ' +  findUnit(this.formNewAlternativeStockItem.stockItem.baseUnitId).code }}

        .Form-item.required
          label.Form-item-label {{ $t('Recipe.popupAdd_UnitId') }}
          .control.form-item-select
            Icon(name="icon-down-arrow")
            select.select(
            name="unitId"
            :data-vv-as="$t('Recipe.popupAdd_UnitId')"
            v-model="formNewAlternativeStockItem.unitId"
            v-validate="isAlternativeStockTab && !isEmptyAlternative ? 'required' : ''",
            id="select-alternative-recipe-popup-add"
            :class="{ 'is-danger': veeErrors.has('unitId') }")
              option(
              v-for="aUnit in alternativeUnits",
              :key="aUnit.id",
              :value="aUnit.id"
              :id="`option-recipe-popup-add-${aUnit.name}`"
              ) {{ aUnit.name }}

            showValidateError(
              v-show="veeErrors.has('unitId')"
              :errorName="veeErrors.first('unitId')"
            )

        .m-top-20(id="recipe-losses-container" v-if="formNewAlternativeStockItem.stockItem")
          .Form-item.double-input
            .Form-item.control
              label.Form-item-label {{ $t('Recipe.popup_item_Loss1') }}
              input.txt.no-spinners(
                name="alternativeLoss1"
                type="number"
                step="any"
                autocomplete="off"
                :data-vv-as="$t('Recipe.popup_item_Loss1')"
                v-model.number="formNewAlternativeStockItem.loss1"
                v-validate="isAlternativeStockTab && !isEmptyAlternative ? 'greater_than:0|max_value:100' : ''",
                @input="calculateGrossQuantity(formNewAlternativeStockItem)"
                :class="{ 'is-danger': veeErrors.has('alternativeLoss1') }"
                placeholder="%"
                id="input-recipes-popup-alternativeLoss1"
              )

            .Form-item.control
              label.Form-item-label {{ $t('Recipe.popup_item_Loss2') }}

              input.txt.no-spinners(
                name="alternativeLoss2"
                type="number"
                step="any"
                placeholder="%"
                autocomplete="off"
                :data-vv-as="$t('Recipe.popup_item_Loss2') "
                :disabled="!formNewAlternativeStockItem.loss1"
                v-model.number="formNewAlternativeStockItem.loss2"
                v-validate="isAlternativeStockTab && !isEmptyAlternative ? 'greater_than:0|max_value:100' : ''",
                :class="{ 'is-danger': veeErrors.has('alternativeLoss2') }"
                id="input-recipes-popup-loss2"
              )

          showValidateError(
              v-show="veeErrors.has('alternativeLoss1')"
              :errorName="veeErrors.first('alternativeLoss1')"
          )

          showValidateError(
              v-show="veeErrors.has('alternativeLoss2')"
              :errorName="veeErrors.first('alternativeLoss2')"
          )

          p.row-info
            span.row-info-text {{ $t('Recipe.form_label_Field_gross_Quantity') }}
            span.row-info-value {{ alternativeGrossQuantity.toFixed(6) }} {{ findUnit(formNewAlternativeStockItem.unitId).name }}

      form.Form(
      v-show="isRecipeTab"
      @submit.prevent="submitNewRecipe"
      id="form-new-recipe")

        .Form-item.required
          label.Form-item-label {{ $t('Recipe.popupAdd_RecipeId') }}
          .control
            custom-search(
              :disabled="isEdit"
              name="recipe",
              component-name="recipe-detail-recipe"
              v-model="formNewRecipe.recipe",
              :data-vv-as="$t('Recipe.popupAdd_Recipe')",
              v-validate="isRecipeTab ? 'required' : ''",
              :inputClass="{ 'is-danger': veeErrors.has('recipe') }",
              ref="recipe"
              id="search-recipe"
              :on-search="getRecipeOptions",
              :options="formNewRecipeSelectOptions",
              label="name",
              :placeholder="$t('Recipe.popupAdd_RecipeId_Placeholder')"
              @afterSelected="setRecipe"
          )

          showValidateError(
              v-show="veeErrors.has('recipe')"
              :errorName="veeErrors.first('recipe')"
          )

        .Form-item.required
          label.Form-item-label {{ $t('Recipe.popupAdd_Quantity') }}
          .control
            customNumberInput(
              name="recipeQuantity"
              :error="veeErrors.has('recipeQuantity')"
              :data-vv-as="$t('Recipe.popupAdd_recipeQuantity')"
              v-validate="isRecipeTab ? {required: true, decimal: $getConst('DECIMAL_FOR_QUANTITY'), max: $getConst('MAX_DIGITS_FOR_QUANTITY')} : ''"
              id="input-recipes-popup-quanity"
              v-model="formNewRecipe.netQuantity"
            )
          showValidateError(
            v-show="veeErrors.has('recipeQuantity')"
            :errorName="veeErrors.first('recipeQuantity')"
          )

        .row-info(v-if="formNewRecipe.recipe")
          span.row-info-text {{ $t('Recipes.form_label_Field_portionUnitId') }}
          span.row-info-value {{ formNewRecipe.recipe.portionQuantity + ' ' + formNewRecipe.recipe.portionUnitName }}

        .m-top-20(id="recipe-losses-container" v-if="formNewRecipe.recipe")
          .Form-item.double-input
            .Form-item.control
              label.Form-item-label {{ $t('Recipe.popup_item_Loss1') }}
              customNumberInput(
                name="loss1"
                :data-vv-as="$t('Recipe.popup_item_Loss1') "
                v-model="formNewRecipe.loss1"
                v-validate="isRecipeTab ? 'greater_than:0|max_value:100' : ''"
                @input="calculateGrossQuantity(formNewRecipe)"
                :class="{ 'is-danger': veeErrors.has('loss1') }"
                type="text"
                placeholder=",00"
                id="input-recipes-popup-loss1"
              )

            .Form-item.control
              label.Form-item-label {{ $t('Recipe.popup_item_Loss2') }}
              customNumberInput(
                name="loss2"
                :data-vv-as="$t('Recipe.popup_item_Loss2') "
                v-model="formNewRecipe.loss2"
                v-validate="isRecipeTab ? 'greater_than:0|max_value:100' : ''"
                @input="calculateGrossQuantity(formNewRecipe)"
                :class="{ 'is-danger': veeErrors.has('loss2') }"
                :disabled="!formNewRecipe.loss1"
                type="text"
                id="input-recipes-popup-loss2"
                placeholder=",00"
              )

          showValidateError(
              v-show="veeErrors.has('loss1')"
              :errorName="veeErrors.first('loss1')"
          )

          showValidateError(
              v-show="veeErrors.has('loss2')"
              :errorName="veeErrors.first('loss2')"
          )

          .row-info
            span.row-info-text {{ $t('Recipe.form_label_Field_gross_Quantity') }}
            span.row-info-value.font-weight-bold {{ grossQuantity.toFixed(6) + ' ' }}
            span.row-info-value.m-left-5 {{ formNewRecipe.recipe.portionQuantity + ' ' + formNewRecipe.recipe.portionUnitName }}

    // Footer

    template(
    slot="footer"
    v-if="activeTabIndex === 0 && !isLoading"
    )
      .add-other-items-checkbox-container(v-if="!isEdit")
        CustomCheckbox(
        id="checkbox-recipes-detail-recipe-save-and-new"
        :label="$t('Global.formSaveAndNewText', { form: $t('Recipe.saveAndNewKey') })"
        v-model="saveAndNew")

      Button(
        primary
        size="medium"
        variant="full"
      id="btn-recipes-new-detail-popup-submit-stock"
      type="submit"
      form="form-new-stock-item"
      :disabled="isCreating")
        span(v-show="!isCreating") {{ isEdit ? $t('Recipe.popupAdd_Button_Edit') : $t('Recipe.popupAdd_Button') }}
        Loading(theme="disable", v-show="isCreating")

    template(
    slot="footer"
    v-if="activeTabIndex === 1 && !isLoading"
    )
      .add-other-items-checkbox-container(v-if="!isEdit")
        CustomCheckbox(
        id="checkbox-recipes-detail-stock-save-and-new"
        :label="$t('Global.formSaveAndNewText', { form: $t('Recipe.saveAndNewKey') })"
        v-model="saveAndNew")

      Button(
        primary
        size="medium"
        variant="full"
      id="btn-recipes-new-detail-popup-submit-recipe"
      type="submit"
      form="form-new-recipe"
      :disabled="isCreating")
        span(v-show="!isCreating") {{ isEdit ? $t('Recipe.popupAdd_Button_Edit') : $t('Recipe.popupAdd_Button') }}
        Loading(theme="disable", v-show="isCreating")

</template>

<script>
import { mapActions, mapGetters} from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
import AlternativeShowButton from '@/view/pages/Recipes/components/show-alternative'
import Preview from '@/view/pages/Recipes/components/preview'

export default {
  name: 'RecipeDetailForm',

  props: {
    status: {
      type: String,
      default: 'new'
    }
  },

  components: {
    AlternativeShowButton,
    Preview
  },

  watch: {
    'formNewStockItem.stockItem' (val) {
      if (val && !this.isEdit) {
        const hasUnitInMyList = !!this.units.find(item => item.id === val.unitId)
        const unitId = hasUnitInMyList ? val.unitId : this.units[0].id
        this.formNewStockItem.unitId = unitId
      }
    },
    'formNewAlternativeStockItem.stockItem' (val) {
      if (val && this.isOpenedAlternative) {
        const hasUnitInMyList = !!this.alternativeUnits.find(item => item.id === val.unitId)
        const unitId = hasUnitInMyList ? val.unitId : this.alternativeUnits[0].id
        this.formNewAlternativeStockItem.unitId = unitId
        this.$validator.reset()
      }
    }
  },

  data () {
    return {
      activeTabIndex: 0,
      isOpenedAlternative: false,

      showAlternative: false,

      item: {},

      formNewStockItemSelectOptions: [],
      formNewAlternativeStockItemSelectOptions: [],
      saveAndNew: true,
      formNewStockItem: {
        stockItem: null,
        unitId: null,
        netQuantity: null,
        loss1: null,
        loss2: null
      },
      formNewAlternativeStockItem: {
        stockItem: null,
        unitId: null,
        netQuantity: null,
        loss1: null,
        loss2: null
      },

      formNewRecipeSelectOptions: [],
      formNewRecipe: {
        recipe: null,
        netQuantity: null,
        unitId: null,
        loss1: null,
        loss2: null
      }
    }
  },

  async created () {
    this.focusFirstInput()
  },

  async mounted () {
    this.$wait.start('isLoading')
    await this.init()
    this.$wait.end('isLoading')
  },

  computed: {
    ...mapGetters('Units', [
      'getRelatedUnitList',
      'BaseUnitList',
      'findUnit'
    ]),

    isLoading () {
      return this.$wait.is('isLoading')
    },

    isEdit () {
      return this.status === 'edit'
    },

    isRecipeTab () {
      return this.activeTabIndex === 1
    },

    isStockTab () {
      return this.activeTabIndex === 0 && !this.showAlternative
    },

    isAlternativeStockTab () {
      return this.activeTabIndex === 0 && this.showAlternative
    },

    validStock () {
      return this.formNewAlternativeStockItem.stockItem ? this.formNewAlternativeStockItem.stockItem.id !== this.formNewStockItem.stockItem.id : true
    },

    isEmptyAlternative () {
      // check always areas for analize required fields
      return !this.formNewAlternativeStockItem.stockItem &&
        !this.formNewAlternativeStockItem.netQuantity
    },

    shouldShowBaseUnit () {
      return this.formNewStockItem.stockItem && this.formNewStockItem.unitId
    },

    shouldShowBaseUnitQuantity () {
      return this.shouldShowBaseUnit && this.formNewStockItem.netQuantity
    },

    baseUnitQuantity () {
      return this.formNewStockItem.netQuantity * this.findUnit(this.formNewStockItem.unitId).baseQuantity
    },

    shouldShowAlternativeBaseUnit () {
      return this.formNewAlternativeStockItem.stockItem && this.formNewAlternativeStockItem.unitId
    },

    shouldShowAlternativeBaseUnitQuantity () {
      return this.shouldShowBaseUnit && this.formNewAlternativeStockItem.netQuantity
    },

    alternativeBaseUnitQuantity () {
      return this.formNewAlternativeStockItem.netQuantity * this.findUnit(this.formNewAlternativeStockItem.unitId).baseQuantity
    },

    isCreating () {
      return this.$wait.is(['create_RECIPE_DETAIL_STOCK', 'create_RECIPE_DETAIL_RECIPE', 'update_RECIPE_DETAIL', 'submitRecipeDetailForm'])
    },

    grossQuantity () {
      if (this.activeTabIndex === 1) {
        return this.formNewRecipe.netQuantity / this.calculateLoss(this.formNewRecipe.loss1) / this.calculateLoss(this.formNewRecipe.loss2)
      } else {
        return this.formNewStockItem.netQuantity / this.calculateLoss(this.formNewStockItem.loss1) / this.calculateLoss(this.formNewStockItem.loss2)
      }
    },

    alternativeGrossQuantity () {
      return (this.formNewAlternativeStockItem.netQuantity) / (this.calculateLoss(this.formNewAlternativeStockItem.loss1) / this.calculateLoss(this.formNewAlternativeStockItem.loss2))
    },

    units () {
      return this.formNewStockItem.stockItem
        ? this.getRelatedUnitList(this.formNewStockItem.stockItem.baseUnitId, true)
        : []
    },

    alternativeUnits () {
      return this.formNewAlternativeStockItem.stockItem
        ? this.getRelatedUnitList(this.formNewAlternativeStockItem.stockItem.baseUnitId, true)
        : []
    },

    previewData () {
      return [
        {
          key: this.$t('Recipe.popupAdd_StockItemName'),
          value: this.formNewStockItem.stockItem ? this.formNewStockItem.stockItem.name : null
        },
        {
          key: this.$t('Recipe.popupAdd_StockItemQuantity'),
          value: this.formNewStockItem.netQuantity
        },
        {
          key: this.$t('Recipe.popup_item_Loss2'),
          value: this.formNewStockItem.loss2
        },
        {
          key: this.$t('Recipe.popupAdd_UnitId'),
          value: this.formNewStockItem.unitId ? this.findUnit(this.formNewStockItem.unitId).name : null
        },
        {
          key: this.$t('Recipe.popup_item_Loss1'),
          value: this.formNewStockItem.loss1
        }
      ]
    },

    directSelectOptionStockItem () {
      return this.formNewStockItemSelectOptions.length === 1 &&
        this.formNewStockItemSelectOptions[0].matchType === 2
        ? this.formNewStockItemSelectOptions[0]
        : {}
    },

    directSelectOptionAlternativeStockItem () {
      return this.formNewAlternativeStockItemSelectOptions.length === 1 &&
        this.formNewAlternativeStockItemSelectOptions[0].matchType === 2
        ? this.formNewAlternativeStockItemSelectOptions[0]
        : {}
    }
  },

  methods: {
    ...mapActions('Recipes', [
      'create_RECIPE_DETAIL_STOCK',
      'create_RECIPE_DETAIL_RECIPE',
      'searchRecipeItemByName',
      'get_RECIPE_HEADER',
      'getDetail',
      'update_RECIPE_DETAIL'
    ]),

    setRecipe (option) {
      this.get_RECIPE_HEADER(option.id)
        .then(res => {
          this.formNewRecipe.recipe = res.data.recipeHeader
          // this.setUnit(this.formNewRecipe.recipe.unitId)
        })
    },

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Stock', [
      'searchStockItem',
      'getStockItem'
    ]),

    calculateLoss (loss) {
      return 1 - (loss / 100)
    },

    async init () {
      if (this.isEdit) {
        // get recipe detail
        const result = await this.getDetail(this.$route.params.detailId)
        const detail = result.data.recipeDetail

        // fill form for stocks
        if (detail.stockItemId) {
          this.formNewStockItem = {
            stockItem: {
              name: detail.stockItemName,
              id: detail.stockItemId,
              baseUnitId: this.findUnit(detail.unitId).baseUnitId
            },
            loss1: detail.loss1,
            loss2: detail.loss2,
            netQuantity: detail.netQuantity,
            unitId: detail.unitId
          }

          if (detail.alternativeStockItemId) {
            this.formNewAlternativeStockItem = {
              stockItem: {
                name: detail.alternativeStockItemName,
                id: detail.alternativeStockItemId,
                baseUnitId: this.findUnit(detail.alternativeUnitId).baseUnitId
              },
              loss1: detail.alternativeLoss1,
              loss2: detail.alternativeLoss2,
              netQuantity: detail.alternativeNetQuantity,
              unitId: detail.alternativeUnitId
            }
          }
        } else {
          // Fill form for recipe
          this.setTab(1)
          const resultRecipe = await this.get_RECIPE_HEADER(detail.recipeId)
          const recipeHeader = resultRecipe.data.recipeHeader

          this.formNewRecipe = {
            ...detail,
            recipe: recipeHeader
          }
        }
      }
    },

    changeShowAlternative () {
      this.$validator.validateAll().then(result => {
        if (!result || !this.validStock) return
        this.isOpenedAlternative = true
        this.showAlternative = !this.showAlternative
        if (this.showAlternative && !this.formNewAlternativeStockItem.stockItem) this.focusFirstInput()
      })
    },

    calculateGrossQuantity (form) {
      form.loss2 = !form.loss1 ? null : form.loss2
    },

    tabChange (index) {
      this.setTab(index)
      this.resetForm()
      this.focusFirstInput()
      this.$nextTick(() => this.$validator.reset())
    },

    setTab (index) {
      this.activeTabIndex = index
    },
    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    focusFirstInput () {
      if (this.isEdit) return
      let firstInput
      if (this.isStockTab) firstInput = 'stockItem'
      else if (this.showAlternative) firstInput = 'alternativeStockItem'
      else firstInput = 'recipe'
      this.$nextTick(() => {
        this.$refs[firstInput].$refs.search.focus()
      })
    },

    submitNewStockItem: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async result => {
        if (!result || !this.validStock) return
        let recipeDetail = {
          id: this.isEdit ? this.$route.params.detailId : null,
          recipeHeaderId: this.$route.params.recipeHeaderId,
          stockItemId: this.formNewStockItem.stockItem.id,
          netQuantity: this.formNewStockItem.netQuantity,
          unitId: this.formNewStockItem.unitId,
          loss1: this.formNewStockItem.loss1 || null,
          loss2: this.formNewStockItem.loss2 || null,
          alternativeStockItemId: this.formNewAlternativeStockItem.stockItem ? this.formNewAlternativeStockItem.stockItem.id : null,
          alternativeNetQuantity: this.formNewAlternativeStockItem.netQuantity,
          alternativeUnitId: this.formNewAlternativeStockItem.unitId,
          alternativeLoss1: this.formNewAlternativeStockItem.loss1 || null,
          alternativeLoss2: this.formNewAlternativeStockItem.loss2 || null
        }
        if (this.isEdit) {
          await this.update_RECIPE_DETAIL({detail: recipeDetail, type: 1})
            .then(res => {
              if (res.status === 200) {
                const message = this.$t('Global.notification_Updated', {
                  name: this.item.name
                })
                this.notifyShow({ message })
                this.$emit('getList')
                this.closePopup()
              }
            })
        } else {
          await this.create_RECIPE_DETAIL_STOCK(recipeDetail).then(res => {
            if (res) {
              const message = this.$t('Global.notification_Created', { name: this.formNewStockItem.stockItem.name })
              this.notifyShow({ message })
              this.$emit('getList')
              if (this.saveAndNew) {
                this.formNewStockItemSelectOptions = []
                this.formNewStockItem = {
                  stockItem: null,
                  netQuantity: null,
                  unitId: null
                }
                this.formNewAlternativeStockItemSelectOptions = []
                this.formNewAlternativeStockItem = {
                  stockItem: null,
                  netQuantity: null,
                  unitId: null
                }
                this.showAlternative = false
                this.$nextTick(() => this.$validator.reset())
                this.focusFirstInput()
              } else {
                this.closePopup()
              }
            }
          })
        }
      })
      this.$emit('getList')
    }, 'submitRecipeDetailForm'),

    submitNewRecipe: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async result => {
        if (!result) return
        let recipeDetail = {
          recipeHeaderId: this.$route.params.recipeHeaderId,
          recipeId: this.formNewRecipe.recipe.id,
          netQuantity: this.formNewRecipe.netQuantity,
          unitId: this.findUnit(this.formNewRecipe.recipe.unitId).id,
          loss1: this.formNewRecipe.loss1 || null,
          loss2: this.formNewRecipe.loss2 || null,
          id: this.formNewRecipe.id
        }
        if (this.isEdit) {
          await this.update_RECIPE_DETAIL({detail: recipeDetail, type: 2})
            .then(res => {
              if (res.status === 200) {
                const message = this.$t('Global.notification_Updated', {
                  name: this.item.name
                })
                this.notifyShow({ message })
                this.$emit('getList')
                this.closePopup()
              }
            })
        } else {
          await this.create_RECIPE_DETAIL_RECIPE(recipeDetail).then(res => {
            if (res) {
              const message = this.$t('Global.notification_Created', {
                name: this.$t('Recipe.popupAdd_Name_Recipe')
              })
              this.notifyShow({ message })
              this.$emit('getList')
              if (this.saveAndNew) {
                this.formNewRecipeSelectOptions = []
                this.formNewRecipe = {
                  recipe: null,
                  netQuantity: null,
                  unitId: null
                }
                this.$nextTick(() => this.$validator.reset())
                this.focusFirstInput()
              } else {
                this.closePopup()
              }
            }
          })
        }
      })
    }, 'submitRecipeDetailForm'),

    getStockItemOptions (search) {
      this.searchStockItem({text: search, types: [1, 2, 3], onlyBaseUnit: true})
        .then(res => {
          this.formNewStockItemSelectOptions = res.data.items
        })
    },

    getAlternativeStockItemOptions (search) {
      this.searchStockItem({text: search, types: [1, 2, 3], onlyBaseUnit: true})
        .then(res => {
          this.formNewAlternativeStockItemSelectOptions = res.data.items
        })
    },

    getRecipeOptions (search) {
      this.searchRecipeItemByName({name: search, type: 1})
        .then(res => {
          this.formNewRecipeSelectOptions = res.data.recipeHeaderList
        })
    },

    resetForm () {
      this.formNewStockItemSelectOptions = []
      this.formNewAlternativeStockItemSelectOptions = []
      this.formNewStockItem = {
        stockItem: null,
        unitId: null,
        netQuantity: null,
        loss1: null,
        loss2: null
      }
      this.formNewAlternativeStockItem = {
        stockItem: null,
        unitId: null,
        netQuantity: null,
        loss1: null,
        loss2: null
      }

      this.formNewRecipeSelectOptions = []
      this.formNewRecipe = {
        recipe: null,
        netQuantity: null,
        unitId: null,
        loss1: null,
        loss2: null
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .PopupHeader {
    padding-bottom: 0;
    margin-top: 27px;
    margin-bottom: -28px;

    .tab {
      display: flex;

      button {
        margin-right: 20px;
        font-size: 1.6rem;
        padding-bottom: 6px;
        border-bottom: 4px solid transparent;
        font-weight: $font-weight-normal;

        &.isSelected {
          pointer-events: none;
          color: $color-primary;
          border-bottom-color: $color-primary;
        }
      }
    }
  }

  .row-info {
    margin-left: 10px;
    margin-top: 10px;
    font-size: $font-size-small;

    &-text {
      color: $color-light;

      &::after {
        content: ':'
      }
    }

    &-value {
      margin-left: 5px;
      font-weight: $font-weight-bold;
      color: $color-dark;
    }
  }

  .double-input {
    display: flex;
    .Form-item {
      margin-top: 0;
      width: 100%;
    }
    .Form-item:first-child {
      margin-right: 10px;
    }
  }
</style>
