var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c(
        "template",
        { slot: "title" },
        [
          _vm.isEdit
            ? [_vm._v(_vm._s(_vm.$t("RecipeGroups.popupForm_Title_Edit")))]
            : [_vm._v(_vm._s(_vm.$t("RecipeGroups.popupForm_Title_Create")))],
        ],
        2
      ),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "form-recipe-group" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmitRecipeGroupForm.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "Form-item" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("RecipeGroups.popupForm_Field_Name"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.item.name,
                        expression: "item.name",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:128|verify_name",
                        expression: "'required|max:128|verify_name'",
                      },
                    ],
                    ref: "firstField",
                    staticClass: "txt",
                    class: { "is-danger": _vm.veeErrors.has("name") },
                    attrs: {
                      autocomplete: "off",
                      name: "name",
                      "data-vv-as": _vm.$t("RecipeGroups.popupForm_Field_Name"),
                      type: "text",
                      "data-vv-delay": _vm.$getConst("INPUT_DELAY_TIME"),
                      id: "input-recipes-popup-field-name",
                    },
                    domProps: { value: _vm.item.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.item, "name", $event.target.value)
                      },
                    },
                  }),
                  _c(
                    "Button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isPendingName,
                          expression: "isPendingName",
                        },
                      ],
                      attrs: {
                        id: "btn-recipes-popup-loading-name",
                        variant: "icon loading",
                        type: "button",
                        tabindex: "-1",
                      },
                    },
                    [_c("Loading", { attrs: { theme: "disable" } })],
                    1
                  ),
                  _c("Button", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isValidateName,
                        expression: "isValidateName",
                      },
                    ],
                    attrs: {
                      variant: "icon check",
                      id: "btn-recipes-popup-ok-name",
                      type: "button",
                      tabindex: "-1",
                      iconName: "icon-check",
                      iconClass: "icon-check",
                    },
                  }),
                ],
                1
              ),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.veeErrors.has("name"),
                      expression: "veeErrors.has('name')",
                    },
                  ],
                  staticClass: "Form-item-help is-danger",
                },
                [_vm._v(_vm._s(_vm.veeErrors.first("name")))]
              ),
            ]),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _vm.isEdit
            ? [
                _c(
                  "Button",
                  {
                    attrs: {
                      primary: "",
                      size: "large",
                      variant: "full",
                      id: "btn-recipes-popup-submit-edit",
                      type: "submit",
                      form: "form-recipe-group",
                      disabled: _vm.isLoading,
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isLoading,
                            expression: "!isLoading",
                          },
                        ],
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("RecipeGroups.popupForm_Button_Edit"))
                        ),
                      ]
                    ),
                    _c("Loading", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isLoading,
                          expression: "isLoading",
                        },
                      ],
                      attrs: { theme: "disable" },
                    }),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "div",
                  { staticClass: "add-other-items-checkbox-container" },
                  [
                    _c("CustomCheckbox", {
                      attrs: {
                        id: "checkbox-recipes-group-save-and-new",
                        label: _vm.$t("Global.formSaveAndNewText", {
                          form: _vm.$t("RecipeGroups.popupForm_Name"),
                        }),
                      },
                      model: {
                        value: _vm.saveAndNew,
                        callback: function ($$v) {
                          _vm.saveAndNew = $$v
                        },
                        expression: "saveAndNew",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "Button",
                  {
                    attrs: {
                      primary: "",
                      size: "large",
                      variant: "full",
                      id: "btn-recipes-popup-submit-new",
                      type: "submit",
                      form: "form-recipe-group",
                      disabled: _vm.isLoading,
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isLoading,
                            expression: "!isLoading",
                          },
                        ],
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("RecipeGroups.popupForm_Button_Create"))
                        ),
                      ]
                    ),
                    _c("Loading", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isLoading,
                          expression: "isLoading",
                        },
                      ],
                      attrs: { theme: "disable" },
                    }),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }