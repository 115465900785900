<template lang="pug">
Popup

    template(slot="title")
      template(v-if="isEdit") {{ $t('RecipeGroups.popupForm_Title_Edit') }}
      template(v-else) {{ $t('RecipeGroups.popupForm_Title_Create') }}

    template(slot="content")
      form.Form(
      id="form-recipe-group"
      @submit.prevent="onSubmitRecipeGroupForm")

        div.Form-item
          label.Form-item-label {{ $t('RecipeGroups.popupForm_Field_Name') }}
          .control
            input.txt(
            ref="firstField",
            autocomplete="off",
            name="name",
            :data-vv-as="$t('RecipeGroups.popupForm_Field_Name')",
            v-model="item.name",
            v-validate="'required|max:128|verify_name'",
            :class="{ 'is-danger': veeErrors.has('name') }",
            type="text"
            :data-vv-delay="$getConst('INPUT_DELAY_TIME')"
            id="input-recipes-popup-field-name")

            Button(
            id="btn-recipes-popup-loading-name"
            variant="icon loading"
            type="button",
            tabindex="-1",
            v-show="isPendingName")
              Loading(theme="disable")
            Button(
            variant="icon check"
            id="btn-recipes-popup-ok-name"
            type="button",
            tabindex="-1",
            iconName="icon-check"
            iconClass="icon-check"
            v-show="isValidateName")
            
          p.Form-item-help.is-danger(
          v-show="veeErrors.has('name')"
          ) {{ veeErrors.first('name') }}

    template(slot="footer")
      template(v-if="isEdit")
          Button(
          primary
          size="large"
          variant="full"
          id="btn-recipes-popup-submit-edit"
          type="submit"
          form="form-recipe-group"
          :disabled="isLoading")
            span(v-show="!isLoading") {{ $t('RecipeGroups.popupForm_Button_Edit') }}
            Loading(theme="disable", v-show="isLoading")
      template(v-else)
        div.add-other-items-checkbox-container
          CustomCheckbox(
          id="checkbox-recipes-group-save-and-new"
          :label="$t('Global.formSaveAndNewText', { form: $t('RecipeGroups.popupForm_Name') })"
          v-model="saveAndNew")

        Button(
          primary
          size="large"
          variant="full"
        id="btn-recipes-popup-submit-new"
        type="submit"
        form="form-recipe-group"
        :disabled="isLoading")
          span(v-show="!isLoading") {{ $t('RecipeGroups.popupForm_Button_Create') }}
          Loading(theme="disable", v-show="isLoading")

</template>

<script>
import { omit, vueWaitLoader } from '@/utils/baseOperations'
import { mapFields, Validator } from 'vee-validate'
import { mapActions } from 'vuex'

export default {
  name: 'RecipeGroupForm',

  props: {
    status: {
      type: String,
      default: 'new'
    }
  },

  data () {
    return {
      item: {
        id: null,
        name: null
      },
      saveAndNew: true
    }
  },

  created () {
    const self = this

    const verifyName = {
      getMessage (field, params, data) {
        return (data && data.message) || 'Something wrong'
      },
      validate (value) {
        return new Promise(resolve => {
          let params = {
            Name: value
          }
          if (self.isEdit) {
            params.Id = self.item.id
          }
          self.CheckRecipeGroupName(params)
            .then(res => {
              if (res) {
                resolve({
                  valid: res.data.isAvailable
                })
              } else {
                resolve({
                  valid: false
                })
              }
            })
        })
      }
    }

    const verifyNameMessages = {
      tr: {
        messages: {
          verify_name: field => 'Girdiğiniz reçete grubu adı sistemde mevcut, Lütfen farklı bir reçete grubu adı giriniz.'
        }
      }
    }

    Validator.extend('verify_name', verifyName)
    Validator.localize(verifyNameMessages)
  },

  async mounted () {
    if (this.isEdit) this.init()
    this.formFocus()
  },

  computed: {
    ...mapFields({
      flagsName: 'name'
    }),

    isLoading () {
      return this.$wait.is(['create_RECIPEGROUP_ITEM', 'update_RECIPEGROUP_ITEM', 'onSubmitRecipeGroupForm'])
    },

    isEdit () {
      return this.status === 'edit'
    },

    isPendingName () {
      return this.flagsName.pending
    },

    isValidateName () {
      if (this.flagsName.required) {
        return this.flagsName.valid && !this.isPendingName
      } else {
        return this.flagsName.valid && this.item.name !== '' && this.item.name !== null && !this.isPendingName
      }
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('RecipeGroups', [
      'get_RECIPEGROUP_LIST',
      'get_RECIPEGROUP_ITEM',
      'create_RECIPEGROUP_ITEM',
      'update_RECIPEGROUP_ITEM',
      'delete_RECIPEGROUP_ITEM',
      'CheckRecipeGroupName'
    ]),

    async init () {
      let item = this.$route.params.item
      if (!item) {
        let itemId = this.$route.params.id
        await this.get_RECIPEGROUP_ITEM(itemId).then(res => {
          if (res.status === 200) {
            item = res.data.recipeGroup
          }
        })
      }
      this.item.id = item.id
      this.item.name = item.name
    },

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    formFocus () {
      this.$refs.firstField.focus()
    },

    onSubmitRecipeGroupForm: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async result => {
        // form error
        if (!result) return

        if (this.isEdit) await this.submitEdit()
        else await this.submitNew()
      })
    }, 'onSubmitRecipeGroupForm'),

    async submitNew () {
      let item = omit(this.item, ['id'])
      await this.create_RECIPEGROUP_ITEM(item).then(res => {
        if (res) {
          const message = this.$t('Global.notification_Created', { name: item.name })
          this.notifyShow({ message })
          this.get_RECIPEGROUP_LIST()
          if (this.saveAndNew) {
            this.item = {
              id: null,
              name: null
            }
            this.formFocus()
            this.$nextTick(() => {
              this.$validator.reset()
            })
          } else {
            this.close()
          }
        }
      })
    },

    async submitEdit () {
      await this.update_RECIPEGROUP_ITEM(this.item).then(res => {
        if (res.status === 200) {
          const message = this.$t('Global.notification_Updated', { name: this.item.name })
          this.notifyShow({ message })
          this.get_RECIPEGROUP_LIST()
          this.close()
        }
      })
    },

    keyAction (event) {
      if (this.isEdit) {
        this.submitEdit()
        return
      }
      switch (event.srcKey) {
        case 'submit':
          this.submitNew()
          break
        case 'submitAndNew':
          this.submitNew()
          break
      }
    }
  }
}
</script>
