export default function($t) {
  return [
    {
      name: 'totalDetailType',
      type: 'portal',
      headerPortal: 'totalHeaderType',
      is_visible: true,
      get short_title() {
        return $t('Recipe.tableColumn_Ingredients_Type')
      },
      get title() {
        return $t('Recipe.tableColumn_Ingredients_Type')
      }
    },
    {
      name: 'calorie',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Recipes.calorie')
      },
      get title() {
        return $t('Recipes.calorie')
      }
    },
    {
      name: 'stockItemName',
      is_visible: true,
      get short_title() {
        return $t('Recipe.tableColumn_Ingredients_StockItemName')
      },
      get title() {
        return $t('Recipe.tableColumn_Ingredients_StockItemName')
      }
    },
    {
      name: 'quantity',
      type: 'mono',
      is_visible: true,

      get short_title() {
        return $t('Recipe.tableColumn_Ingredient_Quantity')
      },
      get title() {
        return $t('Recipe.tableColumn_Ingredient_Quantity')
      }
    },
    {
      name: 'unitName',
      is_visible: true,
      get short_title() {
        return $t('Recipe.tableColumn_Ingredient_Unit')
      },
      get title() {
        return $t('Recipe.tableColumn_Ingredient_Unit')
      }
    },
    {
      name: 'baseQuantity',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Recipe.tableColumn_Ingredient_BaseQuantity')
      },
      get title() {
        return $t('Recipe.tableColumn_Ingredient_BaseQuantity')
      }
    },
    {
      name: 'baseUnitName',
      is_visible: true,
      get short_title() {
        return $t('Recipe.tableColumn_Ingredient_BaseUnit')
      },
      get title() {
        return $t('Recipe.tableColumn_Ingredient_BaseUnit')
      }
    },
    {
      name: 'totalKgQuantity',
      is_visible: true,
      get short_title() {
        return $t('Recipe.tableColumn_Ingredient_totalKgQuantity')
      },
      get title() {
        return $t('Recipe.tableColumn_Ingredient_totalKgQuantity')
      }
    },
    {
      name: 'price',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('Recipe.tableColumn_Ingredient_Price')
      },
      get title() {
        return $t('Recipe.tableColumn_Ingredient_Price')
      }
    },
    {
      name: 'totalCost',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('Recipe.tableColumn_Ingredient_TotalCost')
      },
      get title() {
        return $t('Recipe.tableColumn_Ingredient_TotalCost')
      }
    },
    {
      name: 'stockOnHandQuantity',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Recipe.tableColumn_Ingredient_StockOnHandQuantity')
      },
      get title() {
        return $t('Recipe.tableColumn_Ingredient_StockOnHandQuantity')
      }
    },
    {
      name: 'baseUnitName',
      is_visible: true,
      get short_title() {
        return $t('Recipe.tableColumn_Ingredient_BaseUnit')
      },
      get title() {
        return $t('Recipe.tableColumn_Ingredient_BaseUnit')
      }
    },
    {
      name: 'itemGroupName',
      is_visible: true,
      get short_title() {
        return $t('Recipe.tableColumn_Ingredient_ItemGroupName')
      },
      get title() {
        return $t('Recipe.tableColumn_Ingredient_ItemGroupName')
      }
    }
  ]
}
