import { render, staticRenderFns } from "./copy-recipe.vue?vue&type=template&id=da33bb42&scoped=true&lang=pug&"
import script from "./copy-recipe.vue?vue&type=script&lang=js&"
export * from "./copy-recipe.vue?vue&type=script&lang=js&"
import style0 from "./copy-recipe.vue?vue&type=style&index=0&id=da33bb42&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da33bb42",
  null
  
)

export default component.exports