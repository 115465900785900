var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c(
        "template",
        { slot: "title" },
        [
          _vm.isEdit
            ? [_vm._v(_vm._s(_vm.$t("Recipes.form_EditTitle")))]
            : [_vm._v(_vm._s(_vm.$t("Recipes.form_CreateTitle")))],
        ],
        2
      ),
      _c("template", { slot: "content" }, [
        !_vm.isEdit
          ? _c(
              "div",
              { staticClass: "selectType" },
              [
                _c("SelectedButtons", {
                  attrs: { data: _vm.typeData },
                  model: {
                    value: _vm.formType,
                    callback: function ($$v) {
                      _vm.formType = $$v
                    },
                    expression: "formType",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "form-recipe" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmitRecipeForm.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Recipes.form_Field_Name"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.name,
                        expression: "form.name",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:128|verify_name",
                        expression: "'required|max:128|verify_name'",
                      },
                    ],
                    ref: "firstField",
                    staticClass: "txt",
                    class: { "is-danger": _vm.veeErrors.has("name") },
                    attrs: {
                      autocomplete: "off",
                      name: "name",
                      "data-vv-as": _vm.$t("Recipes.form_Field_Name"),
                      type: "text",
                      "data-vv-delay": _vm.$getConst("INPUT_DELAY_TIME"),
                      id: "input-recipes-form-field-name",
                    },
                    domProps: { value: _vm.form.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "name", $event.target.value)
                      },
                    },
                  }),
                  _c(
                    "Button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isPendingName,
                          expression: "isPendingName",
                        },
                      ],
                      attrs: {
                        variant: "icon loading",
                        id: "btn-recipes-popup-loading-name",
                        type: "button",
                        tabindex: "-1",
                      },
                    },
                    [_c("Loading", { attrs: { theme: "disable" } })],
                    1
                  ),
                  _c("Button", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isValidateName,
                        expression: "isValidateName",
                      },
                    ],
                    attrs: {
                      variant: "icon check",
                      id: "btn-popup-recipes-ok-name",
                      type: "button",
                      tabindex: "-1",
                      iconName: "icon-check",
                      iconClass: "icon-check",
                    },
                  }),
                ],
                1
              ),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.veeErrors.has("name"),
                      expression: "veeErrors.has('name')",
                    },
                  ],
                  staticClass: "Form-item-help is-danger",
                },
                [_vm._v(_vm._s(_vm.veeErrors.first("name")))]
              ),
            ]),
            _vm.isProductionType
              ? _c("div", { staticClass: "Form-item required" }, [
                  _c("label", { staticClass: "Form-item-label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("Recipes.detail_Popup_FormField_StockItemName")
                      )
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "control" },
                    [
                      _c("custom-search", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        ref: "firstField",
                        attrs: {
                          name: "stockItem",
                          "component-name": "form-recipe-stock-item",
                          "data-vv-as": _vm.$t(
                            "Recipes.detail_Popup_FormField_StockItemName"
                          ),
                          inputClass: {
                            "is-danger":
                              _vm.veeErrors.has("stockItem") ||
                              _vm.usedByProductionRecipeBefore,
                          },
                          "on-search": _vm.getOptions,
                          options: _vm.selectOptions,
                          label: "name",
                          placeholder: _vm.$t(
                            "Recipes.detail_Popup_FormField_StockItemName_Placeholder"
                          ),
                        },
                        model: {
                          value: _vm.form.stockItem,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "stockItem", $$v)
                          },
                          expression: "form.stockItem",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has("stockItem"),
                          expression: "veeErrors.has('stockItem')",
                        },
                      ],
                      staticClass: "Form-item-help is-danger",
                    },
                    [_vm._v(_vm._s(_vm.veeErrors.first("stockItem")))]
                  ),
                  _vm.usedByProductionRecipeBefore
                    ? _c("p", { staticClass: "Form-item-help is-danger" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "Recipes.popup_Form_Error_usedByProductionRecipeBefore",
                              {
                                name: _vm.form.stockItem
                                  .usedByProductionRecipeName,
                              }
                            )
                          )
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _c("div", { staticClass: "Form-item" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Recipes.form_Field_RecipeCode"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.recipeCode,
                        expression: "form.recipeCode",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "alpha_dash|max:128|verify_code",
                        expression: "'alpha_dash|max:128|verify_code'",
                      },
                    ],
                    staticClass: "txt",
                    class: { "is-danger": _vm.veeErrors.has("recipeCode") },
                    attrs: {
                      name: "recipeCode",
                      autocomplete: "off",
                      "data-vv-as": _vm.$t("Recipes.form_Field_RecipeCode"),
                      type: "text",
                      "data-vv-delay": _vm.$getConst("INPUT_DELAY_TIME"),
                      id: "input-recipes-form-recipe-code",
                    },
                    domProps: { value: _vm.form.recipeCode },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "recipeCode", $event.target.value)
                      },
                    },
                  }),
                  _c(
                    "Button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isPendingCode,
                          expression: "isPendingCode",
                        },
                      ],
                      attrs: {
                        variant: "icon loading",
                        id: "btn-recipes-popup-loading-code",
                        type: "button",
                        tabindex: "-1",
                      },
                    },
                    [_c("Loading", { attrs: { theme: "disable" } })],
                    1
                  ),
                  _c("Button", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isValidateCode,
                        expression: "isValidateCode",
                      },
                    ],
                    attrs: {
                      variant: "icon check",
                      id: "btn-recipes-popup-ok-code",
                      type: "button",
                      tabindex: "-1",
                      iconName: "icon-check",
                      iconClass: "icon-check",
                    },
                  }),
                ],
                1
              ),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.veeErrors.has("recipeCode"),
                      expression: "veeErrors.has('recipeCode')",
                    },
                  ],
                  staticClass: "Form-item-help is-danger",
                },
                [_vm._v(_vm._s(_vm.veeErrors.first("recipeCode")))]
              ),
            ]),
            _c(
              "div",
              { staticClass: "Form-item m-top-20" },
              [
                _c("label", { staticClass: "Form-item-label" }, [
                  _vm._v(_vm._s(_vm.$t("PriceQuota.popupForm_Field_barcode"))),
                ]),
                _c(
                  "div",
                  { staticClass: "control barcode-input" },
                  [
                    _c("customTextInput", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: `numeric|verify_barcode|verify_weighted_barcode:${_vm.form.totalUnitId}`,
                          expression:
                            "`numeric|verify_barcode|verify_weighted_barcode:${form.totalUnitId}`",
                        },
                      ],
                      class: { "is-danger": _vm.veeErrors.has(`barcode`) },
                      attrs: {
                        id: "input-price-quote-popup-field-barcode-recipe-code",
                        name: "barcode",
                        "data-vv-as": _vm.$t(
                          "PriceQuota.popupForm_Field_barcode"
                        ),
                        placeholder: _vm.$t(
                          "PriceQuota.popupForm_Field_barcode_Placeholder"
                        ),
                        error: _vm.veeErrors.has("barcode"),
                        disabled: _vm.disableBarcodeInput,
                        autocomplete: "off",
                      },
                      on: {
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          $event.preventDefault()
                          return _vm.addBarcode.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.barcode.barcode,
                        callback: function ($$v) {
                          _vm.$set(_vm.barcode, "barcode", $$v)
                        },
                        expression: "barcode.barcode",
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "barcode-actions" },
                      [
                        _vm.isLoadingCheckBarcode
                          ? _c("Loading", { attrs: { theme: "disable" } })
                          : _c(
                              "Button",
                              {
                                attrs: {
                                  justIcon: true,
                                  size: "small",
                                  clearVariant: true,
                                },
                                on: { click: _vm.addBarcode },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "icon",
                                    class: [
                                      _vm.barcode &&
                                      !_vm.disableBarcodeInput &&
                                      !_vm.veeErrors.has("barcode")
                                        ? "c-success"
                                        : "c-gray",
                                    ],
                                  },
                                  [
                                    _c("use", {
                                      attrs: {
                                        "xlink:href": "#icon-global-add",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.veeErrors.has("barcode")
                  ? _c("showValidateError", {
                      attrs: { errorName: _vm.veeErrors.first("barcode") },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "barcodes" },
                  _vm._l(_vm.form.barcodeList, function (barcode, index) {
                    return _c(
                      "span",
                      { staticClass: "barcodes-text" },
                      [
                        _vm._v(_vm._s(barcode.barcode)),
                        _c("Button", {
                          staticClass: "barcodes-text-remove",
                          attrs: {
                            type: "button",
                            variant: "icon",
                            iconName: "icon-barcode-close",
                            iconClass: "icon-barcode-close",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.removeBarcode(index)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ],
              1
            ),
            _c("div", { staticClass: "Form-item" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Recipes.form_Field_RecipeGroup"))),
              ]),
              _c(
                "div",
                { staticClass: "control form-item-select" },
                [
                  _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.recipeGroupId,
                          expression: "form.recipeGroupId",
                        },
                      ],
                      staticClass: "select",
                      class: {
                        "is-danger": _vm.veeErrors.has("recipeGroupId"),
                      },
                      attrs: {
                        id: "select-recipes-recipe-group",
                        name: "recipeGroupId",
                        "data-vv-as": _vm.$t("Recipes.form_Field_RecipeGroup"),
                      },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.form,
                            "recipeGroupId",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c(
                        "option",
                        {
                          attrs: {
                            value: "-1",
                            id: "option-recipes-recipe-unselected",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("Recipes.form_Unselected")))]
                      ),
                      _vm._l(_vm.recipeGroup_List, function (group) {
                        return _c(
                          "option",
                          {
                            key: group.id,
                            attrs: {
                              id: `option-recipes-recipe-group-${group.name}`,
                            },
                            domProps: { value: group.id },
                          },
                          [_vm._v(_vm._s(group.name))]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.veeErrors.has("recipeGroupId"),
                      expression: "veeErrors.has('recipeGroupId')",
                    },
                  ],
                  staticClass: "Form-item-help is-danger",
                },
                [_vm._v(_vm._s(_vm.veeErrors.first("recipeGroupId")))]
              ),
            ]),
            _vm.isProductionType && _vm.form.stockItem
              ? _c(
                  "div",
                  {
                    staticClass: "Form-item required twice-line",
                    attrs: { id: "prod-quantity-unit" },
                  },
                  [
                    _c("div", { staticClass: "twice-item" }, [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(_vm.$t("Recipes.form_label_Field_prodUnitId"))
                        ),
                      ]),
                      _c("div", { staticClass: "control" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.form.prodQuantity,
                              expression: "form.prodQuantity",
                              modifiers: { number: true },
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: {
                                required: true,
                                greater_than: -1,
                                decimal: _vm.$getConst("DECIMAL_FOR_QUANTITY"),
                                max: _vm.$getConst("MAX_DIGITS_FOR_QUANTITY"),
                              },
                              expression:
                                "{required: true, greater_than:-1, decimal: $getConst('DECIMAL_FOR_QUANTITY'), max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                            },
                          ],
                          staticClass: "txt no-spinners",
                          class: {
                            "is-danger": _vm.veeErrors.has("prodQuantity"),
                          },
                          attrs: {
                            name: "prodQuantity",
                            id: "input-recipes-form-field-prodQuantity",
                            autocomplete: "off",
                            "data-vv-as": _vm.$t(
                              "Recipes.form_label_Field_prodUnitId"
                            ),
                            type: "number",
                            step: "any",
                          },
                          domProps: { value: _vm.form.prodQuantity },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form,
                                "prodQuantity",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "twice-item" }, [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(_vm.$t("Recipes.popup_FormField_ProdUnit"))
                        ),
                      ]),
                      _c("div", { staticClass: "control" }, [
                        _c("input", {
                          staticClass: "txt",
                          attrs: {
                            disabled: "",
                            id: "input-recipes-form-field-prodUnit",
                          },
                          domProps: { value: _vm.form.stockItem.unitName },
                        }),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
            !_vm.isProductionType
              ? _c("div", { staticClass: "Form-item" }, [
                  _c("label", { staticClass: "Form-item-label" }, [
                    _vm._v(_vm._s(_vm.$t("Recipes.form_Field_TaxRatio"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "control form-item-select" },
                    [
                      _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.taxId,
                              expression: "form.taxId",
                            },
                          ],
                          staticClass: "select",
                          class: { "is-danger": _vm.veeErrors.has("taxId") },
                          attrs: {
                            id: "select-recipes-tax-ratio",
                            name: "taxId",
                            "data-vv-as": _vm.$t("Recipes.form_Field_TaxRatio"),
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.form,
                                "taxId",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: {
                                value: "-1",
                                id: "option-recipes-tax-ratio-unselected",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("Recipes.form_Unselected")))]
                          ),
                          _vm._l(_vm.Taxes, function (tax) {
                            return _c(
                              "option",
                              {
                                key: tax.id,
                                attrs: { id: `option-recipes-${tax.id}` },
                                domProps: { value: tax.id },
                              },
                              [_vm._v(_vm._s(tax.ratio))]
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has("taxId"),
                          expression: "veeErrors.has('taxId')",
                        },
                      ],
                      staticClass: "Form-item-help is-danger",
                    },
                    [_vm._v(_vm._s(_vm.veeErrors.first("taxId")))]
                  ),
                ])
              : _vm._e(),
            !_vm.isProductionType
              ? _c("div", { staticClass: "Form-item" }, [
                  _c("label", { staticClass: "Form-item-label" }, [
                    _vm._v(
                      _vm._s(_vm.$t("Recipes.form_Field_GrossSalesPrice"))
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "control" },
                    [
                      _c("currency-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              required: true,
                              min_value: 0,
                              max: _vm.$getConst("MAX_DIGITS_FOR_QUANTITY"),
                            },
                            expression:
                              "{required: true, min_value: 0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                          },
                        ],
                        staticClass: "txt",
                        class: { "is-danger": _vm.veeErrors.has("amount") },
                        attrs: {
                          name: "grossSalesPrice",
                          "data-vv-as": _vm.$t(
                            "Recipes.form_Field_GrossSalesPrice"
                          ),
                          disabled: !_vm.isPriceAvailable,
                        },
                        model: {
                          value: _vm.form.grossSalesPrice,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "grossSalesPrice", $$v)
                          },
                          expression: "form.grossSalesPrice",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            !_vm.isProductionType
              ? _c("div", { staticClass: "Form-item" }, [
                  _c("label", { staticClass: "Form-item-label" }, [
                    _vm._v(_vm._s(_vm.$t("Recipes.form_Field_NetSalesPrice"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "control" },
                    [
                      _c("currency-input", {
                        staticClass: "txt is-disabled",
                        attrs: {
                          name: "netSalesPrice",
                          disabled: "",
                          type: "text",
                          id: "input-recipes-form-net-sales-price",
                        },
                        model: {
                          value: _vm.form.netSalesPrice,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "netSalesPrice", $$v)
                          },
                          expression: "form.netSalesPrice",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            !_vm.isProductionType
              ? _c("div", { staticClass: "Form-item twice-line required" }, [
                  _c("div", { staticClass: "twice-item" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(_vm.$t("Recipes.form_Field_BaseuUnitQuantity"))
                      ),
                    ]),
                    _c("div", { staticClass: "control" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.form.totalQuantity,
                            expression: "form.totalQuantity",
                            modifiers: { number: true },
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              required: true,
                              min_value: 0.001,
                              max: _vm.$getConst("MAX_DIGITS_FOR_QUANTITY"),
                            },
                            expression:
                              "{required: true, min_value: 0.001, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                          },
                        ],
                        staticClass: "txt",
                        class: {
                          "is-danger": _vm.veeErrors.has("totalQuantity"),
                        },
                        attrs: {
                          autocomplete: "off",
                          name: "totalQuantity",
                          "data-vv-as": _vm.$t(
                            "Recipes.form_Field_BaseuUnitQuantity"
                          ),
                          type: "number",
                          step: "any",
                          id: "input-recipes-form-field-totalQuantity",
                        },
                        domProps: { value: _vm.form.totalQuantity },
                        on: {
                          change: function ($event) {
                            return _vm.calculatePortions("totalQuantity")
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "totalQuantity",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("totalQuantity"),
                            expression: "veeErrors.has('totalQuantity')",
                          },
                        ],
                        staticClass: "Form-item-help is-danger",
                      },
                      [_vm._v(_vm._s(_vm.veeErrors.first("totalQuantity")))]
                    ),
                  ]),
                  _c("div", { staticClass: "twice-item" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(_vm.$t("Recipes.popup_FormField_BaseUnit"))
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control form-item-select" },
                      [
                        _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|is_not:-1",
                                expression: "'required|is_not:-1'",
                              },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.totalUnitId,
                                expression: "form.totalUnitId",
                              },
                            ],
                            staticClass: "select",
                            class: {
                              "is-danger": _vm.veeErrors.has("baseUnitId"),
                            },
                            attrs: {
                              id: "select-recipes-popup-baseunit",
                              name: "baseUnitId",
                              "data-vv-as": _vm.$t(
                                "Recipes.popup_FormField_BaseUnit"
                              ),
                            },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.form,
                                  "totalUnitId",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "option",
                              {
                                attrs: {
                                  value: "-1",
                                  id: "option-recipes-popup-unselected",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "Recipes.popup_FormField_BaseUnit_Unselected"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _vm._l(_vm.BaseUnitList, function (BaseUnit) {
                              return _c(
                                "option",
                                {
                                  key: BaseUnit.id,
                                  attrs: {
                                    id: `option-recipes-popup-${BaseUnit.name}`,
                                  },
                                  domProps: { value: BaseUnit.baseUnitId },
                                },
                                [_vm._v(_vm._s(BaseUnit.name))]
                              )
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("baseUnitId"),
                            expression: "veeErrors.has('baseUnitId')",
                          },
                        ],
                        staticClass: "Form-item-help is-danger",
                      },
                      [_vm._v(_vm._s(_vm.veeErrors.first("baseUnitId")))]
                    ),
                  ]),
                ])
              : _vm._e(),
            !_vm.isProductionType
              ? _c("div", { staticClass: "Form-item twice-line required" }, [
                  _c("div", { staticClass: "twice-item" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(_vm.$t("Recipes.form_label_Field_portionUnitId"))
                      ),
                    ]),
                    _c("div", { staticClass: "control" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.form.portionQuantity,
                            expression: "form.portionQuantity",
                            modifiers: { number: true },
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              required: true,
                              min_value: 0.001,
                              max: _vm.$getConst("MAX_DIGITS_FOR_QUANTITY"),
                            },
                            expression:
                              "{required: true, min_value: 0.001, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                          },
                        ],
                        staticClass: "txt",
                        class: {
                          "is-danger": _vm.veeErrors.has("portionQuantity"),
                        },
                        attrs: {
                          autocomplete: "off",
                          name: "portionQuantity",
                          disabled: _vm.isEmptyBaseUnit,
                          "data-vv-as": _vm.$t(
                            "Recipes.form_label_Field_portionUnitId"
                          ),
                          type: "number",
                          step: "any",
                          id: "input-recipes-form-field-portionQuantity",
                        },
                        domProps: { value: _vm.form.portionQuantity },
                        on: {
                          change: function ($event) {
                            return _vm.calculatePortions("portionQuantity")
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "portionQuantity",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("portionQuantity"),
                            expression: "veeErrors.has('portionQuantity')",
                          },
                        ],
                        staticClass: "Form-item-help is-danger",
                      },
                      [_vm._v(_vm._s(_vm.veeErrors.first("portionQuantity")))]
                    ),
                  ]),
                  _c("div", { staticClass: "twice-item" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(_vm.$t("Recipes.popup_FormField_BaseUnit"))
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control control form-item-select" },
                      [
                        _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|is_not:-1",
                                expression: "'required|is_not:-1'",
                              },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.portionUnitId,
                                expression: "form.portionUnitId",
                              },
                            ],
                            staticClass: "select",
                            class: { "is-danger": _vm.veeErrors.has("unitId") },
                            attrs: {
                              id: "select-recipes-popup-group-unit",
                              name: "unitId",
                              disabled: _vm.isEmptyBaseUnit,
                              "data-vv-as": _vm.$t(
                                "Recipes.popup_FormField_BaseUnit"
                              ),
                            },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.form,
                                  "portionUnitId",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "option",
                              {
                                attrs: {
                                  value: "-1",
                                  id: "option-recipes-portionid-unselected",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("Recipes.form_Unselected"))
                                ),
                              ]
                            ),
                            _vm._l(_vm.filteredUnitList, function (Unit) {
                              return _c(
                                "option",
                                {
                                  key: Unit.id,
                                  attrs: {
                                    id: `option-recipes-unit-${Unit.name}`,
                                  },
                                  domProps: { value: Unit.id },
                                },
                                [_vm._v(_vm._s(Unit.name))]
                              )
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("unitId"),
                            expression: "veeErrors.has('unitId')",
                          },
                        ],
                        staticClass: "Form-item-help is-danger",
                      },
                      [_vm._v(_vm._s(_vm.veeErrors.first("unitId")))]
                    ),
                  ]),
                ])
              : _vm._e(),
            !_vm.isProductionType
              ? _c("div", { staticClass: "Form-item required" }, [
                  _c("label", { staticClass: "Form-item-label" }, [
                    _vm._v(
                      _vm._s(_vm.$t("Recipes.form_Field_portionQuantity"))
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "control" },
                    [
                      _c("customNumberInput", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|min_value:0.001",
                            expression: "'required|min_value:0.001'",
                          },
                        ],
                        attrs: {
                          name: "calculatedPortion",
                          "data-vv-as": _vm.$t("Despatches.shelfLife"),
                          id: "input-batch-shelf-life",
                          disabled: _vm.isEmptyPortion || _vm.isEmptyBaseUnit,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.calculatePortions("calculatedPortion")
                          },
                        },
                        model: {
                          value: _vm.form.calculatedPortion,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "calculatedPortion", $$v)
                          },
                          expression: "form.calculatedPortion",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: {
                primary: "",
                size: "large",
                variant: "full",
                id: "btn-recipes-popup-submit",
                type: "submit",
                form: "form-recipe",
                disabled: _vm.isLoading,
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading",
                    },
                  ],
                },
                [
                  _vm.isEdit
                    ? [_vm._v(_vm._s(_vm.$t("Recipes.form_FinishEditButton")))]
                    : [
                        _vm._v(
                          _vm._s(_vm.$t("Recipes.form_FinishCreateButton"))
                        ),
                      ],
                ],
                2
              ),
              _c("Loading", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLoading,
                    expression: "isLoading",
                  },
                ],
                attrs: { theme: "disable" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }