<template lang="pug">

  .preview.relative
    .preview-item(v-for="item in data")
      .preview-key {{ item.key }}
      .preview-value {{ item.value || '-' }}
    .preview-actions(@click="onClickPreviewEdit")
      Icon.preview-edit(name="icon-global-edit")

</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    onClickPreviewEdit: {
      type: Function,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>

.preview {
  height: 105px;
  border-radius: $border-radius;
  background-color: $color-ocean;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  &-item {
    display: flex;
    height: 20px;
  }

  &-key {
    font-size: $font-size-small;
    color: $color-light;

    &::after {
      content: ':';
    }
  }

  &-value {
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    color: $color-dark;
    margin-left: 5px;
  }

  $edit-size: 32px;

  &-actions {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius;
    background-color: $color-gray;
    width: $edit-size;
    height: $edit-size;
    right: 20px;
    top: calc(50% - #{$edit-size / 2});
    cursor: pointer;
  }
}

</style>
