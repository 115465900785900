export default function ($t) {
  return [
    {
      name: "orderNumber",
      headerPortal: "orderRecipe",
      type: "portal",
      is_visible: true,
      fixedColumnHeader: true,
      get short_title() {
        return '';
      },
      get title() {
        return '';
      },
    },
    {
      name: "name",
      is_visible: true,
      fixedColumnHeader: true,
      get short_title() {
        return $t("Recipe.tableColumn_Ingredients");
      },
      get title() {
        return $t("Recipe.tableColumn_Ingredients");
      },
    },
    {
      name: "detailType",
      headerPortal: "headerType",
      fixedColumnHeader: true,
      type: "portal",
      is_visible: true,
      get short_title() {
        return $t("Recipe.tableColumn_Ingredients_Type");
      },
      get title() {
        return $t("Recipe.tableColumn_Ingredients_Type");
      },
    },
    {
      name: "netQuantity",
      type: "portal",
      is_visible: true,
      get short_title() {
        return $t("Recipe.tableColumn_Ingredient_NetQuantity");
      },
      get title() {
        return $t("Recipe.tableColumn_Ingredient_NetQuantity");
      },
    },
    {
      name: "unitName",
      is_visible: true,
      type: "portal",
      get short_title() {
        return $t("Recipe.tableColumn_Ingredient_Unit");
      },
      get title() {
        return $t("Recipe.tableColumn_Ingredient_Unit");
      },
    },
    {
      name: "loss1",
      type: "portal",
      is_visible: true,
      get short_title() {
        return $t("Recipe.tableColumn_Ingredient_Loss1");
      },
      get title() {
        return $t("Recipe.tableColumn_Ingredient_Loss1");
      },
    },
    {
      name: "loss2",
      type: "portal",
      is_visible: true,
      get short_title() {
        return $t("Recipe.tableColumn_Ingredient_Loss2");
      },
      get title() {
        return $t("Recipe.tableColumn_Ingredient_Loss2");
      },
    },
    {
      name: "grossQuantity",
      type: "mono",
      is_visible: true,
      get short_title() {
        return $t("Recipe.tableColumn_Ingredient_GrossQuantity");
      },
      get title() {
        return $t("Recipe.tableColumn_Ingredient_GrossQuantity");
      },
    },
    {
      name: "baseQuantity",
      type: "mono",
      is_visible: true,
      get short_title() {
        return $t("Recipe.tableColumn_Ingredient_BaseQuantity");
      },
      get title() {
        return $t("Recipe.tableColumn_Ingredient_BaseQuantity");
      },
    },
    {
      name: "baseUnitName",
      is_visible: true,
      get short_title() {
        return $t("Recipe.tableColumn_Ingredient_BaseUnit");
      },
      get title() {
        return $t("Recipe.tableColumn_Ingredient_BaseUnit");
      },
    },
    {
      name: "kgQuantity",
      type: "mono",
      is_visible: true,
      get short_title() {
        return $t("Recipe.tableColumn_Ingredient_kgQuantity");
      },
      get title() {
        return $t("Recipe.tableColumn_Ingredient_kgQuantity");
      },
    },
    {
      name: "cost",
      type: "currency",
      is_visible: true,
      get short_title() {
        return $t("Recipe.tableColumn_Ingredient_AveragePrice");
      },
      get title() {
        return $t("Recipe.tableColumn_Ingredient_AveragePrice");
      },
    },
    {
      name: "totalCost",
      type: "currency",
      is_visible: true,
      get short_title() {
        return $t("Recipe.tableColumn_Ingredient_TotalCost");
      },
      get title() {
        return $t("Recipe.tableColumn_Ingredient_TotalCost");
      },
    },
    {
      name: "costRatio",
      type: "percent",
      is_visible: true,
      get short_title() {
        return $t("Recipe.tableColumn_Ingredient_CostRatio");
      },
      get title() {
        return $t("Recipe.tableColumn_Ingredient_CostRatio");
      },
    },
  ];
}
