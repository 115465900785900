var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c("template", { slot: "title" }, [
        _c("div", [
          !_vm.isEdit
            ? _c("span", [_vm._v(_vm._s(_vm.$t("Recipe.popupAdd_Header")))])
            : _c("span", [
                _vm._v(_vm._s(_vm.$t("Recipe.popupAdd_Header_Edit"))),
              ]),
        ]),
        !_vm.isEdit
          ? _c("div", { staticClass: "PopupHeader" }, [
              _c(
                "div",
                { staticClass: "tab" },
                [
                  _c(
                    "Button",
                    {
                      class: { isSelected: _vm.activeTabIndex === 0 },
                      attrs: {
                        size: "small",
                        id: "btn-recipes-popup-tab-header-1",
                        type: "button",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.tabChange(0)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("Recipe.popupAdd_Header_StockItem")))]
                  ),
                  _c(
                    "Button",
                    {
                      class: { isSelected: _vm.activeTabIndex === 1 },
                      attrs: {
                        size: "small",
                        id: "btn-recipes-popup-tab-header-2",
                        type: "button",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.tabChange(1)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("Recipe.popupAdd_Header_Recipe")))]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _vm.isLoading
        ? _c("template", { slot: "content" }, [_c("Loading")], 1)
        : _vm._e(),
      !_vm.isLoading
        ? _c(
            "template",
            { slot: "content" },
            [
              _vm.showAlternative
                ? _c("Preview", {
                    attrs: {
                      data: _vm.previewData,
                      onClickPreviewEdit: _vm.changeShowAlternative,
                    },
                  })
                : _vm._e(),
              _c(
                "form",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isStockTab,
                      expression: "isStockTab",
                    },
                  ],
                  staticClass: "Form",
                  attrs: { id: "form-new-stock-item" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submitNewStockItem.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("Recipe.popupAdd_StockItemName"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("custom-search", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: _vm.isStockTab ? "required" : "",
                              expression: "isStockTab ? 'required' : ''",
                            },
                          ],
                          ref: "stockItem",
                          attrs: {
                            "component-name": "recipe-detail-stock-item",
                            name: "stockItem",
                            "data-vv-as": _vm.$t(
                              "Recipe.popupAdd_StockItemName"
                            ),
                            inputClass: {
                              "is-danger":
                                _vm.veeErrors.has("stockItem") ||
                                !_vm.validStock,
                            },
                            id: "search-stock-item",
                            "on-search": _vm.getStockItemOptions,
                            options: _vm.formNewStockItemSelectOptions,
                            disabled: _vm.isEdit,
                            label: "name",
                            placeholder: _vm.$t(
                              "Recipe.popupAdd_StockItemName_Placeholder"
                            ),
                            directSelectOption: _vm.directSelectOptionStockItem,
                          },
                          model: {
                            value: _vm.formNewStockItem.stockItem,
                            callback: function ($$v) {
                              _vm.$set(_vm.formNewStockItem, "stockItem", $$v)
                            },
                            expression: "formNewStockItem.stockItem",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("stockItem"),
                            expression: "veeErrors.has('stockItem')",
                          },
                        ],
                        staticClass: "Form-item-help is-danger",
                      },
                      [_vm._v(_vm._s(_vm.veeErrors.first("stockItem")))]
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !_vm.veeErrors.has("stockItem") &&
                              !_vm.validStock,
                            expression:
                              "!veeErrors.has('stockItem') && !validStock",
                          },
                        ],
                        staticClass: "Form-item-help is-danger",
                      },
                      [_vm._v(_vm._s(_vm.$t("Recipe.checkStock")))]
                    ),
                    _vm.shouldShowBaseUnit
                      ? _c("p", { staticClass: "row-info" }, [
                          _c("span", { staticClass: "row-info-text" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "Recipe.popupAdd_StockItemName_BaseUnitText"
                                )
                              )
                            ),
                          ]),
                          _c("span", { staticClass: "row-info-value" }, [
                            _vm._v(
                              _vm._s(
                                _vm.findUnit(
                                  _vm.formNewStockItem.stockItem.baseUnitId
                                ).code
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                  _c(
                    "div",
                    { staticClass: "Form-item required" },
                    [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(_vm.$t("Recipe.popupAdd_StockItemQuantity"))
                        ),
                      ]),
                      _c("div", { staticClass: "control" }),
                      _c("customNumberInput", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: _vm.isStockTab
                              ? {
                                  required: true,
                                  decimal: _vm.$getConst(
                                    "DECIMAL_FOR_QUANTITY"
                                  ),
                                  is_not: 0,
                                  max: _vm.$getConst("MAX_DIGITS_FOR_QUANTITY"),
                                }
                              : "",
                            expression:
                              "isStockTab ? {required: true, decimal: $getConst('DECIMAL_FOR_QUANTITY'), is_not: 0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}: ''",
                          },
                        ],
                        attrs: {
                          name: "stockItemQuantity",
                          error: _vm.veeErrors.has("stockItemQuantity"),
                          "data-vv-as": _vm.$t(
                            "Recipe.popupAdd_StockItemQuantity"
                          ),
                          id: "input-recipes-popup-new-stock-item-quanity",
                        },
                        model: {
                          value: _vm.formNewStockItem.netQuantity,
                          callback: function ($$v) {
                            _vm.$set(_vm.formNewStockItem, "netQuantity", $$v)
                          },
                          expression: "formNewStockItem.netQuantity",
                        },
                      }),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("stockItemQuantity"),
                            expression: "veeErrors.has('stockItemQuantity')",
                          },
                        ],
                        attrs: {
                          errorName: _vm.veeErrors.first("stockItemQuantity"),
                        },
                      }),
                      _vm.shouldShowBaseUnitQuantity &&
                      !_vm.veeErrors.has("stockItemQuantity")
                        ? _c("p", { staticClass: "row-info" }, [
                            _c("span", { staticClass: "row-info-text" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Recipe.popupAdd_StockItemName_BaseUnitQuantityText"
                                  ) + " "
                                )
                              ),
                            ]),
                            _c("span", { staticClass: "row-info-value" }, [
                              _vm._v(
                                _vm._s(
                                  Number(_vm.baseUnitQuantity.toFixed(6)) +
                                    " " +
                                    _vm.findUnit(
                                      this.formNewStockItem.stockItem.baseUnitId
                                    ).code
                                )
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("Recipe.popupAdd_UnitId"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control form-item-select" },
                      [
                        _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formNewStockItem.unitId,
                                expression: "formNewStockItem.unitId",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: _vm.isStockTab ? "required" : "",
                                expression: "isStockTab ? 'required' : ''",
                              },
                            ],
                            staticClass: "select",
                            class: { "is-danger": _vm.veeErrors.has("unitId") },
                            attrs: {
                              name: "unitId",
                              "data-vv-as": _vm.$t("Recipe.popupAdd_UnitId"),
                              id: "select-recipe-popup-add",
                            },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.formNewStockItem,
                                  "unitId",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(_vm.units, function (unit) {
                            return _c(
                              "option",
                              {
                                key: unit.id,
                                attrs: {
                                  id: `option-recipe-popup-add-${unit.name}`,
                                },
                                domProps: { value: unit.id },
                              },
                              [_vm._v(_vm._s(unit.name))]
                            )
                          }),
                          0
                        ),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("unitId"),
                              expression: "veeErrors.has('unitId')",
                            },
                          ],
                          attrs: { errorName: _vm.veeErrors.first("unitId") },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm.formNewStockItem.stockItem
                    ? _c(
                        "div",
                        {
                          staticClass: "m-top-20",
                          attrs: { id: "recipe-losses-container" },
                        },
                        [
                          _c("div", { staticClass: "Form-item double-input" }, [
                            _c(
                              "div",
                              { staticClass: "Form-item control" },
                              [
                                _c(
                                  "label",
                                  { staticClass: "Form-item-label" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("Recipe.popup_item_Loss1"))
                                    ),
                                  ]
                                ),
                                _c("customNumberInput", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "greater_than:0|max_value:100",
                                      expression:
                                        "'greater_than:0|max_value:100'",
                                    },
                                  ],
                                  class: {
                                    "is-danger": _vm.veeErrors.has("loss1"),
                                  },
                                  attrs: {
                                    name: "loss1",
                                    error: _vm.veeErrors.has("loss1"),
                                    isTypePercentage: true,
                                    "data-vv-as": _vm.$t(
                                      "Recipe.popup_item_Loss1"
                                    ),
                                    placeholder: ",00",
                                    id: "input-recipes-popup-loss1",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.calculateGrossQuantity(
                                        _vm.formNewStockItem
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.formNewStockItem.loss1,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formNewStockItem,
                                        "loss1",
                                        $$v
                                      )
                                    },
                                    expression: "formNewStockItem.loss1",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "Form-item control" },
                              [
                                _c(
                                  "label",
                                  { staticClass: "Form-item-label" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("Recipe.popup_item_Loss2"))
                                    ),
                                  ]
                                ),
                                _c("customNumberInput", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "greater_than:0|max_value:100",
                                      expression:
                                        "'greater_than:0|max_value:100'",
                                    },
                                  ],
                                  attrs: {
                                    name: "loss2",
                                    error: _vm.veeErrors.has("loss2"),
                                    "data-vv-as": _vm.$t(
                                      "Recipe.popup_item_Loss2"
                                    ),
                                    isTypePercentage: true,
                                    id: "input-recipes-popup-loss2",
                                    placeholder: ",00",
                                    disabled: !_vm.formNewStockItem.loss1,
                                  },
                                  model: {
                                    value: _vm.formNewStockItem.loss2,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formNewStockItem,
                                        "loss2",
                                        $$v
                                      )
                                    },
                                    expression: "formNewStockItem.loss2",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("showValidateError", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.veeErrors.has("loss1"),
                                expression: "veeErrors.has('loss1')",
                              },
                            ],
                            attrs: { errorName: _vm.veeErrors.first("loss1") },
                          }),
                          _c("showValidateError", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.veeErrors.has("loss2"),
                                expression: "veeErrors.has('loss2')",
                              },
                            ],
                            attrs: { errorName: _vm.veeErrors.first("loss2") },
                          }),
                          _c("div", { staticClass: "row-info" }, [
                            _c("span", { staticClass: "row-info-text" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Recipe.form_label_Field_gross_Quantity"
                                  )
                                )
                              ),
                            ]),
                            _c("span", { staticClass: "row-info-value" }, [
                              _vm._v(
                                _vm._s(_vm.grossQuantity.toFixed(6)) +
                                  " " +
                                  _vm._s(
                                    _vm.findUnit(_vm.formNewStockItem.unitId)
                                      .name
                                  )
                              ),
                            ]),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _vm.activeTabIndex === 0
                ? _c("AlternativeShowButton", {
                    attrs: {
                      showAlternative: _vm.showAlternative,
                      changeShowAlternative: _vm.changeShowAlternative,
                    },
                  })
                : _vm._e(),
              _c(
                "form",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isAlternativeStockTab,
                      expression: "isAlternativeStockTab",
                    },
                  ],
                  staticClass: "Form",
                  attrs: { id: "form-new-stock-item" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submitNewStockItem.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("Recipe.popupAdd_StockItemName"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("custom-search", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value:
                                _vm.isAlternativeStockTab &&
                                !_vm.isEmptyAlternative
                                  ? "required"
                                  : "",
                              expression:
                                "isAlternativeStockTab && !isEmptyAlternative ? 'required' : ''",
                            },
                          ],
                          ref: "alternativeStockItem",
                          attrs: {
                            "component-name":
                              "recipe-detail-alternative-stock-item",
                            name: "alternativeStockItem",
                            "data-vv-as": _vm.$t(
                              "Recipe.popupAdd_StockItemName"
                            ),
                            inputClass: {
                              "is-danger":
                                _vm.veeErrors.has("alternativeStockItem") ||
                                !_vm.validStock,
                            },
                            id: "search-alternative-stock-item",
                            "on-search": _vm.getAlternativeStockItemOptions,
                            options:
                              _vm.formNewAlternativeStockItemSelectOptions,
                            label: "name",
                            placeholder: _vm.$t(
                              "Recipe.popupAdd_StockItemName_Placeholder"
                            ),
                            directSelectOption:
                              _vm.directSelectOptionAlternativeStockItem,
                          },
                          model: {
                            value: _vm.formNewAlternativeStockItem.stockItem,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formNewAlternativeStockItem,
                                "stockItem",
                                $$v
                              )
                            },
                            expression: "formNewAlternativeStockItem.stockItem",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("alternativeStockItem"),
                            expression: "veeErrors.has('alternativeStockItem')",
                          },
                        ],
                        staticClass: "Form-item-help is-danger",
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.veeErrors.first("alternativeStockItem"))
                        ),
                      ]
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !_vm.veeErrors.has("alternativeStockItem") &&
                              !_vm.validStock,
                            expression:
                              "!veeErrors.has('alternativeStockItem') && !validStock",
                          },
                        ],
                        staticClass: "Form-item-help is-danger",
                      },
                      [_vm._v(_vm._s(_vm.$t("Recipe.checkStock")))]
                    ),
                    _vm.shouldShowAlternativeBaseUnit
                      ? _c("p", { staticClass: "row-info" }, [
                          _c("span", { staticClass: "row-info-text" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "Recipe.popupAdd_StockItemName_BaseUnitText"
                                )
                              )
                            ),
                          ]),
                          _c("span", { staticClass: "row-info-value" }, [
                            _vm._v(
                              _vm._s(
                                _vm.findUnit(
                                  _vm.formNewAlternativeStockItem.stockItem
                                    .baseUnitId
                                ).code
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                  _c(
                    "div",
                    { staticClass: "Form-item required" },
                    [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(
                          _vm._s(_vm.$t("Recipe.popupAdd_StockItemQuantity"))
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "control" },
                        [
                          _c("customNumberInput", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value:
                                  _vm.isAlternativeStockTab &&
                                  !_vm.isEmptyAlternative
                                    ? "required|decimal:6|is_not:0"
                                    : "",
                                expression:
                                  "isAlternativeStockTab && !isEmptyAlternative ? 'required|decimal:6|is_not:0' : ''",
                              },
                            ],
                            attrs: {
                              name: "alternativeStockItemQuantity",
                              error: _vm.veeErrors.has(
                                "alternativeStockItemQuantity"
                              ),
                              "data-vv-as": _vm.$t(
                                "Recipe.popupAdd_StockItemQuantity"
                              ),
                              id: "input-alternative-recipes-popup-new-stock-item-quanity",
                            },
                            model: {
                              value:
                                _vm.formNewAlternativeStockItem.netQuantity,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formNewAlternativeStockItem,
                                  "netQuantity",
                                  $$v
                                )
                              },
                              expression:
                                "formNewAlternativeStockItem.netQuantity",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has(
                              "alternativeStockItemQuantity"
                            ),
                            expression:
                              "veeErrors.has('alternativeStockItemQuantity')",
                          },
                        ],
                        attrs: {
                          errorName: _vm.veeErrors.first(
                            "alternativeStockItemQuantity"
                          ),
                        },
                      }),
                      _vm.shouldShowAlternativeBaseUnitQuantity &&
                      _vm.formNewAlternativeStockItem.stockItem &&
                      _vm.formNewAlternativeStockItem.stockItem.baseUnitId &&
                      !_vm.veeErrors.has("alternativeStockItemQuantity")
                        ? _c("p", { staticClass: "row-info" }, [
                            _c("span", { staticClass: "row-info-text" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Recipe.popupAdd_StockItemName_BaseUnitQuantityText"
                                  ) + " "
                                )
                              ),
                            ]),
                            _c("span", { staticClass: "row-info-value" }, [
                              _vm._v(
                                _vm._s(
                                  Number(
                                    _vm.alternativeBaseUnitQuantity.toFixed(6)
                                  ) +
                                    " " +
                                    _vm.findUnit(
                                      this.formNewAlternativeStockItem.stockItem
                                        .baseUnitId
                                    ).code
                                )
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("Recipe.popupAdd_UnitId"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control form-item-select" },
                      [
                        _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formNewAlternativeStockItem.unitId,
                                expression:
                                  "formNewAlternativeStockItem.unitId",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value:
                                  _vm.isAlternativeStockTab &&
                                  !_vm.isEmptyAlternative
                                    ? "required"
                                    : "",
                                expression:
                                  "isAlternativeStockTab && !isEmptyAlternative ? 'required' : ''",
                              },
                            ],
                            staticClass: "select",
                            class: { "is-danger": _vm.veeErrors.has("unitId") },
                            attrs: {
                              name: "unitId",
                              "data-vv-as": _vm.$t("Recipe.popupAdd_UnitId"),
                              id: "select-alternative-recipe-popup-add",
                            },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.formNewAlternativeStockItem,
                                  "unitId",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(_vm.alternativeUnits, function (aUnit) {
                            return _c(
                              "option",
                              {
                                key: aUnit.id,
                                attrs: {
                                  id: `option-recipe-popup-add-${aUnit.name}`,
                                },
                                domProps: { value: aUnit.id },
                              },
                              [_vm._v(_vm._s(aUnit.name))]
                            )
                          }),
                          0
                        ),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("unitId"),
                              expression: "veeErrors.has('unitId')",
                            },
                          ],
                          attrs: { errorName: _vm.veeErrors.first("unitId") },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm.formNewAlternativeStockItem.stockItem
                    ? _c(
                        "div",
                        {
                          staticClass: "m-top-20",
                          attrs: { id: "recipe-losses-container" },
                        },
                        [
                          _c("div", { staticClass: "Form-item double-input" }, [
                            _c("div", { staticClass: "Form-item control" }, [
                              _c("label", { staticClass: "Form-item-label" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("Recipe.popup_item_Loss1"))
                                ),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value:
                                      _vm.formNewAlternativeStockItem.loss1,
                                    expression:
                                      "formNewAlternativeStockItem.loss1",
                                    modifiers: { number: true },
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value:
                                      _vm.isAlternativeStockTab &&
                                      !_vm.isEmptyAlternative
                                        ? "greater_than:0|max_value:100"
                                        : "",
                                    expression:
                                      "isAlternativeStockTab && !isEmptyAlternative ? 'greater_than:0|max_value:100' : ''",
                                  },
                                ],
                                staticClass: "txt no-spinners",
                                class: {
                                  "is-danger":
                                    _vm.veeErrors.has("alternativeLoss1"),
                                },
                                attrs: {
                                  name: "alternativeLoss1",
                                  type: "number",
                                  step: "any",
                                  autocomplete: "off",
                                  "data-vv-as": _vm.$t(
                                    "Recipe.popup_item_Loss1"
                                  ),
                                  placeholder: "%",
                                  id: "input-recipes-popup-alternativeLoss1",
                                },
                                domProps: {
                                  value: _vm.formNewAlternativeStockItem.loss1,
                                },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.formNewAlternativeStockItem,
                                        "loss1",
                                        _vm._n($event.target.value)
                                      )
                                    },
                                    function ($event) {
                                      return _vm.calculateGrossQuantity(
                                        _vm.formNewAlternativeStockItem
                                      )
                                    },
                                  ],
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "Form-item control" }, [
                              _c("label", { staticClass: "Form-item-label" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("Recipe.popup_item_Loss2"))
                                ),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value:
                                      _vm.formNewAlternativeStockItem.loss2,
                                    expression:
                                      "formNewAlternativeStockItem.loss2",
                                    modifiers: { number: true },
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value:
                                      _vm.isAlternativeStockTab &&
                                      !_vm.isEmptyAlternative
                                        ? "greater_than:0|max_value:100"
                                        : "",
                                    expression:
                                      "isAlternativeStockTab && !isEmptyAlternative ? 'greater_than:0|max_value:100' : ''",
                                  },
                                ],
                                staticClass: "txt no-spinners",
                                class: {
                                  "is-danger":
                                    _vm.veeErrors.has("alternativeLoss2"),
                                },
                                attrs: {
                                  name: "alternativeLoss2",
                                  type: "number",
                                  step: "any",
                                  placeholder: "%",
                                  autocomplete: "off",
                                  "data-vv-as": _vm.$t(
                                    "Recipe.popup_item_Loss2"
                                  ),
                                  disabled:
                                    !_vm.formNewAlternativeStockItem.loss1,
                                  id: "input-recipes-popup-loss2",
                                },
                                domProps: {
                                  value: _vm.formNewAlternativeStockItem.loss2,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.formNewAlternativeStockItem,
                                      "loss2",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _c("showValidateError", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.veeErrors.has("alternativeLoss1"),
                                expression: "veeErrors.has('alternativeLoss1')",
                              },
                            ],
                            attrs: {
                              errorName:
                                _vm.veeErrors.first("alternativeLoss1"),
                            },
                          }),
                          _c("showValidateError", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.veeErrors.has("alternativeLoss2"),
                                expression: "veeErrors.has('alternativeLoss2')",
                              },
                            ],
                            attrs: {
                              errorName:
                                _vm.veeErrors.first("alternativeLoss2"),
                            },
                          }),
                          _c("p", { staticClass: "row-info" }, [
                            _c("span", { staticClass: "row-info-text" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Recipe.form_label_Field_gross_Quantity"
                                  )
                                )
                              ),
                            ]),
                            _c("span", { staticClass: "row-info-value" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.alternativeGrossQuantity.toFixed(6)
                                ) +
                                  " " +
                                  _vm._s(
                                    _vm.findUnit(
                                      _vm.formNewAlternativeStockItem.unitId
                                    ).name
                                  )
                              ),
                            ]),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _c(
                "form",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isRecipeTab,
                      expression: "isRecipeTab",
                    },
                  ],
                  staticClass: "Form",
                  attrs: { id: "form-new-recipe" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submitNewRecipe.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "Form-item required" },
                    [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(_vm._s(_vm.$t("Recipe.popupAdd_RecipeId"))),
                      ]),
                      _c(
                        "div",
                        { staticClass: "control" },
                        [
                          _c("custom-search", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: _vm.isRecipeTab ? "required" : "",
                                expression: "isRecipeTab ? 'required' : ''",
                              },
                            ],
                            ref: "recipe",
                            attrs: {
                              disabled: _vm.isEdit,
                              name: "recipe",
                              "component-name": "recipe-detail-recipe",
                              "data-vv-as": _vm.$t("Recipe.popupAdd_Recipe"),
                              inputClass: {
                                "is-danger": _vm.veeErrors.has("recipe"),
                              },
                              id: "search-recipe",
                              "on-search": _vm.getRecipeOptions,
                              options: _vm.formNewRecipeSelectOptions,
                              label: "name",
                              placeholder: _vm.$t(
                                "Recipe.popupAdd_RecipeId_Placeholder"
                              ),
                            },
                            on: { afterSelected: _vm.setRecipe },
                            model: {
                              value: _vm.formNewRecipe.recipe,
                              callback: function ($$v) {
                                _vm.$set(_vm.formNewRecipe, "recipe", $$v)
                              },
                              expression: "formNewRecipe.recipe",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("recipe"),
                            expression: "veeErrors.has('recipe')",
                          },
                        ],
                        attrs: { errorName: _vm.veeErrors.first("recipe") },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "Form-item required" },
                    [
                      _c("label", { staticClass: "Form-item-label" }, [
                        _vm._v(_vm._s(_vm.$t("Recipe.popupAdd_Quantity"))),
                      ]),
                      _c(
                        "div",
                        { staticClass: "control" },
                        [
                          _c("customNumberInput", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: _vm.isRecipeTab
                                  ? {
                                      required: true,
                                      decimal: _vm.$getConst(
                                        "DECIMAL_FOR_QUANTITY"
                                      ),
                                      max: _vm.$getConst(
                                        "MAX_DIGITS_FOR_QUANTITY"
                                      ),
                                    }
                                  : "",
                                expression:
                                  "isRecipeTab ? {required: true, decimal: $getConst('DECIMAL_FOR_QUANTITY'), max: $getConst('MAX_DIGITS_FOR_QUANTITY')} : ''",
                              },
                            ],
                            attrs: {
                              name: "recipeQuantity",
                              error: _vm.veeErrors.has("recipeQuantity"),
                              "data-vv-as": _vm.$t(
                                "Recipe.popupAdd_recipeQuantity"
                              ),
                              id: "input-recipes-popup-quanity",
                            },
                            model: {
                              value: _vm.formNewRecipe.netQuantity,
                              callback: function ($$v) {
                                _vm.$set(_vm.formNewRecipe, "netQuantity", $$v)
                              },
                              expression: "formNewRecipe.netQuantity",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("recipeQuantity"),
                            expression: "veeErrors.has('recipeQuantity')",
                          },
                        ],
                        attrs: {
                          errorName: _vm.veeErrors.first("recipeQuantity"),
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.formNewRecipe.recipe
                    ? _c("div", { staticClass: "row-info" }, [
                        _c("span", { staticClass: "row-info-text" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("Recipes.form_label_Field_portionUnitId")
                            )
                          ),
                        ]),
                        _c("span", { staticClass: "row-info-value" }, [
                          _vm._v(
                            _vm._s(
                              _vm.formNewRecipe.recipe.portionQuantity +
                                " " +
                                _vm.formNewRecipe.recipe.portionUnitName
                            )
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.formNewRecipe.recipe
                    ? _c(
                        "div",
                        {
                          staticClass: "m-top-20",
                          attrs: { id: "recipe-losses-container" },
                        },
                        [
                          _c("div", { staticClass: "Form-item double-input" }, [
                            _c(
                              "div",
                              { staticClass: "Form-item control" },
                              [
                                _c(
                                  "label",
                                  { staticClass: "Form-item-label" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("Recipe.popup_item_Loss1"))
                                    ),
                                  ]
                                ),
                                _c("customNumberInput", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: _vm.isRecipeTab
                                        ? "greater_than:0|max_value:100"
                                        : "",
                                      expression:
                                        "isRecipeTab ? 'greater_than:0|max_value:100' : ''",
                                    },
                                  ],
                                  class: {
                                    "is-danger": _vm.veeErrors.has("loss1"),
                                  },
                                  attrs: {
                                    name: "loss1",
                                    "data-vv-as": _vm.$t(
                                      "Recipe.popup_item_Loss1"
                                    ),
                                    type: "text",
                                    placeholder: ",00",
                                    id: "input-recipes-popup-loss1",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.calculateGrossQuantity(
                                        _vm.formNewRecipe
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.formNewRecipe.loss1,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formNewRecipe, "loss1", $$v)
                                    },
                                    expression: "formNewRecipe.loss1",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "Form-item control" },
                              [
                                _c(
                                  "label",
                                  { staticClass: "Form-item-label" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("Recipe.popup_item_Loss2"))
                                    ),
                                  ]
                                ),
                                _c("customNumberInput", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: _vm.isRecipeTab
                                        ? "greater_than:0|max_value:100"
                                        : "",
                                      expression:
                                        "isRecipeTab ? 'greater_than:0|max_value:100' : ''",
                                    },
                                  ],
                                  class: {
                                    "is-danger": _vm.veeErrors.has("loss2"),
                                  },
                                  attrs: {
                                    name: "loss2",
                                    "data-vv-as": _vm.$t(
                                      "Recipe.popup_item_Loss2"
                                    ),
                                    disabled: !_vm.formNewRecipe.loss1,
                                    type: "text",
                                    id: "input-recipes-popup-loss2",
                                    placeholder: ",00",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.calculateGrossQuantity(
                                        _vm.formNewRecipe
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.formNewRecipe.loss2,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formNewRecipe, "loss2", $$v)
                                    },
                                    expression: "formNewRecipe.loss2",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("showValidateError", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.veeErrors.has("loss1"),
                                expression: "veeErrors.has('loss1')",
                              },
                            ],
                            attrs: { errorName: _vm.veeErrors.first("loss1") },
                          }),
                          _c("showValidateError", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.veeErrors.has("loss2"),
                                expression: "veeErrors.has('loss2')",
                              },
                            ],
                            attrs: { errorName: _vm.veeErrors.first("loss2") },
                          }),
                          _c("div", { staticClass: "row-info" }, [
                            _c("span", { staticClass: "row-info-text" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Recipe.form_label_Field_gross_Quantity"
                                  )
                                )
                              ),
                            ]),
                            _c(
                              "span",
                              {
                                staticClass: "row-info-value font-weight-bold",
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.grossQuantity.toFixed(6) + " ")
                                ),
                              ]
                            ),
                            _c(
                              "span",
                              { staticClass: "row-info-value m-left-5" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.formNewRecipe.recipe.portionQuantity +
                                      " " +
                                      _vm.formNewRecipe.recipe.portionUnitName
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.activeTabIndex === 0 && !_vm.isLoading
        ? _c(
            "template",
            { slot: "footer" },
            [
              !_vm.isEdit
                ? _c(
                    "div",
                    { staticClass: "add-other-items-checkbox-container" },
                    [
                      _c("CustomCheckbox", {
                        attrs: {
                          id: "checkbox-recipes-detail-recipe-save-and-new",
                          label: _vm.$t("Global.formSaveAndNewText", {
                            form: _vm.$t("Recipe.saveAndNewKey"),
                          }),
                        },
                        model: {
                          value: _vm.saveAndNew,
                          callback: function ($$v) {
                            _vm.saveAndNew = $$v
                          },
                          expression: "saveAndNew",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "Button",
                {
                  attrs: {
                    primary: "",
                    size: "medium",
                    variant: "full",
                    id: "btn-recipes-new-detail-popup-submit-stock",
                    type: "submit",
                    form: "form-new-stock-item",
                    disabled: _vm.isCreating,
                  },
                },
                [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isCreating,
                          expression: "!isCreating",
                        },
                      ],
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.isEdit
                            ? _vm.$t("Recipe.popupAdd_Button_Edit")
                            : _vm.$t("Recipe.popupAdd_Button")
                        )
                      ),
                    ]
                  ),
                  _c("Loading", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isCreating,
                        expression: "isCreating",
                      },
                    ],
                    attrs: { theme: "disable" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.activeTabIndex === 1 && !_vm.isLoading
        ? _c(
            "template",
            { slot: "footer" },
            [
              !_vm.isEdit
                ? _c(
                    "div",
                    { staticClass: "add-other-items-checkbox-container" },
                    [
                      _c("CustomCheckbox", {
                        attrs: {
                          id: "checkbox-recipes-detail-stock-save-and-new",
                          label: _vm.$t("Global.formSaveAndNewText", {
                            form: _vm.$t("Recipe.saveAndNewKey"),
                          }),
                        },
                        model: {
                          value: _vm.saveAndNew,
                          callback: function ($$v) {
                            _vm.saveAndNew = $$v
                          },
                          expression: "saveAndNew",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "Button",
                {
                  attrs: {
                    primary: "",
                    size: "medium",
                    variant: "full",
                    id: "btn-recipes-new-detail-popup-submit-recipe",
                    type: "submit",
                    form: "form-new-recipe",
                    disabled: _vm.isCreating,
                  },
                },
                [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isCreating,
                          expression: "!isCreating",
                        },
                      ],
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.isEdit
                            ? _vm.$t("Recipe.popupAdd_Button_Edit")
                            : _vm.$t("Recipe.popupAdd_Button")
                        )
                      ),
                    ]
                  ),
                  _c("Loading", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isCreating,
                        expression: "isCreating",
                      },
                    ],
                    attrs: { theme: "disable" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }